import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";

export default function PaymentDetail({ order }) {
  const paymentModes = order.priceDetail.paymentMode;

  let lastPaymentMode =
    paymentModes.length > 0
      ? paymentModes[paymentModes.length - 1]?.mode
      : null;

  if (order.priceDetail.paymentStatus === "paid") {
    return (
      <div className="px-4 w-[95%] items-center bg-[white] border border-gray-200 rounded-lg py-4 flex gap-2">
        <FaCheckCircle className="text-green-600 text-3xl" />

        <div className="flex flex-col">
          <div className="text-base font-[500] flex items-center gap-1 ">
            {lastPaymentMode === "cash" ? "Paid by cash" : "Paid online"}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 w-[95%] items-center bg-[white] border border-gray-200 rounded-lg py-4 flex justify-between gap-3">
      <div className="flex flex-col">
        <div className="text-[1.125rem] text-red-600 font-[500] flex items-center gap-1 ">
          Payment pending <div className="custom-red-pulse scale-[.6]"> </div>
        </div>
        <div className="text-sm font-[400] text-[gray] leading-4">
          Get the payment before you finish and leave.
        </div>
      </div>
      <div className="text-[1.25rem] flex-shrink-0 flex items-center justify-center p-3 bg-red-100 text-red-700 rounded-full aspect-square">
        <GiReceiveMoney />
      </div>
    </div>
  );
}
