import axios from "axios";

const Base_Url = process.env.REACT_APP_PAY_URL;

const cashfreeOrderApi = async ({ options }) => {
  try {
    const res = await axios.post(`${Base_Url}/pay/cashfree`, {
      options: {
        ...options,
        environment:
          process.env.REACT_APP_ENVIRONMENT === "dev"
            ? "sandbox"
            : "production",
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
    };
  }
};

const rzpAPI = async ({ options }) => {
  try {
    const res = await axios.post(`${Base_Url}/pay/rzp`, {
      options: {
        ...options,
        environment:
          process.env.REACT_APP_ENVIRONMENT === "dev"
            ? "sandbox"
            : "production",
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
    };
  }
};

export { cashfreeOrderApi, rzpAPI };
