import React from "react";
import { FaDirections, FaLocationArrow } from "react-icons/fa";
import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function OrderSummaryInfoContainer({ order }) {
  const address = order.addressId;
  const delivery = order.date.delivery;
  return (
    <div className="w-full flex flex-col">
      <div className=" w-full flex flex-col relative  text-[.875rem] font-[400] p-4 bg-[white] rounded-lg border border-gray-200  ">
        <p className=" text-sm  w-full flex justify-between items-center font-[400] text-[gray]">
          Expected return day
          <div
            onClick={() =>
              window.openUrl(
                `https://www.google.com/maps/dir/?api=1&destination=${address.locationv2.lat},${address.locationv2.lng}&travelmode=driving`
              )
            }
            className=" text-[1.125rem] text-[white] gap-2 font-[500] blue-gradient flex items-center justify-center bg-[#2136d4] rounded-full size-[2rem] "
          >
            <FaDirections />
          </div>
        </p>

        <p className=" text-[1.125rem] leading-4 -mt-1 font-[500] text-[black]">
          {formatDateTime(moment(delivery?.celebrationDate).add(1, "day"))}
        </p>

        <div className="flex gap-1 text-sm items-center mt-2">
          <div className=" text-gray-500">Serviced on:</div>
          <div className="   ">
            {formatDateTime(moment(delivery.celebrationDate))}
          </div>
        </div>

        <div className="w-full flex items-start gap-2 relative">
          <div className=" flex flex-col w-full py-[2px]">
            <div className="text-black font-medium text-sm flex items-center gap-1 ">
              <FaLocationArrow className="text-[#2136d4] text-sm " />
              {address.locationv2.addressLine1}
            </div>
            <div className=" text-gray-500 text-xs  font-[400] leading-[16px] mt-0.5  ">
              {address.locationv2?.addressLine2}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const formatDateTime = (timeOfPresence) => {
  const now = moment();
  const presenceDate = moment(timeOfPresence);

  if (presenceDate.isSame(now, "day")) {
    return "Today";
  } else if (presenceDate.isSame(now.clone().add(1, "day"), "day")) {
    return "Tomorrow";
  } else if (presenceDate.isSame(now.clone().subtract(1, "day"), "day")) {
    return "Yesterday";
  } else {
    return presenceDate.format("Do MMMM ");
  }
};
