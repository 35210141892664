import React, { memo, useEffect } from "react";
import PopUpComponents from "./PopUpComponents";
import EachRoute from "./EachRoute";
import BottomNavbar from "../components/navbar/BottomNavbar";
import useFetchInitialData from "../hooks/useFetchInitialData";
import useNotificationHandler from "../hooks/useNotificationHandler";
import { useAuthContext } from "../context/AuthContext";

const MainRouter = () => {
  const { isLoading, callMethods } = useFetchInitialData();

  const { initialState } = useAuthContext();

  useEffect(() => {
    // there might be repeative calls to this function, due to change in initialState.santsId
    // so, we will check the function is already executed or not

    callMethods();

    // eslint-disable-next-line
  }, [initialState?.santaId]);

  useNotificationHandler();

  if (isLoading) {
    return (
      <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center">
        <div className="loader w-[2rem] "></div>
      </div>
    );
  }

  return (
    <div className=" bg-[white] ">
      <PopUpComponents />
      <EachRoute />
      <BottomNavbar />
    </div>
  );
};

export default memo(MainRouter);
