import React, { useState, useRef, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { FaAnglesRight } from "react-icons/fa6";

const SlideToAcceptBtn = ({
  btnText,
  fun,
  disabled,
  isSimple,
  isLoading,
  subText,
}) => {
  const parentRef = useRef(null);
  const iconControls = useAnimation();
  const iconWidth = 48;

  const [states, setStates] = useState({
    isAccepted: false,
    parentWidth: 0,
    iconPosition: 0,
    backgroundColor: "",
    textOpacity: 1,
  });

  useEffect(() => {
    if (parentRef.current) {
      setStates((p) => ({ ...p, parentWidth: parentRef.current.offsetWidth }));
    }
  }, [parentRef]);

  useEffect(() => {
    states.isAccepted && handleSubmit();

    // eslint-disable-next-line
  }, [states.isAccepted]);

  function handleSubmit() {
    fun && fun();
  }

  if (isSimple)
    return (
      <button
        className={`py-4 px-4 w-full flex flex-col  items-center leading-4 rounded-[.5rem] font-[500] text-[1.125rem] ${
          disabled && "opacity-50"
        } before:w-[100px] mirror-Animation blue-gradient justify-center `}
        onClick={() => {
          !disabled && handleSubmit();
        }}
      >
        {isLoading ? (
          <div
            style={{ borderTopColor: "white" }}
            className="loader w-[1rem] "
          ></div>
        ) : btnText ? (
          btnText
        ) : (
          "Continue"
        )}
        <div className="text-[.75rem]">{subText}</div>
      </button>
    );

  const handleSlide = (event, info) => {
    if (disabled) return;
    const maxIconPosition = states.parentWidth - iconWidth;
    const newPosition = info.point.x;
    const newOpacity = Math.max(0.4, 1 - newPosition / maxIconPosition);
    setStates((prevState) => ({
      ...prevState,
      isAccepted: newPosition >= maxIconPosition,
      iconPosition: newPosition,
      backgroundColor: calculateBackgroundColor(newPosition),
      textOpacity: newOpacity,
    }));
  };

  const handleDragEnd = (event, info) => {
    if (disabled) return;
    const maxIconPosition = states.parentWidth - iconWidth;
    const targetPosition =
      info.point.x >= maxIconPosition ? maxIconPosition : 0;
    setStates((prevState) => ({
      ...prevState,
      isAccepted: targetPosition === maxIconPosition,
      backgroundColor: calculateBackgroundColor(targetPosition),
    }));
    const animation = { x: targetPosition };
    const transition = { duration: 0.6 };
    iconControls.start(animation, transition);
  };

  const calculateBackgroundColor = (position) => {
    const gradientPosition = Math.min(
      position / (states.parentWidth - iconWidth / 2),
      1
    );
    return `linear-gradient(to right, #34D399 ${
      gradientPosition * 100
    }%, #E5E7EB ${gradientPosition * 100}%)`;
  };

  return (
    <div className="mt-4" ref={parentRef}>
      <motion.div className="flex items-center h-12 rounded-full bg-gray-200 shadow-md cursor-pointer relative overflow-hidden">
        <motion.div
          className="bg-gradient absolute inset-0"
          style={{ background: states.backgroundColor }}
        >
          <motion.div
            drag={disabled ? false : "x"}
            dragConstraints={{ left: 0, right: states.parentWidth - iconWidth }}
            onDrag={handleSlide}
            onDragEnd={handleDragEnd}
            dragMomentum={false}
            className="flex items-center justify-center h-12 w-12 bg-white rounded-full z-10"
            animate={iconControls}
            style={{ opacity: disabled ? 0.5 : 1 }}
          >
            <FaAnglesRight />
          </motion.div>
        </motion.div>
        <span
          className="flex-grow text-center font-semibold text-[1.125rem]"
          style={{ opacity: states.textOpacity }}
        >
          {btnText ? btnText : "Slide to Accept"}
        </span>
      </motion.div>
    </div>
  );
};

export default SlideToAcceptBtn;
