import React, { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { BsQrCode, BsQrCodeScan } from "react-icons/bs";
import { FaArrowRightLong, FaCircleCheck } from "react-icons/fa6";
import { rzpAPI } from "../../../../../../api/pay/pay.api";

import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function QRmodal({
  setParentStates,
  onReferesh,
  order,
  states,
  setStates,
}) {
  return (
    <AnimatePresence>
      {states.showQrModal && (
        <Modal
          order={order}
          states={states}
          setStates={setStates}
          setParentStates={setParentStates}
          onReferesh={onReferesh}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ states, setStates, order, setParentStates, onReferesh }) => {
  const containerRef = useRef(null);
  const handleClose = () => setStates((p) => ({ ...p, showQrModal: false }));
  const [localStates, setLocalStates] = useState({
    generatingQRScanner: false,
    statusChecking: false,
    loadingQRImage: true,
    amount: order.priceDetail.pendingAmount,
  });

  const handleQrGenerate = async () => {
    setLocalStates((p) => ({
      ...p,
      generatingQRScanner: true,
      loadingQRImage: true,
    }));
    try {
      const res = await rzpAPI({
        options: {
          amount: order.priceDetail.pendingAmount,
          orderId: order._id,
          operation: "createRzpQRCode",
          notes: {
            paymentFor: "order",
            paymentFor_id: order._id,
            paymentInitiaterDomain: "ebo-admin",
          },
        },
      });

      if (res.isSuccess && res.QRScanner) {
        setStates((p) => ({
          ...p,
          QRScanner: res.QRScanner,
          QRID: res.QRID,
          amount: res.amount,
        }));
      } else {
        window.toast("Error while creating QR Scanner");
        return null;
      }
      setLocalStates((p) => ({
        ...p,
        generatingQRScanner: false,
      }));
    } catch (error) {
      console.error(error);
      setLocalStates((p) => ({
        ...p,
        generatingQRScanner: false,
      }));
    }
  };

  if (states.isPaymentDone) {
    return (
      <motion.div
        ref={containerRef}
        initial={{ y: "80rem" }}
        animate={{ y: 0 }}
        exit={{ y: "80rem" }}
        transition={{ duration: 0.3 }}
        className="fixed left-0 flex flex-col  bottom-0 top-0 items-center justify-center right-0 bg-[white] z-[1]"
      >
        <div className=" absolute top-4 z-[1] left-4 ">
          <RxCross2
            onClick={() => {
              if (states.isPaymentDone) {
                onReferesh();
              }
              handleClose();
            }}
            className="text-[1.5rem] text-[black]"
          />
        </div>
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 200, opacity: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className="relative flex items-center justify-center "
        >
          <FaCircleCheck className="text-[8rem] text-green-700 " />
        </motion.div>
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 200, opacity: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
          className="text-[1.5rem] mt-6 font-[500] "
        >
          ₹{order.priceDetail.pendingAmount} Received
        </motion.div>
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 200, opacity: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
          className="text-[1rem] text-[gray] font-[500] "
        >
          on {moment().format("Do MMM, h:mm A")}
        </motion.div>
      </motion.div>
    );
  }

  if (states.QRScanner) {
    return (
      <motion.div
        ref={containerRef}
        initial={{ y: "80rem" }}
        animate={{ y: 0 }}
        exit={{ y: "80rem" }}
        transition={{ duration: 0.3 }}
        className="fixed left-0 flex flex-col  bottom-0 top-0 items-center justify-center right-0 bg-[white] z-[1]"
      >
        {localStates.statusChecking && (
          <div className=" absolute top-0 left-0 bottom-0 gap-2 text-[1rem] text-[white] right-0 z-[15] bg-[#000000c0] flex items-center justify-center ">
            <div
              style={{
                borderTopColor: "white",
              }}
              className="loader w-[1rem]"
            ></div>
            Checking status...
          </div>
        )}
        {!states.isPaymentDone && localStates.loadingQRImage && (
          <div className=" absolute top-0 left-0 bottom-0 gap-2 text-[1rem] text-[white] right-0 z-[15] bg-[#000000c0] flex items-center justify-center ">
            <div
              style={{
                borderTopColor: "white",
              }}
              className="loader w-[1rem]"
            ></div>
            Loading QR...
          </div>
        )}
        <div className=" absolute top-4 z-[1] left-4 ">
          <RxCross2
            onClick={() => {
              if (states.isPaymentDone) {
                // onReferesh();
              }
              handleClose();
            }}
            className="text-[1.5rem] text-[black]"
          />
        </div>
        {states.isPaymentDone ? (
          <>
            <div className="relative flex items-center justify-center ">
              <FaCircleCheck className=" popAnime text-[10rem] text-green-700 " />
            </div>
            <div className="text-[1.5rem] mt-6 font-[500] ">
              Payment received
            </div>
          </>
        ) : (
          <>
            <img
              src={states.QRScanner}
              onLoad={() => {
                setLocalStates((p) => ({
                  ...p,
                  generatingQRScanner: true,
                  loadingQRImage: false,
                }));
              }}
              alt=""
              className=" w-full "
            />
          </>
        )}
      </motion.div>
    );
  }
  return (
    <div className=" fixed top-0 left-0 bottom-0 right-0 z-[2] bg-[#0000006d] ">
      <motion.div
        initial={{ y: "35rem" }}
        animate={{ y: 0 }}
        exit={{ y: "35rem" }}
        transition={{ duration: 0.3 }}
        className="fixed bottom-0 left-0 flex overflow-hidden flex-col min-h-[5rem] rounded-t-xl text-[.875rem] items-center right-0 bg-[white] z-[1]"
      >
        <div className=" absolute top-4 left-4 ">
          <RxCross2
            onClick={handleClose}
            className="text-[1.5rem] text-[black]"
          />
        </div>
        {localStates.generatingQRScanner && (
          <div className=" absolute top-[3rem] left-0 bottom-0 gap-2 text-[1rem] text-[white] right-0 z-[15] bg-[#000000c0] flex items-center justify-center ">
            <div
              style={{
                borderTopColor: "white",
              }}
              className="loader w-[1rem]"
            ></div>
            Generating QR scanner...
          </div>
        )}
        <BsQrCode className="text-[15rem] mt-[4rem] blur-lg  " />

        <div
          onClick={() => {
            handleQrGenerate();
          }}
          className={`  relative bottom-[9rem]  py-3 px-[5rem] gap-1 font-[500] blue-gradient flex items-center rounded-lg justify-center text-[1.125rem] `}
        >
          <BsQrCodeScan className="text-[1.25rem] relative bottom-[1px] " />{" "}
          Generate QR
        </div>

        <div className=" mt-[1.5rem] text-[1.125rem] font-[500]">
          Pay to{" "}
          <span
            style={{ fontFamily: "Josefin Sans, sans-serif" }}
            className="text-[#2136d4] "
          >
            ebo
          </span>
        </div>
        <div className="text-[2rem] font-[800] mt-1 leading-[30px] ">
          ₹{order.priceDetail.pendingAmount}
        </div>
        <div className="mt-[1rem] text-[1rem]">Ask customer to scan QR</div>
        <div className="flex gap-4 w-full items-center mt-4 justify-center">
          <hr className="border-t-[2px] border-gray-400 w-[30%]" />
          <div className=" text-[1rem] text-[gray]">OR</div>
          <hr className="border-t-[2px] border-gray-400 w-[30%]" />
        </div>
        <div
          onClick={() => {
            setStates((p) => ({
              ...p,
              showQrModal: false,
              cashCollectModal: true,
            }));
          }}
          className="mt-[1.25rem] mb-12 text-[1rem] gap-2 border-b border-[#2136d4] leading-4 text-[#2136d4] flex items-center "
        >
          Take cash <FaArrowRightLong />
        </div>
      </motion.div>
    </div>
  );
};
