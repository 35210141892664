import React from "react";
import { FaAngleRight } from "react-icons/fa";

export default function OrderSummaryContainer({ order, setStates }) {
  return (
    <div
      onClick={() => setStates((p) => ({ ...p, orderSummaryPage: true }))}
      className="px-4 w-[95%] flex-col items-start mt-2 bg-[white] border border-gray-200 rounded-lg py-4 flex gap-2"
    >
      <div className=" items-center w-full flex gap-2">
        <img
          className="  size-[3.5rem] border bg-blue-100  rounded-lg aspect-square"
          src={order?.productId?.images[0]}
          alt=""
        />
        <div className="flex flex-col w-full">
          <div className="text-sm font-[500] flex items-center gap-1 ">
            View decor
          </div>
          <div className="text-xs font-[400] text-[gray] leading-4">
            View booked decoration details and materials required
          </div>
        </div>
        <FaAngleRight className="text-[1.25rem]" />
      </div>
      {order.addOns.length > 0 && (
        <div className="w-full flex items-center mt-2 ">
          {order.addOns.slice(0, 6).map((addOn, idx) => {
            return (
              <img
                className="w-[2rem] -mr-4 rounded-full border border-blue-200"
                alt=""
                src={addOn.addonId?.addonImage}
                key={idx}
              />
            );
          })}
          <div className="font-medium ml-6">
            {order.addOns.length + " Add-ons"}
          </div>
        </div>
      )}

      <SantaInstructions order={order} />
    </div>
  );
}

const SantaInstructions = ({ order }) => {
  const santaInstructions = order?.santaInstructions
    ?.split(";")
    .filter((curr) => curr);
  const imagesInstructionForSanta = order?.imagesInstructionForSanta;

  if (santaInstructions?.length > 0 || imagesInstructionForSanta?.length > 0) {
    return (
      <div className="w-full ">
        <div className=" w-full flex flex-col  text-[.875rem] font-[400]   ">
          <div className="text-[gray] text-[.8125rem] mb-2 ">Instructions</div>
          <div className="flex gap-2 w-full flex-col">
            {santaInstructions?.map((curr, idx) => {
              return (
                <div
                  key={idx}
                  className="flex items-start
               gap-2   "
                >
                  <div className=" w-[.5rem] relative top-[6px] rounded-full aspect-square h-[.5rem]  blue-gradient  "></div>
                  <div className="  first-letter:capitalize ">{curr}</div>
                </div>
              );
            })}
            {imagesInstructionForSanta?.map((curr, idx) => {
              return (
                <div key={idx} className="flex items-center  w-full    ">
                  <div className="flex items-start gap-2 w-[80%] pr-2">
                    <div className=" w-[.5rem]  relative top-[6px] rounded-full aspect-square h-[.5rem]  blue-gradient  "></div>
                    <div className="  first-letter:capitalize ">
                      {curr.instruction}
                    </div>
                  </div>
                  <img
                    className="w-[20%] rounded-lg"
                    onClick={() => {
                      window.showMsg(curr.img, "img");
                    }}
                    alt=""
                    src={curr.img}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};
