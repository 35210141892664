import { FaUserLargeSlash } from "react-icons/fa6";

export default function OnboardingRejectedPage({ santa }) {
  return (
    <div className="px-2">
      <div className="flex flex-col justify-center items-center mt-4 bg-white w-full p-6 px-4 rounded-[.5rem] shadow-lg">
        {/* <MdBlock className="text-red-500 text-[4rem] mb-4" /> */}
        <FaUserLargeSlash className="text-blue-500 text-[4rem] mb-4" />
        <p className="mb-2 text-[1.5rem] font-bold text-gray-800">
          Application Rejected
        </p>
        <p className="  text-gray-600 text-center">
          Your onboarding application is rejected
        </p>
      </div>

      <div className="mt-8 bg-gray-50 border w-full p-4 rounded-[.5rem] shadow">
        <p className="text-lg font-semibold mb-2 text-gray-800">
          Reason for Rejection
        </p>
        <p className=" text-gray-600 mb-4">
          {santa?.profileDraftDetails?.remark || "Not specified"}
        </p>

        <p className="text-md font-semibold mb-2 text-gray-800">Next Steps:</p>
        <div className="space-y-2 text-gray-600">
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>
              Contact support to understand the reason for rejection and seek
              guidance on how to proceed.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
