import { memo } from "react";

const Button = ({ text, type, id, isLoading, disabled }) => {
  let disableStyle =
    disabled === true
      ? " opacity-70 cursor-not-allowed"
      : "mirror-Animation opacity-100 cursor-pointer";

  return (
    <button
      type={type}
      className={`${disableStyle} blue-gradient before:w-[200px] text-white w-full py-3 rounded-md active:scale-[0.99] transition-all uppercase font-medium md:font-semibold  "cursor-pointer"
     `}
      id={id ? id : ""}
      // disabled={disabled}
    >
      {/* {text} */}
      {isLoading ? (
        <div
          style={{ borderTopColor: "white" }}
          className="loader w-[1rem]"
        ></div>
      ) : (
        text
      )}
    </button>
  );
};

export default memo(Button);
