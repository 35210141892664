import React from "react";
import { FaCamera, FaCheck } from "react-icons/fa6";

import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function DecorationPics({
  workImageStates,
  setWorkImageStates,
  order,
  openCameraForWorkImageClick,
}) {
  const decorImages = order.decorWorkPictures;

  return (
    <div className="flex flex-col rounded-lg bg-[white] px-4 pb-4 w-[95%] py-2 border mt-2 ">
      <div className="flex mb-4 justify-between items-center">
        <div className="flex items-center gap-2">
          <FaCamera className="text-[#2136d4] text-xl " />

          <div className="text-base  font-[500]">Take photos of decoration</div>
        </div>
        <div className="  text-sm text-gray-500 font-medium">
          {decorImages.length}/8
        </div>
      </div>

      {decorImages.length === 0 && (
        <div className=" text-[gray] text-center text-[.875rem] py-8 ">
          Upload decoration images. At least 3 images and max 8 images
        </div>
      )}

      {decorImages.map((curr, idx) => {
        return (
          <div className="w-full flex items-start relative text-[.875rem]  my-3 gap-2 ">
            <img
              onClick={() =>
                window.showMsg(<img className=" " src={curr.imgUrl} alt="" />)
              }
              className="w-[3.5rem] aspect-square rounded-lg  "
              src={curr.imgUrl + "?timeStamp=" + moment().valueOf()}
              alt=""
            />
            <div className="w-[70%] flex flex-col  ">
              <div className="font-[500] ">Decor photo {idx + 1}</div>
              <div className=" items-center flex gap-1   text-[green] font-[500] ">
                <div className="text-[.675rem] flex items-center justify-center p-1 text-[white]  bg-green-600  rounded-full aspect-square">
                  <FaCheck />
                </div>
                Uploaded
              </div>
            </div>
            {idx === decorImages.length - 1 && (
              <label
                onClick={() => {
                  setWorkImageStates((p) => ({
                    ...p,
                    isUploadingImg: false,
                    img: "",
                    imgFile: "",
                  }));
                  openCameraForWorkImageClick(idx, decorImages.length);
                }}
                className="rounded-lg cursor-pointer px-2 py-1 absolute right-0 flex items-center bg-gray-100 border border-gray-200 gap-2 justify-center "
              >
                <FaCamera className="text-[1.125rem] text-[#2136d4] " />
                <div className="text-[1rem] font-[500]">Retake</div>
              </label>
            )}
          </div>
        );
      })}

      {decorImages.length < 8 && (
        <>
          {workImageStates.isUploadingImg ? (
            <div className="rounded-lg w-full py-2 flex items-center bg-gray-100 border border-gray-200 gap-2 mt-4 justify-center ">
              <div className=" loader w-[1rem]"></div>
              <div className="text-[1rem] font-[500]">Uploading...</div>
            </div>
          ) : (
            <label
              onClick={() => {
                setWorkImageStates((p) => ({
                  ...p,
                  isUploadingImg: false,
                  img: "",
                  imgFile: "",
                }));
                openCameraForWorkImageClick(
                  decorImages.length,
                  decorImages.length
                );
              }}
              // htmlFor="decorationImg"
              className="rounded-lg cursor-pointer w-full py-2 flex items-center bg-gray-100 border border-gray-200 gap-2 mt-4 justify-center "
            >
              <FaCamera className="text-[1.125rem] text-[#2136d4] " />
              <div className="text-[1rem] font-[500]">Take more photos</div>
            </label>
          )}
        </>
      )}
    </div>
  );
}
