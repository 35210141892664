import { useLocation, useNavigate } from "react-router-dom";
import { BiSolidUser, BiUser } from "react-icons/bi";
import {
  PiHouseSimple,
  PiHouseSimpleFill,
  PiNotepad,
  PiNotepadFill,
} from "react-icons/pi";
import { RiWallet3Fill, RiWallet3Line } from "react-icons/ri";
import { useContext } from "react";
import GlobalStoreContext from "../../context/GlobalStoreContext";
import AuthContext from "../../context/AuthContext";

export default function BottomNavbar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    initialState: { isLoading },
  } = useContext(AuthContext);

  const { handleVibrate } = useContext(GlobalStoreContext);

  const handleClick = (path) => {
    handleVibrate();
    navigate(path);
  };

  function TopLine() {
    return (
      <div className="bg-[#2136D4] h-1 w-full rounded-b-lg absolute -top-[1px]  z-[1]" />
    );
  }

  if (isLoading) return null;

  const isNavbarVisible = links.some((nav) => nav.path === pathname);
  if (!isNavbarVisible && pathname !== "/") return null;

  const iconContainerStyle = "flex flex-col items-center w-[18%]  ";

  return (
    <div className="fixed bottom-0 left-0 right-0 z-[1] ">
      <div
        style={{
          boxShadow: "0 0 10px #d9d9d9",
        }}
        className="flex justify-between items-start text-base  bg-white border-t border-[#D9D9D9] rounded-t-xl px-4 h-fit  "
      >
        <div
          style={{
            fontFamily: "'Josefin Sans', sans-serif",
          }}
          className={`${iconContainerStyle} ${
            pathname === "/"
              ? "text-[#2136D4] font-[900] relative"
              : "font-[700] text-[#5f5f5f]"
          } relative text-[1rem] py-2 pb-[6px] `}
          onClick={() => handleClick("/")}
        >
          {pathname === "/" && <TopLine />}

          <div className=" text-[28px] -mb-[4px] relative ">
            {pathname === "/" ? <PiHouseSimpleFill /> : <PiHouseSimple />}
            <p
              className={`absolute top-[4px] text-[18px] right-[9px] font-bold  ${
                pathname === "/" && "text-white"
              } -rotate-[20deg] `}
            >
              e
            </p>
          </div>

          <p className="relative bottom-[1px]">ebo</p>
        </div>
        {links.map((_, i) => {
          return (
            <EachLinks
              key={i}
              _={_}
              handleClick={handleClick}
              pathname={pathname}
            />
          );
        })}
      </div>
    </div>
  );
}
function TopLine() {
  return (
    <div className="bg-[#2136D4] h-1 w-full rounded-b-lg absolute bottom-0 -top-[1px] z-10" />
  );
}
const EachLinks = ({ _, handleClick, pathname }) => {
  const iconContainerStyle = "flex flex-col items-center w-[18%]  ";

  return (
    <div
      className={`${iconContainerStyle} ${
        pathname === _.path
          ? "text-[#2136D4] font-[600] relative"
          : "font-[500] text-[#5f5f5f]"
      } relative text-[.875rem] py-2 pb-[6px] `}
      onClick={() => handleClick(_.path)}
    >
      {pathname === _.path && <TopLine />}
      <div className=" text-[28px] -mb-[4px] ">
        {pathname === _.path ? _.activeIcon : _.inActiveIcon}
      </div>
      <p className={`${pathname === _.path ? " " : " "}`}>{_.label}</p>
    </div>
  );
};

const links = [
  {
    id: 1,
    path: "/orders",
    label: "Orders",
    inActiveIcon: <PiNotepad />,
    activeIcon: <PiNotepadFill />,
  },
  {
    id: 2,
    path: "/earnings",
    label: "Earnings",
    inActiveIcon: <RiWallet3Line />,
    activeIcon: <RiWallet3Fill />,
  },
  {
    id: 3,
    path: "/my-account",
    label: "Profile",
    inActiveIcon: <BiUser />,
    activeIcon: <BiSolidUser />,
  },
];
