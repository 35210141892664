import React, { useState } from "react";
import { GiTakeMyMoney } from "react-icons/gi";
import { BsQrCodeScan } from "react-icons/bs";
import { handleUpdate } from "../../subC/helperFunctions";
import SlideToConfirmButton from "../../../../../assets/buttons/SlideToConfirmButton";

export default function BottomBtn({
  order,
  states,
  setStates,
  setParentStates,
  leaderSanta,
  santaId,
  openCameraForWorkImageClick,
}) {
  const [localStates, setLocalStates] = useState({
    isLoading: false,
    finalAmount: 0,
  });

  const isOptoutMessagePresent =
    leaderSanta?.santa?._id === santaId && leaderSanta.tags.includes("optout");

  if (
    order.priceDetail.paymentStatus === "pending" ||
    order.priceDetail.paymentStatus === "partial paid"
  ) {
    return (
      <div className="p-2 mt-6 fixed bottom-0 bg-[white] rounded-t-xl border-t border-blue-100 left-0 right-0 flex-col ">
        {isOptoutMessagePresent && <OptOutMessage />}
        <div className="  flex gap-4 ">
          <div
            onClick={() => setStates((p) => ({ ...p, cashCollectModal: true }))}
            className="flex-1 py-3 gap-1 font-[500] border border-blue-400 text-[#2136d4] bg-blue-100 flex items-center rounded-lg justify-center text-[1.125rem] "
          >
            <GiTakeMyMoney className="text-[1.25rem] relative bottom-[1px] " />{" "}
            Take Cash
          </div>
          <div
            onClick={() => setStates((p) => ({ ...p, showQrModal: true }))}
            className="flex-1 py-3 gap-1 mirror-Animation before:w-[20px] font-[500] blue-gradient flex items-center rounded-lg justify-center text-[1.125rem] "
          >
            <BsQrCodeScan className="text-[1.25rem] relative bottom-[1px] " />{" "}
            Show QR
          </div>
        </div>
      </div>
    );
  } else if (order.priceDetail.paymentStatus === "paid") {
    const decorImagesCount = order.decorWorkPictures.length;

    if (decorImagesCount < 3) {
      return (
        <div className="p-2 mt-6 fixed bottom-0 bg-[white] rounded-t-xl border-t border-blue-100 left-0 right-0 flex-col ">
          {isOptoutMessagePresent && <OptOutMessage />}
          <div
            onClick={() =>
              openCameraForWorkImageClick(
                order?.decorImages?.length,
                order?.decorImages?.length
              )
            }
            className="w-full flex gap-4"
          >
            {states.isUploadingDecorImg ? (
              <div className="flex-1 py-4 font-[500] blue-gradient opacity-80 flex-center rounded-lg gap-2 text-[1.125rem] ">
                <div
                  className="loader w-[1rem]"
                  style={{
                    borderTopColor: "white",
                  }}
                ></div>
                Uploading...
              </div>
            ) : (
              <div className="flex-1   flex-col mirror-Animation before:w-[200px] py-4 font-[500] blue-gradient flex items-center rounded-lg justify-center text-[1.125rem] ">
                {decorImagesCount === 0 ? (
                  <div className="">Take decor pictures</div>
                ) : (
                  <div className="">
                    Take {3 - decorImagesCount} more decor pictures
                  </div>
                )}

                <div className="text-[.875rem] font-[400] leading-4">
                  {decorImagesCount === 0 ? "at least 3 pictures" : ``}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="p-2 mt-6 fixed bottom-0 bg-[white] rounded-t-xl border-t border-blue-100 left-0 right-0 flex-col ">
        {isOptoutMessagePresent && <OptOutMessage />}
        <div className="  flex gap-4 ">
          <SlideToConfirmButton
            btnText={
              localStates.isLoading ? (
                <div
                  style={{ borderTopColor: "white" }}
                  className="loader w-[1rem]"
                ></div>
              ) : (
                "Service Complete"
              )
            }
            disabled={false}
            funOnFullSlide={async () => {
              await handleServiceComplete({
                setLocalStates,
                order,
                setParentStates,
              });
            }}
            disableDrag={localStates.isLoading}
            onFullSlideTextBeforeRelease={
              localStates.isLoading ? (
                <div
                  style={{ borderTopColor: "white" }}
                  className="loader w-[1rem]"
                ></div>
              ) : (
                "Release to confirm"
              )
            }
          />
        </div>
      </div>
    );
  }
}

const OptOutMessage = () => {
  return (
    <div className="flex-col px-4 mb-3">
      <div className="text-base font-semibold text-[#2136d4] ">Opted out</div>
      <div className="text-xs text-gray-700 ">
        You won’t be present; your team will cover this booking
      </div>
    </div>
  );
};

const handleServiceComplete = async ({
  order,
  setParentStates,
  setLocalStates,
  callMethods,
}) => {
  setLocalStates((p) => ({ ...p, isLoading: true }));
  try {
    await handleUpdate({
      orderId: order._id,
      isOrderCompletionProcess: true,
      setStates: setParentStates,
    });
  } catch (error) {
    console.error(error);
  }
  setLocalStates((p) => ({ ...p, isLoading: false }));
};

//serviceEnd , santaLeaved
