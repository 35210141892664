import React from "react";
import CompletedOrdersList from "../../components/orders/completedList/CompletedOrdersList";
import Header2 from "../../assets/headers/Header2";

export default function CompletedOrderspage() {
  return (
    <div className="flex flex-col pt-[4.5rem] bg-[#f0f5fe] items-center fixed top-0 left-0 bottom-0 right-0">
      <Header2 title={"Completed Orders"} />

      <CompletedOrdersList />
    </div>
  );
}
