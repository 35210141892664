import React, { useEffect, useMemo, useState } from "react";
import SantaTeam from "../subC/santaTeam/SantaTeam";
import { FaCheck } from "react-icons/fa6";
import EarningSummary from "../subC/earningSummary/EarningSummary";
import Header2 from "../../../../assets/headers/Header2";
import { useNavigate, useParams } from "react-router-dom";
import ServiceEarningQuickView from "../../liveOrderRequests/subC/ServiceEarningQuickView";
import { FaCheckCircle, FaRegStar, FaStar, FaUser } from "react-icons/fa";
import {
  getOrderForSantaApi,
  updateOrderForSantaApi,
} from "../../../../api/orderApi";
import { useAuthContext } from "../../../../context/AuthContext";
import { formatCurrency } from "../../../../utils/santaAppUtils";
import { BsEmojiLaughingFill, BsFillPhoneFill } from "react-icons/bs";
import { findServiceStatus } from "../subC/helperFunctions";
import { motion } from "framer-motion";
import moment from "moment";
import { BiChevronLeft } from "react-icons/bi";
// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export const projectionString =
  "date _id santaGroups productId images addressId feedbacks materialsReturningStatus imagesInstructionForSanta santaInstructions priceDetail orderWallet santaGroups rawMaterials tags addOns freebie bookingStatus  orderServiceStatus";

export default function CompletedOrder() {
  const nav = useNavigate();
  const {
    initialState: { santa },
  } = useAuthContext();

  const { orderId } = useParams();

  const [states, setStates] = useState({
    isLoading: true,
    order: null,
    workProcessRatingStars: 0,
    workProcessReview: "",
    customerBehaviourRatingStars: 0,
    customerBehaviourReview: "",
  });

  useEffect(() => {
    onInitialLoad();

    // eslint-disable-next-line
  }, [orderId]);

  const onInitialLoad = async () => {
    setStates((p) => ({ ...p, isLoading: true }));
    try {
      // handle order fetch
      const res = await getOrderForSantaApi({
        orderId,
        projectionString: projectionString,
      });

      if (res.isSuccess) {
        setStates((p) => ({
          ...p,
          order: res.order,
          workProcessRatingStars:
            res.order.feedbacks?.santa?.workProcess?.stars,
          customerBehaviourRatingStars:
            res.order.feedbacks?.santa?.userBehaviour?.stars,
          workProcessReview: res.order.feedbacks?.santa?.workProcess?.review,
          customerBehaviourReview:
            res.order.feedbacks?.santa?.userBehaviour?.review,
        }));
        console.log(res.order);
      }
    } catch (error) {
      console.log(error);
    }
    setStates((p) => ({ ...p, isLoading: false }));
  };

  const leaderSanta = useMemo(() => {
    let temp = states.order?.santaGroups?.santas?.find((santa) =>
      santa.tags.includes("leader")
    );

    if (!temp) {
      temp = states.order?.santaGroups?.santas[0];
    }

    return temp;
  }, [states.order?.santaGroups?.santas]);

  const payout = useMemo(() => {
    let temp = states.order?.santaGroups?.santas.find(
      (curr) => curr._id === santa._id
    );
    return temp?.payout || 0;
  }, [states.order?.santaGroups?.santas, santa._id]);

  const updateOrder = async (updateObject) => {
    try {
      const res = await updateOrderForSantaApi({
        queryObject: { _id: orderId },
        updateObject,
        projectionString: "_id feedbacks",
      });

      if (res.isSuccess) {
        console.log(res.order);
        let tempOrder = { ...states.order };
        tempOrder.feedbacks = res.order.feedbacks;
        setStates((p) => ({
          ...p,
          order: tempOrder,
          workProcessRatingStars:
            res.order.feedbacks?.santa?.workProcess?.stars,
          customerBehaviourRatingStars:
            res.order.feedbacks?.santa?.userBehaviour?.stars,
          workProcessReview: res.order.feedbacks?.santa?.workProcess?.review,
          customerBehaviourReview:
            res.order.feedbacks?.santa?.userBehaviour?.review,
        }));
      } else {
        window.toast("Oops something went wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (states.isLoading || !states.order) {
    return (
      <div className="fix-screen flex-center gap-2 ">
        <Header2 navFun={() => nav("/orders")} title={"Back to orders"} />

        <div className="loader w-[2rem]" />
        <p>Loading order...</p>
      </div>
    );
  }

  const order = states?.order;

  const completionTime =
    findServiceStatus(order.orderServiceStatus, "completed")?.child?.date ||
    moment(order.date.delivery.celebrationDate)
      .hour(parseInt(order.date.delivery.readyTime))
      .toISOString();

  return (
    <>
      <div className=" fixed top-0 flex flex-col space-y-4 overflow-scroll scroll-bar-remove bg-blue-50 items-center right-0 left-0 bottom-0 ">
        {/* <Header2 navFun={() => nav("/orders")} title={"Back to orders"} /> */}
        <AnimationTopContainer order={order} completionTime={completionTime} />

        <div className="w-full flex-col space-y-4 h-full overflow-scroll pb-16">
          {leaderSanta?.santa?._id === santa._id ? (
            <ServiceEarningQuickView order={order} />
          ) : (
            <div className="px-4 w-full ">
              <div className=" w-full flex flex-col relative  text-[.875rem] font-[400] p-4 bg-[white] rounded-lg border border-gray-200  ">
                <p className=" text-sm font-[500] text-gray-500">You earned</p>
                <p className=" text-2xl  font-semibold gap-2 flex items-center">
                  <span className="font-extrabold">
                    {formatCurrency(payout)}
                  </span>
                </p>
                <div className="bg-blue-100 py-1 px-2 mt-2 rounded-md bg-gradient-to-r text-sm text-[#2136d4]">
                  Admin santa has not yet splited the money!
                </div>
              </div>
            </div>
          )}
          <div className="px-4 w-full ">
            <div className="flex flex-col rounded-lg bg-[white] p-4 w-full border  ">
              <div className="flex z-[1] mb-4 relative gap-2 justify-between items-center">
                <div className="text-base  font-[500]">Work pictures</div>
                <div className="  text-gray-500 font-medium ">
                  {order?.decorWorkPictures?.length || 0}/8
                </div>
              </div>

              {order?.decorWorkPictures?.length === 0 && (
                <div className="flex-center w-full py-4 text-gray-400 font-medium">
                  No work pictures uploaded
                </div>
              )}

              {order?.decorWorkPictures?.map((curr, idx) => {
                return (
                  <div className="w-full flex items-start  text-[.875rem]  my-3 gap-2 ">
                    <img
                      onClick={() =>
                        window.showMsg(
                          <img className=" " src={curr.imgUrl} alt="" />
                        )
                      }
                      className="w-[3.5rem] aspect-square rounded-lg  "
                      src={curr.imgUrl}
                      alt=""
                    />
                    <div className="w-[70%] flex flex-col  ">
                      <div className="font-[500] ">Decor photo {idx + 1}</div>
                      <div className=" items-center flex gap-1   text-[green] font-[500] ">
                        <div className="text-[.675rem] flex items-center justify-center p-1 text-[white]  bg-green-600  rounded-full aspect-square">
                          <FaCheck />
                        </div>
                        Uploaded
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <FeedBackContainer
            {...{
              order,
              updateOrder,
              states,
              setStates,
            }}
          /> */}

          {leaderSanta?.santa?._id === santa._id && (
            <>
              <div className="px-4 w-full ">
                <p className=" text-[1rem] text-[gray] mb-1 px-[2px] ">
                  Earning Summary
                </p>
                <EarningSummary
                  adjustmentsArr={order?.orderWallet || []}
                  santaHandlingFee={order?.santaGroups?.santaHandlingFee}
                  santaServiceCosting={order?.santaGroups?.santaServiceCosting}
                  santaTip={order?.priceDetail?.santaTip}
                  order={order}
                />
              </div>
            </>
          )}

          <div className="px-4 mt-6 w-full">
            <p className=" text-[1.125rem] w-full text-center font-[600] ">
              <span className="text-[gray]">Santa </span>
              <span className="   text-[black]">Team</span>
            </p>
            <SantaTeam santaGroups={order?.santaGroups} />
          </div>
        </div>
      </div>
    </>
  );
}

const AnimationTopContainer = ({ completionTime }) => {
  const nav = useNavigate();
  return (
    <motion.div
      // initial={{
      //   height: "100dvh",
      //   borderBottomLeftRadius: 0,
      //   borderBottomRightRadius: 0,
      // }}
      // animate={{
      //   height: "28dvh",
      //   borderBottomLeftRadius: 20,
      //   borderBottomRightRadius: 20,
      // }}
      // transition={{
      //   delay: 1.2,
      //   ease: "easeOut",
      //   duration: 0.75,
      // }}
      className="flex flex-col h-[28dvh] overflow-hidden rounded-b-xl w-full flex-shrink-0 items-center pt-10 py-5 justify-center text-white z-[5] bg-green-600"
    >
      <div
        style={{
          animationDelay: "1.2s",
        }}
        className="flex items-center w-full animate__animated animate__fadeIn justify-start absolute top-4 left-4"
      >
        <div
          onClick={() => {
            nav(-1);
          }}
          className="flex items-center text-[1rem]"
        >
          <BiChevronLeft className="text-[1.5rem]" />
          <div className="flex flex-col ">
            <p className="font-[500] text-[1.125rem] ">Back</p>
          </div>
        </div>
      </div>
      <FaCheckCircle
        style={{
          animationDelay: "0s",
        }}
        className="text-[6rem] mb-4 animate__animated animate__backInUp  text-white  delay-1000"
      />

      <div
        style={{
          animationDelay: "0.2s",
        }}
        className="animate__animated animate__backInUp text-xl text-white  font-semibold flex items-center gap-2"
      >
        Order Completed <BsEmojiLaughingFill className="text-base" />
      </div>
      <div
        style={{
          animationDelay: "1.2s",
        }}
        className="animate__animated animate__fadeIn text-sm mt-1  text-white  font-regular"
      >
        on {moment(completionTime).format("D, MMM - h:mm A")}
      </div>
    </motion.div>
  );
};

const FeedBackContainer = ({ order, updateOrder, states, setStates }) => {
  const [isSubmitingUserFeedback, setIsSubmitingUserFeedback] = useState(false);
  const [isSubmitingWorkFeedback, setIsSubmitingWorkFeedback] = useState(false);

  return (
    <div className="w-full px-4 space-y-4 relative">
      <div className="w-full p-4 border border-gray-200 rounded-lg bg-white flex-col">
        <div className=" font-medium flex items-center text-base gap-2  ">
          <BsFillPhoneFill className="text-sm text-[#2136d4]" />
          Rate your work experience?
        </div>
        <div className="ml-5">
          <RatingStars
            states={states}
            setStates={setStates}
            ratingType="workProcessRatingStars"
            reviewType="workProcessReview"
          />
        </div>
        {/* preveiw of button only when : star > 0  */}
        {states.workProcessRatingStars > 0 &&
          order.feedbacks?.santa?.workProcess?.stars !==
            states.workProcessRatingStars && (
            <div
              onClick={async () => {
                setIsSubmitingWorkFeedback(true);
                try {
                  await updateOrder({
                    "feedbacks.santa.workProcess.review":
                      states.workProcessReview,
                    "feedbacks.santa.workProcess.stars":
                      states.workProcessRatingStars,
                  });
                } catch (error) {
                  console.error(error);
                }
                setIsSubmitingWorkFeedback(false);
              }}
              className="rounded-lg blue-gradient mt-4 text-center py-2 text-sm font-medium "
            >
              {isSubmitingWorkFeedback ? (
                <>
                  <div
                    className="loader w-[1rem] mr-2"
                    style={{
                      borderTopColor: "white",
                    }}
                  ></div>{" "}
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </div>
          )}
      </div>
      <div className="w-full p-4 border border-gray-200 rounded-lg bg-white flex-col">
        <div className=" font-medium flex items-center text-base gap-2  ">
          <FaUser className="text-sm text-[#2136d4]" /> Rate customer's
          behaviour
        </div>
        <div className="ml-5">
          <RatingStars
            states={states}
            setStates={setStates}
            ratingType="customerBehaviourRatingStars"
            reviewType="customerBehaviourReview"
          />
        </div>

        {/* preveiw of button only when : star > 0 && order should also not contain the stars > 0   */}
        {states.customerBehaviourRatingStars > 0 &&
          order.feedbacks?.santa?.userBehaviour?.stars !==
            states.customerBehaviourRatingStars && (
            <div
              onClick={async () => {
                setIsSubmitingUserFeedback(true);
                try {
                  await updateOrder({
                    "feedbacks.santa.userBehaviour.stars":
                      states.customerBehaviourRatingStars,
                    "feedbacks.santa.userBehaviour.review":
                      states.customerBehaviourReview,
                  });
                } catch (error) {
                  console.error(error);
                }
                setIsSubmitingUserFeedback(false);
              }}
              className="rounded-lg blue-gradient mt-4 text-center py-2 text-sm font-medium "
            >
              {isSubmitingUserFeedback ? (
                <>
                  <div
                    className="loader w-[1rem] mr-2"
                    style={{
                      borderTopColor: "white",
                    }}
                  ></div>
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </div>
          )}
      </div>
    </div>
  );
};

const RatingStars = ({ states, setStates, ratingType, reviewType }) => {
  const starsCountArray = [1, 2, 3, 4, 5];
  const existingReview =
    states.order?.feedbacks?.santa[
      reviewType === "workProcessReview" ? "workProcess" : "userBehaviour"
    ].review;

  const existingReviewStars =
    states.order?.feedbacks?.santa[
      ratingType === "workProcessRatingStars" ? "workProcess" : "userBehaviour"
    ].stars;
  return (
    <div className="flex-col w-full">
      <div className="flex items-center gap-1.5 mt-2 ">
        {starsCountArray.map((star) => {
          if (states[ratingType] >= star) {
            let starColor = "text-[#FFD700]";
            if (states[ratingType] === 3) {
              starColor = "text-orange-600";
            }

            if (states[ratingType] < 3) {
              starColor = "text-red-600";
            }

            return (
              <FaStar
                key={star}
                onClick={() => setStates({ ...states, [ratingType]: star })}
                className={`${starColor} animate__animated animate__tada   text-2xl cursor-pointer`}
              />
            );
          }
          return (
            <FaRegStar
              key={star}
              onClick={() => setStates({ ...states, [ratingType]: star })}
              className="text-gray-400 text-2xl cursor-pointer"
            />
          );
        })}
      </div>
      {existingReview && (
        <div className=" text-gray-600 text-sm mt-3">{existingReview}</div>
      )}

      {/* only present for rating less than 4 */}
      {((states[ratingType] < 4 && states[ratingType] > 0) || existingReview) &&
        existingReviewStars !== states[ratingType] && (
          <textarea
            className="w-full p-2 border border-gray-200 text-sm rounded-lg mt-3"
            placeholder="Please tell us what you feel bad..."
            value={states[reviewType]}
            onChange={(e) =>
              setStates({ ...states, [reviewType]: e.target.value })
            }
          />
        )}
    </div>
  );
};
