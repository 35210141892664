import React from "react";
import { TbCategoryPlus, TbRepeat } from "react-icons/tb";
import { findServiceStatus } from "../../../../subC/helperFunctions";

export default function Materials({ order, setStates, isAcceptedOrder }) {
  return (
    <div className="flex flex-col w-full px-4 ">
      <div className="w-full text-[#171717] flex flex-col gap-2 border-t border-dotted mt-4 pt-2">
        <div className=" font-[600] text-[1rem] flex justify-between item-center">
          <p className="flex items-center gap-1">
            <TbCategoryPlus className=" relative bottom-[1px]" />
            Materials
          </p>
        </div>

        <div className=" flex flex-col justify-center bg-white rounded-lg  py-2 px-2">
          {order?.productId.rawMaterials?.map((curr, idx) => {
            const item = curr.item ? curr.item : curr.customItem;

            return (
              <div
                key={idx}
                className={`flex justify-between items-start border-b mb-2 pb-2 px-3 `}
              >
                <div className="flex gap-4 w-[80%] items-start">
                  <div className="w-[90%] flex items-start gap-3">
                    <img
                      onClick={() => {
                        window.showMsg(
                          item?.images ? item?.images[0] : item?.image,
                          "img"
                        );
                      }}
                      src={item?.images ? item?.images[0] : item?.image}
                      alt="material"
                      className=" rounded-lg overflow-hidden w-[3.5rem] min-w-[3.5rem] aspect-square bg-blue-50 "
                    />
                    <div className="flex flex-col pt-[1px] w-full ">
                      <p
                        className="text-black first-letter:capitalize
                           max-w-[100%] text-ellipsis overflow-hidden whitespace-nowrap font-medium"
                      >
                        {item.itemName}
                      </p>
                      <div className="flex  mt-1">
                        <div className="flex flex-col pr-4 border-r leading-4 items-center border-gray-200  ">
                          <div className="text-[.875rem] font-[500]  ">
                            x {curr.quantity}
                          </div>
                          <div className="text-[.75rem] text-[gray] ">
                            Needed
                          </div>
                        </div>
                        <div className="flex flex-col pl-4  leading-4 items-center ">
                          <div className="text-[.875rem] font-[500]  ">
                            x {curr.extraQuantity}
                          </div>
                          <div className="text-[.75rem] text-[gray] ">
                            Extra
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {item?.tags?.includes("rent") && (
                    <p>
                      <TbRepeat className="text-[1rem]" />
                    </p>
                  )}
                </div>
                <div className="flex items-start ">
                  x {curr.quantity + curr.extraQuantity}
                  {curr.packetType === "meter"
                    ? "M"
                    : curr.packetType === "liter"
                    ? "L"
                    : ""}
                </div>
              </div>
            );
          })}

          {isAcceptedOrder &&
            findServiceStatus(order.orderServiceStatus, "serviceStarted")
              ?.child &&
            !findServiceStatus(order.orderServiceStatus, "serviceEnd")
              ?.child && (
              <div
                onClick={() => {
                  setStates((p) => ({ ...p, materialAddModal: true }));
                }}
                className="w-full rounded-lg py-3 text-[#2136d4] border border-blue-200 bg-blue-100 text-center"
              >
                Add more +
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
