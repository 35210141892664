import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { deleteUserPermanentlyApi, generate_otp } from "../../api/santaApi";
import AuthContext from "../../context/AuthContext";
import DeletionOtpModal from "./DeletionOtpModal";

export default function UserDelete() {
  const {
    initialState: { santa },
  } = useContext(AuthContext);
  const [states, setStates] = useState({
    phoneNumber: santa?.personalDetails?.phoneNumber || "",
    isLoading: false,
    isOtpSending: false,
    otp: "",
    otpHash: "",
    isDeletionOtpModal: false,
  });

  const handleDelete = async () => {
    try {
      if (states.otp.length !== 6) {
        handleMsgToastRender("Invalid OTP");
        return;
      }
      setStates((p) => ({ ...p, isLoading: true }));
      const res = await deleteUserPermanentlyApi({
        options: {
          tags: ["deleteSantaAccount"],
          phoneNumber: states.phoneNumber,
          otp: states.otp,
          otpHash: states.otpHash,
        },
      });

      if (res.isSuccess) {
        handleMsgToastRender("User deleted successfully");
        setStates((p) => ({
          ...p,
          isLoading: false,
          isOtpSending: false,
          otp: "",
          otpHash: "",
          isDeletionOtpModal: false,
        }));
        localStorage.clear();
        window.location.reload();
        window.location.href = "/";
      } else {
        handleMsgToastRender(res.message);
        setStates((p) => ({
          ...p,
          isLoading: false,
        }));
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
  };

  const handleOtpSend = async () => {
    try {
      if (states.phoneNumber.length !== 10) {
        handleMsgToastRender("Invalid phone number");
        return;
      }
      setStates((p) => ({
        ...p,
        isOtpSending: true,
        isLoading: false,
      }));
      const res = await generate_otp({
        url: process.env.REACT_APP_BACKEND_URL,
        options: {
          tags: ["login", "otpGenerate"],
          data: { number: states.phoneNumber },
        },
      });
      if (res.isSuccess) {
        setStates((p) => ({
          ...p,
          isDeletionOtpModal: true,
          otpHash: res.hash,
          isOtpSending: false,
        }));
      } else {
        if (!res.isExist) {
          handleMsgToastRender("No santa exist with this phone number");
        } else {
          handleMsgToastRender("Something went wrong! Try again later");
        }
        setStates((p) => ({
          ...p,
          isOtpSending: false,
        }));
      }

      return true;
    } catch (error) {
      console.error(error.message);
      return false;
    }
  };
  console.log(states);
  const handleMsgToastRender = (msg) => {
    window.toast(msg);
  };
  return (
    <div className="flex flex-col mt-[7rem]  justify-center text-[1rem] items-center  ">
      <div className="fixed top-0 right-0 left-0 h-[5rem] flex px-4 justify-start bg-[#2136d4] items-center">
        <Link to="/" className="flex  w-full flex-1">
          <h1
            style={{
              fontFamily: "'Josefin Sans', sans-serif",
            }}
            className="  relative text-[2.5rem] flex justify-center items-end text-[white] font-[800]  "
          >
            ebo
          </h1>
        </Link>
      </div>
      <div className=" max-w-[55rem] w-full flex-col  flex pb-[4rem]  px-6">
        <div className="font-[600] text-[2rem] mb-4 ">Delete User</div>
        <div className="text-[1rem]">
          We understand that you've decided to delete your account, and we
          respect your decision. Please be aware that this process is
          irreversible, and all your data associated with your account will be
          permanently deleted. Before proceeding, we want to ensure that you are
          fully informed about the consequences of this action.
        </div>
        <div className="font-[600] text-[1.5rem]  mt-6 ">Important Points:</div>
        <div className="font-[600] text-[1.25rem] mt-4 ">
          1. Irreversible Action:
        </div>
        <div className="text-[1rem]">
          Deleting your account means that all your data, including profile
          information, preferences, and usage history, will be permanently
          removed from our system. This action cannot be undone.
        </div>
        <div className="font-[600] text-[1.25rem] mt-4 ">
          2. Loss of Access:
        </div>
        <div className="text-[1rem]">
          Once your account is deleted, you will no longer have access to any
          content, services, or features associated with this account.
        </div>
        <div className="font-[600] text-[1.25rem] mt-4 ">3. Data Security:</div>
        <div className="text-[1rem]">
          Your privacy is important to us. We want to assure you that we take
          data security seriously. However, after the account deletion, we will
          not be able to retrieve or restore any of your information.
        </div>
        <div className="text-[1rem] mt-6">
          If you are certain about deleting your account, please proceed by
          entering your phone number below to initiate the account deletion
          process.
        </div>
        {/* phone number input */}
        <input
          className="border rounded-xl p-2 mt-2 w-[20rem]"
          type="number"
          value={states.phoneNumber}
          onChange={(e) =>
            setStates((p) => ({ ...p, phoneNumber: e.target.value }))
          }
          placeholder="Enter your phone number"
        />
        <div
          onClick={() => {
            handleOtpSend();
          }}
          className="border rounded-md py-1 text-[red] w-[10rem] cursor-pointer flex items-center
         justify-center border-[red] bg-[#ffe2e2] mt-4"
        >
          {states.isOtpSending ? (
            <div
              style={{
                borderTopColor: "red",
              }}
              className="loader  w-[1rem] my-1 "
            ></div>
          ) : (
            "Proceed to delete"
          )}
        </div>
        <DeletionOtpModal
          handleDelete={handleDelete}
          parentStates={states}
          setParentStates={setStates}
        />
        <div className="font-[600] text-[1.25rem] mt-4 ">Contact Us:</div>
        <div className="text-[1rem]">
          If you have any concerns, questions, or need assistance, please feel
          free to contact our support team at{" "}
          <a className="italic text-[#2136d4]" href="mailto:contact@ebonow.com">
            contact@ebonow.com
          </a>{" "}
          or{" "}
          <a href="tel:+919046727027" className="text-[#2136d4]">
            +91 9046727027
          </a>
        </div>
        <div className="text-[1rem] mt-6">
          Thank you for being a part of our community. We appreciate your trust
          and participation.
        </div>
        <div className="text-[1rem] mt-6">Sincerely,</div>
        <div className="text-[1rem] font-[500]">ebo</div>
      </div>
    </div>
  );
}
