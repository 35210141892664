import { useEffect, useState } from "react";

export default function EarningSummary({
  adjustmentsArr,
  santaHandlingFee,
  santaServiceCosting,
  order,
  santaTip = 0,
}) {
  const [states, setStates] = useState({
    finalAmount: 0,
  });

  useEffect(() => {
    computeFinalAmount();

    // eslint-disable-next-line
  }, [adjustmentsArr, santaHandlingFee, santaServiceCosting]);

  const computeFinalAmount = () => {
    let amt = 0;
    amt += (santaServiceCosting || 0) + (santaHandlingFee || 0);
    for (const adj of adjustmentsArr) {
      if (adj.inOutType === "in") {
        amt += adj.amount || 0;
      } else {
        amt -= adj.amount || 0;
      }
    }
    setStates((p) => ({ ...p, finalAmount: amt }));
  };
  return (
    <div className="bg-white py-4 text-[gray] text-[.875rem] rounded-lg border border-gray-200">
      <div className="space-y-2 capitalize">
        {santaServiceCosting > 0 && (
          <div className="px-4 flex justify-between items-center ">
            <p>Service Cost </p>
            <p className={`font-medium text-black`}>₹{santaServiceCosting}</p>
          </div>
        )}

        {adjustmentsArr.length > 0 &&
          adjustmentsArr.map((adj, idx) => (
            <div
              key={idx}
              className={`px-4 flex justify-between items-center ${
                adj.inOutType === "out" && ""
              }`}
            >
              <p>{adj.inOutName} </p>
              <p className={` font-medium   text-black`}>
                {adj.inOutType === "out" && "-"} ₹{adj.amount}
              </p>
            </div>
          ))}

        {santaHandlingFee > 0 && (
          <div className="px-4 flex justify-between items-center">
            <p>Handling Fee </p>
            <p className={`font-medium text-black`}>₹{santaHandlingFee}</p>
          </div>
        )}

        {santaTip > 0 && (
          <div className="px-4 flex justify-between items-center ">
            <p>Santa tip</p>
            <p className={`font-medium text-black`}>₹{santaTip}</p>
          </div>
        )}
      </div>
      <div className="px-4 border-t border-dashed mt-3 pt-3 flex justify-between items-center">
        <p className="font-semibold text-black">Earning total</p>
        <p className="font-semibold text-black">
          ₹{states.finalAmount + (santaTip || 0)}
        </p>
      </div>
    </div>
  );
}
