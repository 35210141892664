import { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import AuthContext from "../../../../../context/AuthContext";
import { RxCross2 } from "react-icons/rx";
import { BiEdit } from "react-icons/bi";
import { MdEdit } from "react-icons/md";

export default function SantaTeamManagementModal({
  states,
  setStates,
  callBackFunc,
}) {
  const handleClose = () =>
    setStates((p) => ({
      ...p,
      santaTeamManagementModal: false,
    }));

  return (
    <AnimatePresence>
      {states.santaTeamManagementModal && (
        <Modal
          handleClose={handleClose}
          order={states.order}
          callBackFunc={callBackFunc}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose, callBackFunc, order }) => {
  const [states, setStates] = useState({
    isSaving: false,
    santaGroups: order?.santaGroups, // it is an object having santas as an array of object that contains santa , tags, isAMember, teamLeader, payout
  });
  const {
    initialState: { santa, token },
  } = useContext(AuthContext);

  const santas = states.santaGroups?.santas || [];

  const mainSantas = santas.filter((curr) => {
    if (!curr.isAMember) return true;
    else return false;
  });

  const helperSantas = santas.filter((curr) => {
    if (curr.isAMember) return true;
    else return false;
  });

  console.log("all santas: ", santas, "main santas: ", mainSantas);

  useEffect(() => {
    if (states.phoneNumber && states.name) {
      setStates((prev) => ({ ...prev, canSave: true }));
    } else {
      setStates((prev) => ({ ...prev, canSave: false }));
    }

    return () => {};
  }, [states.phoneNumber, states.name]);

  const onSave = async () => {
    if (!states.canSave) return;

    if (
      states.phoneNumber.length !== 10 ||
      typeof parseInt(states.phoneNumber) !== "number"
    ) {
      window.toast("Please enter valid phone number");
      return;
    }
  };

  const handleTagChangeForSanta = async (santa) => {
    try {
      // for main santa tag management
      if (!santa?.isAMember) {
        // santa is off site, so ask the santa that are going to be on site?
        window.warningModal({
          msg: "Are you going on work?",
          lb_text: "No",
          rb_text: "Yes",
          lb_fn: () => {
            // from tag remove "offSite" and "leader" and add "onSite"

            const updatedSantas = states.santaGroups.santas.map((curr) => {
              if (curr.santa._id === santa._id) {
                return {
                  ...curr,
                  tags: curr.tags
                    .filter(
                      (currTag) => currTag !== "onSite" && currTag !== "leader"
                    )
                    .concat("offSite"),
                };
              } else return curr;
            });
            setStates((prev) => ({
              ...prev,
              santaGroups: {
                ...prev.santaGroups,
                santas: updatedSantas,
              },
            }));
          },
          rb_fn: () => {
            // from tag remove "offSite" and add "leader" and "onSite"
            const updatedSantas = states.santaGroups.santas.map((curr) => {
              if (curr.santa._id === santa._id) {
                return {
                  ...curr,
                  tags: curr.tags
                    .filter((currTag) => currTag !== "offSite")
                    .concat("onSite")
                    .concat("leader"),
                };
              } else return curr;
            });
            setStates((prev) => ({
              ...prev,
              santaGroups: {
                ...prev.santaGroups,
                santas: updatedSantas,
              },
            }));
          },
        });
      }
    } catch (error) {
      window.toast("Something went wrong, please try again");
    }
  };

  return (
    <div
      className="fixed top-0 right-0 left-0   bottom-0
       flex items-end justify-end z-[99] "
    >
      <div
        onClick={handleClose}
        className=" fixed top-0 right-0 left-0 bottom-0 z-[1] bg-[#000000a7]"
      ></div>

      <motion.div
        initial={{
          translateY: 800,
        }}
        animate={{
          translateY: 0,
        }}
        exit={{
          translateY: 800,
        }}
        transition={{ ease: "easeInOut" }}
        className=" w-full h-[90%]
       flex items-center rounded-t-xl overflow-hidden
        drop-shadow border-t bg-[white] z-[2] text-[.875rem] flex-col "
      >
        {states.isLoading && (
          <div
            className="absolute text-white gap-2 top-0 right-0 left-0 bottom-0
             flex items-center justify-center bg-[#000000a5] z-[1]"
          >
            <div
              style={{ borderTopColor: "white" }}
              className="loader w-[1rem]"
            ></div>
            Saving...
          </div>
        )}

        {/* header */}
        <div className="w-full font-[500]  bg-blue-100 flex items-center justify-between text-[1rem] px-2 mb-2 py-2 ">
          <div className="text-[1.125rem] font-[600] ">Santa team</div>
          <div
            onClick={handleClose}
            className="text-[black] bg-[white] rounded-full p-1 cursor-pointer"
          >
            <RxCross2 className="text-[1.25rem] text-[black] " />
          </div>
        </div>

        <div className=" overflow-scroll relative flex flex-col   w-full h-full rounded-t-lg  ">
          <div className="w-full px-3 text-[1rem]  mb-1 mt-2 font-medium ">
            Main santas
          </div>

          {mainSantas.map((currMainSanta) => {
            const currMainSantaDetails = currMainSanta?.santa?.personalDetails;

            return (
              <div
                key={currMainSanta?.santa?._id}
                className="flex relative cursor-pointer mx-2 justify-between whitespace-nowrap items-center border rounded-xl p-2  bg-white"
              >
                <div className="flex  justify-between gap-2 items-center w-full ">
                  <div className="flex gap-2 items-start w-full ">
                    <img
                      src={
                        currMainSantaDetails?.image || "/images/santaIcon.png"
                      }
                      alt={currMainSantaDetails?.name}
                      className="rounded-full w-[3rem] bg-[white] aspect-square"
                    />

                    <div className="flex flex-col">
                      <div className="font[600] text-[1rem] overflow-hidden text-ellipsis whitespace-nowrap max-w-[12rem]  ">
                        {currMainSanta?.santa?._id === santa._id && "(You)"}{" "}
                        {currMainSantaDetails?.name || "Name not provided"}
                      </div>
                      <div className="text-[.75rem] font-[400]  ">
                        {currMainSantaDetails?.phoneNumber}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => {
                    handleTagChangeForSanta(currMainSanta?.santa);
                  }}
                  className={` ${
                    currMainSanta?.tags?.includes("offSite")
                      ? "text-[white] bg-red-600"
                      : " blue-gradient "
                  } text-[.6875rem] px-2 py-[2px] flex items-center gap-1 rounded `}
                >
                  {currMainSanta?.tags?.includes("offSite")
                    ? "Off site"
                    : "Leader"}

                  <MdEdit className="text-white  " />
                </div>
              </div>
            );
          })}
          <div className="w-full px-3 text-[1rem]  mb-1 mt-3 font-medium ">
            Helper team
          </div>

          {mainSantas.map((currSanta) => {
            const currMainSantaDetails = currSanta?.santa?.personalDetails;

            return (
              <div
                key={currSanta?.santa?._id}
                className="flex relative cursor-pointer mx-2 justify-between whitespace-nowrap items-center border rounded-xl p-2  bg-white"
              >
                <div className="flex  justify-between gap-2 items-center w-full ">
                  <div className="flex gap-2 items-start w-full ">
                    <img
                      src={
                        currMainSantaDetails?.image || "/images/santaIcon.png"
                      }
                      alt={currMainSantaDetails?.name}
                      className="rounded-full w-[3rem] bg-[white] aspect-square"
                    />

                    <div className="flex flex-col">
                      <div className="font[600] text-[1rem] overflow-hidden text-ellipsis whitespace-nowrap max-w-[12rem]  ">
                        {currSanta?.santa?._id === santa._id && "(You)"}{" "}
                        {currMainSantaDetails?.name || "Name not provided"}
                      </div>
                      <div className="text-[.75rem] font-[400]  ">
                        {currMainSantaDetails?.phoneNumber}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={` ${
                    currSanta?.tags?.includes("leader")
                      ? " blue-gradient "
                      : " text-[black] bg-gray-50 border border-gray-200  "
                  } text-[.6875rem] px-2 py-[2px] flex items-center gap-1 rounded `}
                >
                  Helper
                </div>
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};
