import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { TiWarning } from "react-icons/ti";
import AuthContext from "../../../context/AuthContext";
import usePullToRefresh, {
  RefreshLoader,
} from "../../../hooks/usePullToRefresh";
import Header2 from "../../../assets/headers/Header2";
import { getOrderForSantaApi } from "../../../api/orderApi";
import SlideToConfirmButton from "../../../assets/buttons/SlideToConfirmButton";
import ReturningItemsRow from "./subC/ReturningItemsRow";
import OrderSummaryInfoContainer from "./subC/OrderSummaryInfoContainer";
import CallToUserModal from "../../../assets/CallToUserModal";
import { santaApi } from "../../../api/santaApi";
import DirectionPage from "./subC/DirectionPage";
import ReturnMaterialsPickup from "./subC/ReturnMaterialsPickup";
import ReturnDonePage from "./subC/ReturnDonePage";

import moment from "moment-timezone";
import AddressIdx from "../orderDetails/liveOrder/subC/address/AddressIdx";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export const projectionString =
  "date _id santaGroups productId images addressId materialsReturningStatus QRScanner decorWorkPictures disableCallMasking imagesInstructionForSanta santaInstructions priceDetail orderWallet santaGroups rawMaterials tags addOns freebie bookingStatus  orderServiceStatus orderOtp";

export default function ReturnableOrderIndex() {
  const { orderId } = useParams();
  const nav = useNavigate();

  const {
    initialState: { santaId, santa },
  } = useContext(AuthContext);

  const containerRef = useRef(null);

  const [refereshStates, setRefereshStates] = useState({});

  const [states, setStates] = useState({
    isLoadingOrder: true,
    isLoading: false,
    order: null,
    returnableItems: [],
    CallToUserModal: {
      isRender: false,
      user: [],
      onErrorCallBack: null,
    },
    presentedPage: "default", // "directionPage", "materialsCheckoutPage", "returnDonePage"
  });

  useEffect(() => {
    onInitialLoad({ santaId, orderId, setStates, states });
    // eslint-disable-next-line
  }, []);

  usePullToRefresh(
    containerRef,
    () => onInitialLoad(),
    (params) => {
      setRefereshStates(params);
    },
    {
      isGradient: false,
      loaderColor: "#2136d4",
    }
  );

  const onInitialLoad = async () => {
    try {
      // handle order fetch
      setStates((p) => ({ ...p, isLoadingOrder: true }));

      const res = await getOrderForSantaApi({
        orderId,
        projectionString: projectionString,
      });

      if (res.isSuccess) {
        setStates((p) => ({
          ...p,
          isLoadingOrder: false,
          order: res.order,
          returnableItems: res.order?.productId?.rawMaterials?.filter((curr) =>
            curr?.tags?.includes("rental")
          ),
        }));
      } else {
        setStates((p) => ({ ...p, isLoadingOrder: false }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateOrder = useCallback(
    async (updateObject) => {
      try {
        const options = {
          tags: ["order", "updateOrderDetail"],
          queryObject: { _id: states.order._id },
          updateObject,
        };
        const res = await santaApi(options);

        if (res.isSuccess) {
          setStates((p) => ({
            ...p,
            order: res.order,
          }));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [states.order]
  );

  const getBtnInfo = useCallback(() => {
    const materialsReturningStatusArray =
      states?.order?.materialsReturningStatus; // [{status, date, remark}...]

    const statusSequence = ["initiated", "atLocation", "returned"];

    const currentStatus =
      materialsReturningStatusArray?.length > 0
        ? materialsReturningStatusArray[
            materialsReturningStatusArray?.length - 1
          ]
        : null;

    const currentStatusIndex = statusSequence.indexOf(currentStatus?.status);

    const nextStatus = statusSequence[currentStatusIndex + 1];
    const btnDataForEachStatus = {
      initiated: {
        btnText: "Start return process",
        btnFn: () => {
          // update the order ( return status as initiated )
          updateOrder({
            returnStatus: "initiated",
            $push: {
              materialsReturningStatus: {
                status: "initiated", // initiated, atLocation, returned
                date: moment().format(),
                tags: ["santa"],
                remark: `Return process initiated by ${santa.personalDetails.name} (santa - ${santa._id})`,
              },
            },
          });

          setStates((p) => ({ ...p, presentedPage: "directionPage" }));
        },
      },
      atLocation: {
        btnText: "View direction",
        btnFn: () => {
          setStates((p) => ({ ...p, presentedPage: "directionPage" }));
        },
      },
      returned: {
        btnText: "Start materials pickup",
        btnFn: () => {
          setStates((p) => ({ ...p, presentedPage: "materialsCheckoutPage" }));
        },
      },
    };

    return btnDataForEachStatus[nextStatus];
  }, [states.order, santa, updateOrder]);

  if (!states.isLoadingOrder && !states.order) {
    return (
      <div className=" fixed  pt-[12.5rem] text-[.875rem] top-0 right-0 left-0 bottom-0 bg-blue-50 flex flex-col  ">
        <div className="fixed items-center justify-center flex flex-col top-0 right-0 left-0 rounded-b-xl blue-gradient p-4 py-6">
          <FaAngleLeft
            onClick={() => nav(-1)}
            className=" fixed top-6 left-4 text-[1.5rem]  "
          />
          <div className="flex mt-4 w-full justify-center text-[white]">
            <div className="flex flex-col font-[500] text-[1rem] items-center">
              <TiWarning className="text-[6rem]" />
              <div className=" ">Something went wrong</div>
              <div
                onClick={() =>
                  onInitialLoad({ santaId, orderId, setStates, states })
                }
                className="rounded-full px-2 mt-2 bg-[#f0f5fe76] text-[white]"
              >
                Try again
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (states.isLoadingOrder) {
    return (
      <div className="flex flex-col justify-center items-center gap-4 h-[100vh]">
        <div className="loader w-[2rem]" />
        <p>Loading order status...</p>
      </div>
    );
  }

  if (states.presentedPage === "returnDonePage") {
    return <ReturnDonePage />;
  }

  return (
    <>
      <Header2
        navFun={() => nav("/orders")}
        title={`#${states.order._id}`}
        order={states.order}
      />
      <CallToUserModal parentStates={states} setParentStates={setStates} />

      {states.presentedPage === "directionPage" && (
        <DirectionPage
          {...{
            states,
            setStates,
            MIN_DISTANCE_FOR_ARRIVE_INVALIDATION:
              states.order?.date?.delivery?.minDistanceOfSantaFromLocation,
            address: states.order.addressId,
            updateOrder,
          }}
        />
      )}
      {states.presentedPage === "materialsCheckoutPage" && (
        <ReturnMaterialsPickup
          {...{
            states,
            setStates,
            updateOrder,
          }}
        />
      )}

      {states.presentedPage === "default" && (
        <>
          <div className="fixed top-0 left-0 right-0 bottom-0 ">
            <div
              ref={containerRef}
              className="text-[.875rem] pt-[4rem] space-y-4 px-4 items-center  scroll-bar-remove  overflow-x-hidden overflow-y-scroll h-full pb-[100px] bg-blue-50 flex flex-col"
            >
              <RefreshLoader refereshStates={refereshStates} />
              <OrderSummaryInfoContainer order={states?.order} />
              <ReturningItemsRow {...{ states }} />
              <div
                className={`flex flex-col w-full bg-[white]  rounded-lg border border-gray-200 mt-2 py-4 `}
              >
                <AddressIdx
                  {...{
                    order: states.order,
                    updateOrder: updateOrder,
                    isContainer: false,
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="fixed bottom-0 right-0 p-3 left-0 h-[5rem] bg-white">
        <SlideToConfirmButton
          btnText={getBtnInfo()?.btnText}
          funOnFullSlide={getBtnInfo()?.btnFn}
        />
      </div>
    </>
  );
}
