import React from "react";
import { SANTA_HELP_NUMBER } from "../../../../utils/constants";
import { MdCall } from "react-icons/md";

export default function ContactSupportButton() {
  return (
    <div className="w-full flex py-3 items-center justify-between px-4   gap-2">
      <div
        onClick={() => {
          window.openUrl(`tel:${SANTA_HELP_NUMBER}`);
        }}
        className="flex items-center text-[#2136d4] border border-[#2136d4] bg-blue-100 rounded-lg w-full text-base font-[600] py-3 justify-center gap-2"
      >
        <MdCall className="text-base mb-[3px] " />
        Contact ebo support
      </div>
    </div>
  );
}
