import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { FaRegClock } from "react-icons/fa";
import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function DirectionPageHeader({ navFun, order }) {
  const delivery = order.date.delivery;
  return (
    <div className="fixed top-0 right-0 left-0 h-[5rem] border-b rounded-b-xl py-3 px-2 flex flex-col items-center text-[1rem]  blue-gradient z-10 ">
      <div
        onClick={navFun}
        className="flex w-full items-center text-[1rem] relative"
      >
        <BiChevronLeft className="text-[1.5rem] absolute left-0" />
        <p className="w-full text-[1.125rem] text-center font-[600] ">
          Service location on the way{" "}
        </p>
      </div>
      <p className=" text-[1rem] flex items-center gap-2 font-[500] mt-1 ">
        <FaRegClock className="text-[white]" /> Arrival by
        {moment(delivery.arrivingTime, "HH").format(" hh:mm A")}
      </p>
    </div>
  );
}
