import React, { useContext, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross1 } from "react-icons/rx";
import { startExocallApi } from "../api/santaApi";
import AuthContext from "../context/AuthContext";

export default function CallToUserModal({ parentStates, setParentStates }) {
  const handleClose = async () => {
    setParentStates((p) => ({
      ...p,
      CallToUserModal: {
        isRender: false,
        user: null,
        onErrorCallBack: null,
      },
    }));
  };

  return (
    <AnimatePresence>
      {parentStates.CallToUserModal.isRender && (
        <Modal
          handleClose={handleClose}
          onErrorCallBack={parentStates.CallToUserModal?.onErrorCallBack}
          user={parentStates.CallToUserModal?.user}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose, user, onErrorCallBack }) => {
  const {
    initialState: { santa },
  } = useContext(AuthContext);

  const [states, setStates] = useState({
    isCalling: false,
    callInitiated: false,
  });

  const exophone = "08047493366";

  const handleCall = async () => {
    try {
      setStates((p) => ({ ...p, isCalling: true }));

      const res = await startExocallApi({
        exophone,
        userNumber: user.phoneNumber,
        santaNumber: santa?.personalDetails?.phoneNumber,
      });

      if (res.isSuccess) {
        window.toast("Call initiated");
      } else {
        if (onErrorCallBack) {
          await onErrorCallBack();
          handleClose();
        } else {
          window.toast("Unable to initiate call", "error");
        }
      }
      setStates((p) => ({ ...p, callInitiated: true }));
    } catch (error) {
      console.log(error);
      setStates((p) => ({ ...p, isCalling: false }));
    }
  };

  return (
    <div className="fixed top-0 z-[999] flex items-end justify-end right-0 left-0 bottom-0  ">
      <div
        onClick={handleClose}
        className="fixed top-0 z-[-1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
      ></div>
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: "0%", opacity: 1 }}
        exit={{ y: "100%", opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="flex items-center justify-center w-full  flex-col bg-[white] rounded-t-lg   "
      >
        <div className=" w-full flex items-center justify-between p-4 pb-0  ">
          <div className="flex flex-col ">
            <p className="text-[1.125rem] font-[500] ">Call to {user?.name}</p>
          </div>
          <div
            onClick={handleClose}
            className="cursor-pointer rounded-full relative bg-white border border-gray-200 -top-[3.5rem] left-[.5rem] flex items-center justify-center w-[2rem] h-[2rem] "
          >
            <RxCross1 />
          </div>
        </div>
        <div className="flex p-4 flex-col w-full items-end justify-end gap-2">
          <button
            onClick={() => {
              if (!states.isCalling && !states.callInitiated) {
                handleCall();
              }
            }}
            className="text-[1rem] w-full cursor-pointer font-[500] blue-gradient p-3 px-6 rounded-lg"
          >
            {states.callInitiated
              ? "Ringing..."
              : states.isCalling
              ? "Calling..."
              : "Call now"}
          </button>
          {states.callInitiated && (
            <div className="text-[.875rem] text-[#2136d4] w-full text-center ">
              Pick up the call coming from {exophone}
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};
