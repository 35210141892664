import React, { useRef, useEffect } from "react";

export default function OtpInputBoxes({ otp, setOtp, isVerificationStarted }) {
  const inputRefs = useRef(Array(6).fill(null)); // Create refs array

  useEffect(() => {
    // setTimeout(() => {
    const firstInput = inputRefs.current[0];
    if (firstInput) {
      firstInput.focus();
    }
    // }, 2000);
  }, []);

  const handleChange = (event, index) => {
    const { value } = event.target;
    // console.log("event triggered", event.key);
    const newValue = value.replace(/[^0-9]/g, "").slice(-1); // Allow only numbers
    const updatedOtp = [...otp];
    updatedOtp[index] = newValue;
    setOtp(updatedOtp);

    // Move focus to the next input if a digit is entered
    if (newValue && index < inputRefs.current.length - 1) {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }

    // console.log(otp);
  };

  const handleBackPress = (event, index) => {
    const { key } = event;

    if (key === "Backspace") {
      const currentValue = otp[index];

      if (!currentValue) {
        // Case 1: If there is no value in the current box, go back to the previous box and clear it
        if (index > 0) {
          const prevInput = inputRefs.current[index - 1];
          if (prevInput) {
            const updatedOtp = [...otp];
            updatedOtp[index - 1] = "";
            setOtp(updatedOtp);
            prevInput.focus();
          }
        }
      } else {
        // Case 2: If there is a value in the current box, clear it and keep focus on the current box
        const updatedOtp = [...otp];
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    // Process the pasted data, assuming it is numeric
    const pastedValues = pastedData.split("").filter((char) => !isNaN(char));
    const updatedOtp = [...otp];

    // Update the OTP values with pasted data
    pastedValues.forEach((value, i) => {
      const currentIndex = index + i;
      if (currentIndex < updatedOtp.length) {
        if (!isNaN(value)) {
          updatedOtp[currentIndex] = value;
        }
      }
    });

    setOtp(updatedOtp);
  };

  const blurEffectStyle = {
    filter: "blur(1.5px)",
    pointerEvents: "none",
  };

  return (
    <div className="flex flex-nowrap justify-between">
      {otp.map((digit, index) => (
        <React.Fragment key={index}>
          <div
            style={isVerificationStarted ? blurEffectStyle : {}}
            className={`relative w-12 h-12 rounded-[10px] overflow-hidden border `}
          >
            <input
              type="text"
              inputMode="numeric"
              value={digit}
              onChange={(event) => handleChange(event, index)}
              onKeyDown={(event) => {
                event.key === "Backspace" && handleBackPress(event, index);
              }}
              onPaste={(event) => handlePaste(event, index)}
              disabled={isVerificationStarted}
              className="w-full h-full bg-gray-100 text-center text-2xl font-bold outline-none focus:outline-none"
              maxLength="2"
              ref={(inputRef) => (inputRefs.current[index] = inputRef)} // Attach refs correctly
              aria-label={`OTP digit ${index + 1}`}
            />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
