import React, { useEffect, useState } from "react";
import { useGlobalStoreContext } from "../../../context/GlobalStoreContext";
import { formatCurrency } from "../../../utils/santaAppUtils";
import { BiSolidBadgeCheck } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import DailyView from "../daily/DailyView";

import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

// each week payout details
export default function EWPayoutDetails({ week, year }) {
  const {
    storeStates: {
      santaEarningsData: { weeklyEarnings },
    },
  } = useGlobalStoreContext();

  const [states, setStates] = useState({
    selectedWeekData: {
      isLoading: true,
      payout: 0,
      payoutDate: null,
      isPayoutDone: false,
      payoutDoneDate: null,
      carryForwardedAmountToNextWeek: 0,
      carryForwardAmountFromLastWeek: 0,
    },
  });

  useEffect(() => {
    const selectedWeekData = weeklyEarnings?.find(
      (curr) => curr.week === parseInt(week) && curr.year === parseInt(year)
    );

    if (selectedWeekData) {
      setStates((p) => ({
        ...p,
        selectedWeekData: {
          ...selectedWeekData,
          isLoading: false,
        },
      }));
    } else {
      window.toast("No data found for this week", "error");
    }

    // eslint-disable-next-line
  }, [weeklyEarnings]);

  if (states.selectedWeekData?.isLoading) {
    return (
      <div className="flex items-center justify-center w-full  ">
        <div className="loader w-[2rem]"></div>
      </div>
    );
  }

  let payoutTransferMessage = states.selectedWeekData?.isPayoutDone
    ? `Paid on ` +
      moment(states.selectedWeekData?.payoutDoneDate).format(
        "ddd, DD MMM - YYYY"
      )
    : `Payout will be on ` +
      moment(states.selectedWeekData?.payoutDate).format("ddd, DD MMM - YYYY");

  let weekDateObj = moment().isoWeek(week).year(year);

  if (states.selectedWeekData?.payout <= 0)
    payoutTransferMessage = `No payout for this week `;

  return (
    <>
      <div className={`${className.container}`}>
        <div className={`w-full text-center font-[500] text-sm`}>
          {getWeekRange(week, year)}
        </div>
        <div className={`${className.dashLine}`} />
        <div className={` w-full flex items-center justify-between py-4 `}>
          <div className="flex flex-col gap-1">
            <div
              className={`text-3xl flex items-end gap-2 text-[#2136d4] font-extrabold mt-2`}
            >
              {formatCurrency(Math.abs(states.selectedWeekData?.payout))}
              {/* <span className="text-sm font-[500] mb-0.5 ">Payout</span> */}
            </div>
            <div className="text-sm text-gray-500">{payoutTransferMessage}</div>
          </div>
          {states.selectedWeekData?.isPayoutDone ? (
            <BiSolidBadgeCheck className="text-green-500 text-[3.5rem] " />
          ) : (
            <GiReceiveMoney className="text-[#2136d4] text-[3rem] relative bottom-4" />
          )}
        </div>
      </div>

      <div className={`${className.container} gap-3`}>
        {states.selectedWeekData?.carryForwardAmountFromLastWeek !== 0 && (
          <div className="border border-orange-200 px-1 py-1 text-[11px] text-center text-orange-700 rounded-lg bg-orange-50 w-full ">
            <span className="font-[500]">
              -
              {formatCurrency(
                Math.abs(
                  states.selectedWeekData?.carryForwardAmountFromLastWeek
                )
              )}{" "}
            </span>
            Floating cash from last week {weekDateObj.isoWeek()} (
            {weekDateObj
              .subtract(1, "weeks")
              .startOf("isoWeek")
              .format(" D MMM - ")}
            {weekDateObj.subtract(1, "weeks").endOf("isoWeek").format("D MMM ")}
            )
          </div>
        )}
        <div className="flex w-full items-center text-sm justify-between">
          <div className="  text-gray-600">Total Earnings</div>
          <Link
            to={`/earnings/week/${year}/${week}`}
            className=" flex items-center gap-1 font-[600]"
          >
            {formatCurrency(states.selectedWeekData?.earnings)}
            <FaAngleRight className="text-[#2136d4]" />
          </Link>
        </div>
        <div className="flex w-full  items-center text-sm justify-between">
          <div className=" flex flex-col text-gray-600">
            <div className="">Cash in hand</div>
          </div>
          <Link
            to={`/deposit/${year}/${week}`}
            className=" flex items-center gap-1 font-[600]"
          >
            -{formatCurrency(states.selectedWeekData?.CIHValue)}
            <FaAngleRight className="text-[#2136d4]" />
          </Link>
        </div>

        {/* presenting the deposit amount if santa has deposits any amount to ebo */}
        {states.selectedWeekData?.depositAmount > 0 && (
          <div className="flex w-full  items-center text-sm justify-between">
            <div className=" flex flex-col text-gray-600">
              <div className="">Deposits</div>
            </div>
            <div className=" flex items-center gap-1 font-[600]">
              {formatCurrency(states.selectedWeekData?.depositAmount)}
            </div>
          </div>
        )}

        {/* carryForwardedAmountToNextWeek  */}
        {states.selectedWeekData?.carryForwardedAmountToNextWeek > 0 && (
          <div className="flex w-full  items-center text-xs bg-orange-50 p-2 rounded-md text-orange-700 justify-between">
            <div className=" flex flex-col ">
              <div className=" ">Floating cash carried to next week</div>
              <div className=" text-[10px] leading-3">
                Week {weekDateObj.isoWeek()} (
                {weekDateObj
                  .add(1, "weeks")
                  .startOf("isoWeek")
                  .format(" D MMM - ")}
                {weekDateObj.add(1, "weeks").endOf("isoWeek").format("D MMM ")})
              </div>
            </div>
            <div className=" flex text-sm items-center gap-1 font-[600]">
              {formatCurrency(
                states.selectedWeekData?.carryForwardedAmountToNextWeek
              )}
            </div>
          </div>
        )}

        <div className={`${className.dashLine}`} />
        <div className="flex items-center w-full text-sm justify-between">
          <div className="  text-gray-600">
            {states?.selectedWeekData?.totalWalletAmount < 0
              ? "Floating cash"
              : states?.selectedWeekData?.isPayoutDone
              ? "Paid amount"
              : "Payout amount"}
          </div>
          <div className="  font-[600]  mr-1">
            {states?.selectedWeekData?.totalWalletAmount < 0 && "-"}{" "}
            {formatCurrency(
              Math.abs(states?.selectedWeekData?.totalWalletAmount || 0)
            )}
          </div>
        </div>

        {states.selectedWeekData?.carryForwardedAmountToNextWeek !== 0 && (
          <div className="border border-orange-200 p-2 py-1 text-xs text-center text-orange-700 rounded-lg bg-orange-50 w-full ">
            {formatCurrency(
              Math.abs(states.selectedWeekData?.carryForwardedAmountToNextWeek)
            )}{" "}
            floating cash carry forward to next week
          </div>
        )}
      </div>
      <div className={`${className.container} !p-0`}>
        <div className="flex border-b w-full">
          <div className="flex-1">
            <p className={`text-[#2136D4] py-3 text-center`}>Earnings</p>
            <p className="h-1 bg-[#2136D4] rounded-t-[5px]"></p>
          </div>
        </div>

        <DailyView {...{ week, year }} />
      </div>
    </>
  );
}

const getWeekRange = (week, year) => {
  const weekDateObj = moment().isoWeek(week).year(year);

  const weekStartDate = weekDateObj.startOf("isoWeek").format("DD MMM");
  const weekEndDate = weekDateObj.endOf("isoWeek").format("DD MMM");

  if (weekDateObj.isSame(moment(), "isoWeeks")) {
    return (
      <div className=" w-full justify-center flex items-center gap-2">
        <span>This week</span>
        <span className="text-[#2136d4] px-2 py-0.5 bg-blue-100 rounded text-[10px]">
          {weekStartDate} - {weekEndDate} , Week {weekDateObj.isoWeek()}
        </span>
      </div>
    );
  }

  if (weekDateObj.isSame(moment().subtract(1, "weeks"), "isoWeeks")) {
    return (
      <div className=" w-full justify-center flex items-center gap-2">
        <span>Last week</span>
        <span className="text-[#2136d4] px-2 py-0.5 bg-blue-100 rounded text-[10px]">
          {weekStartDate} - {weekEndDate} , Week {weekDateObj.isoWeek()}
        </span>
      </div>
    );
  }

  return (
    <div className=" w-full justify-center flex items-center gap-2">
      <span>Week {weekDateObj.isoWeek()}</span>
      <span className="text-[#2136d4] px-2 py-0.5 bg-blue-100 rounded text-[10px]">
        {weekStartDate} - {weekEndDate}
      </span>
    </div>
  );
};

const className = {
  container:
    "border w-full border-gray-200 bg-white rounded-lg flex flex-col  items-center p-4",
  dashLine: "border-t border-gray-300 w-full my-2 h-0 border-dashed",
};
