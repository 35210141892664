import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross1 } from "react-icons/rx";
import { FiCamera } from "react-icons/fi";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
import { useCompRendering } from "../../context/CompRenderingContext";

const isDirectWithInput = process.env.REACT_APP_IMAGE_PICK_FROM === "DIRECT";

export default function AskImagePickFrom({ parentStates, setParentStates }) {
  const handleClose = async () => {
    setParentStates((p) => ({
      ...p,
      AskImagePickFrom: {
        isRender: false,
      },
    }));
  };

  return (
    <AnimatePresence>
      {parentStates.AskImagePickFrom.isRender && (
        <Modal
          handleClose={handleClose}
          setParentStates={setParentStates}
          properties={parentStates.AskImagePickFrom}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose, properties, setParentStates }) => {
  const { setStates: setCompRendringStates } = useCompRendering();
  const [isChoice, setIsChoice] = useState(false);

  const inputRef = useRef(null);

  const onCameraOptionClick = () => {
    if (isDirectWithInput) {
      inputRef?.current?.click();

      return;
    }
    setCompRendringStates((p) => ({
      ...p,
      AskImagePickFrom: {
        ...p.AskImagePickFrom,
        onImagePicked: onImagePicked,
      },
    }));

    window.handleVibrate();

    window.postMessageRW({
      action: "imagePicker",
      pickFrom: "camera",
      allowsEditing: properties.allowsEditing,
      isFrontCamera: properties.cameraType === "front",
    });
  };

  const onMediaOptionClick = () => {
    if (isDirectWithInput) {
      inputRef?.current?.click();

      return;
    }
    setCompRendringStates((p) => ({
      ...p,
      AskImagePickFrom: {
        ...p.AskImagePickFrom,
        onImagePicked: onImagePicked,
      },
    }));

    window.handleVibrate();

    window.postMessageRW({
      action: "imagePicker",
      allowsEditing: properties.allowsEditing,
      pickFrom: "gallery",
    });
  };

  const onImagePicked = (data) => {
    if (data.isCancelled) {
      if (properties.pickFrom !== "choice") handleClose();
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      properties?.onImagePickEnd(
        data.file,
        data.base64Url,
        event.target.result
      );
      handleClose();
    };
    reader.readAsDataURL(data.file);
  };

  const handleSelectImage = async (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = (event) => {};
      onImagePicked({ file: imageFile });
      reader.readAsDataURL(imageFile);
    }
  };

  useEffect(() => {
    //  default on camera or gallery option, if properties.pickFrom !== 'choice
    if (properties.pickFrom === "camera" && !isDirectWithInput) {
      onCameraOptionClick();
    }

    if (properties.pickFrom === "gallery" && !isDirectWithInput) {
      onMediaOptionClick();
    }

    if (properties.pickFrom === "choice" || isDirectWithInput) {
      setIsChoice(true);
    }

    // eslint-disable-next-line
  }, []);

  // if (!isChoice) return null;

  return (
    <div
      className={`z-[98] fix-screen ${!isChoice ? "hidden" : "flex-center"} `}
    >
      <div
        onClick={handleClose}
        className="fix-screen z-[-1] bg-[#0000003e]"
      ></div>
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: "0%", opacity: 1 }}
        exit={{ y: "100%", opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="flex items-center relative justify-center w-[18rem] flex-col bg-[white] rounded-lg   "
      >
        <div
          onClick={handleClose}
          className=" absolute -top-10  right-1  bg-white rounded-full p-2 aspect-square  "
        >
          <RxCross1 />
        </div>
        <div className="text-lg font-[500] pt-4 ">
          {properties.heading || "Select Image"}
        </div>
        <div className="flex items-center w-full justify-evenly py-6 font-medium ">
          <div
            onClick={onCameraOptionClick}
            className="bg-blue-50 rounded-lg p-3 flex-col gap-1 items-center"
          >
            <FiCamera className="text-[#2136d4] text-2xl" />
            <div className="text-gray-500 text-sm">Camera</div>
          </div>
          <div
            onClick={onMediaOptionClick}
            className="bg-blue-50 rounded-lg p-3 flex-col gap-1 items-center"
          >
            <MdOutlinePhotoSizeSelectActual className="text-[#2136d4] text-2xl" />
            <div className="text-gray-500  text-sm">Gallery</div>
          </div>
        </div>
        <input
          type="file"
          accept="image/*"
          id="santaProfileImageInput"
          className="hidden"
          ref={inputRef}
          onChange={handleSelectImage}
        />
      </motion.div>
    </div>
  );
};
