import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import OrderPage from "../pages/orders/OrderPage";
import UserDelete from "../pages/delete/UserDelete";
import AuthPageIndex from "../components/onBoarding/authComponents/AuthPageIndex";
import CompletedOrderspage from "../pages/orders/CompletedOrderspage";
import EWPayoutDetailsPage from "../pages/earnings/EWPayoutDetailsPage";
import ReturnableOrderPage from "../pages/orders/ReturnableOrderPage";
import OrderRequestDetailsPage from "../pages/orders/OrderRequestDetailsPage";
import CompletedOrder from "../components/orders/orderDetails/completedOrder/CompletedOrder";

// --------------- pages imports --------------------------------
const PayoutListPage = lazy(() => import("../pages/earnings/PayoutListPage"));
const HomePage = lazy(() => import("../pages/home/HomePage"));
const OnBoradingHomePage = lazy(() =>
  import("../pages/onBoarding/OnboardingHomePage")
);
const OnboardingEachPage = lazy(() =>
  import("../pages/onBoarding/OnboardingEachPage")
);
const EarningsPage = lazy(() => import("../pages/earnings/EarningsPage"));
const WeeklyEarningsPage = lazy(() =>
  import("../pages/earnings/WeeklyEarningsPage")
);
const DailyEarningsPage = lazy(() =>
  import("../pages/earnings/DailyEarningsPage")
);
const DepositPage = lazy(() =>
  import("../pages/transaction/deposit/DepositPage")
);
const MyAccountPage = lazy(() => import("../pages/myAccount/MyAccountPage"));
const SantaProfilePage = lazy(() =>
  import("../pages/myAccount/SantaProfilePage")
);
const OrdersPage = lazy(() => import("../pages/orders/OrdersPage"));
const MyTeamPage = lazy(() => import("../pages/myTeam/MyTeamPage"));
const MemberProfilePage = lazy(() =>
  import("../pages/myTeam/MemberProfilePage")
);

const EachRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/"
          element={
            <Suspense fallback={<Spinner />}>
              <HomePage />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="/login"
        element={
          <Suspense fallback={<Spinner />}>
            <AuthPageIndex />
          </Suspense>
        }
      />

      <Route
        path="/delete-my-account"
        element={
          <Suspense fallback={<Spinner />}>
            <UserDelete />
          </Suspense>
        }
      />

      <Route
        path="/onboarding"
        //  element={<Outlet />}
        element={<PrivateRoute />}
      >
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <OnBoradingHomePage />
            </Suspense>
          }
        />
        <Route
          path="pages"
          element={
            <Suspense fallback={<Spinner />}>
              <OnboardingEachPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/orders" element={<PrivateRoute />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <OrdersPage />
            </Suspense>
          }
        />
        <Route
          path="completed"
          element={
            <Suspense fallback={<Spinner />}>
              <CompletedOrderspage />
            </Suspense>
          }
        />
        <Route
          path="order/:orderId"
          element={
            <Suspense fallback={<Spinner />}>
              <OrderPage />
            </Suspense>
          }
        />{" "}
        <Route
          path="completed/:orderId"
          element={
            <Suspense fallback={<Spinner />}>
              <CompletedOrder />
            </Suspense>
          }
        />
        <Route
          path="returnableOrder/:orderId"
          element={
            <Suspense fallback={<Spinner />}>
              <ReturnableOrderPage />
            </Suspense>
          }
        />
        <Route
          path="orderRequest/:orderId"
          element={
            <Suspense fallback={<Spinner />}>
              <OrderRequestDetailsPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/earnings" element={<PrivateRoute />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <EarningsPage />
            </Suspense>
          }
        />
        <Route
          path="week/:year/:week"
          element={
            <Suspense fallback={<Spinner />}>
              <WeeklyEarningsPage />
            </Suspense>
          }
        />
        <Route
          path="week/:year/:week/:date"
          element={
            <Suspense fallback={<Spinner />}>
              <DailyEarningsPage />
            </Suspense>
          }
        />

        <Route
          path="payout"
          element={
            <Suspense fallback={<Spinner />}>
              <PayoutListPage />
            </Suspense>
          }
        />
        <Route
          path="payout/:year/:week"
          element={
            <Suspense fallback={<Spinner />}>
              <EWPayoutDetailsPage />
            </Suspense>
          }
        />
      </Route>

      <Route path="/deposit/:year/:week" element={<PrivateRoute />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <DepositPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/my-account" element={<PrivateRoute />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <MyAccountPage />
            </Suspense>
          }
        />
        <Route
          path="santa-profile"
          element={
            <Suspense fallback={<Spinner />}>
              <SantaProfilePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/my-team" element={<PrivateRoute />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <MyTeamPage />
            </Suspense>
          }
        />
        <Route
          path="member/:memberId"
          element={
            <Suspense fallback={<Spinner />}>
              <MemberProfilePage />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

const Spinner = () => {
  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center">
      <div className="loader w-[2rem] "></div>
    </div>
  );
};

export default EachRoute;
