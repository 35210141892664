import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { motion, useAnimation } from "framer-motion";
import { FaAnglesRight, FaCheck } from "react-icons/fa6";
import GlobalStoreContext from "../../context/GlobalStoreContext";

const SlideToConfirmButton = ({
  btnText,
  funOnFullSlide,
  disabled = false,
  height = 60,
  iconWidth = 60,
  disableDrag = false,
  onFullSlideTextBeforeRelease,
  onFullSlideTextAfterRelease,
  onConfirmedText,
  whyDisabledInfoText = "",
}) => {
  const parentRef = useRef(null);
  const iconControls = useAnimation();
  const { handleVibrate } = useContext(GlobalStoreContext);
  const [states, setStates] = useState({
    isFullSlide: false,
    slideFunStarted: false,
    confirmed: false,
    buttonContainerWidth: 0,
  });
  const isMounted = useRef(false);

  const handleSubmit = useCallback(async () => {
    funOnFullSlide && (await funOnFullSlide());

    setStates((p) => ({
      ...p,
      isFullSlide: false,
      slideFunStarted: false,
      confirmed: false,
    }));
    const animation = { x: 0 };
    const transition = { duration: 0.2 };
    // checking if the componet has been unmounted
    isMounted.current && iconControls?.start(animation, transition);
  }, [funOnFullSlide, iconControls]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (parentRef.current) {
      const buttonContainerWidth = parentRef.current.clientWidth || 0;
      setStates((p) => ({ ...p, buttonContainerWidth }));
    }
  }, [parentRef]);

  const handleSlide = useCallback(
    (event, info) => {
      const maxIconPosition = states.buttonContainerWidth - iconWidth;

      if (info.point.x > maxIconPosition) {
        setStates((p) => ({ ...p, isFullSlide: true }));
      } else {
        setStates((p) => ({ ...p, isFullSlide: false }));
      }
    },
    [states.buttonContainerWidth, iconWidth]
  );

  const handleDragEnd = useCallback(
    (event, info) => {
      const maxIconPosition = states.buttonContainerWidth - iconWidth;
      const targetPosition =
        info.point.x > maxIconPosition ? maxIconPosition - 10 : 0;

      if (targetPosition > 0) {
        setStates((p) => ({ ...p, slideFunStarted: true }));
        handleVibrate();
        handleSubmit();
      }
      const animation = { x: targetPosition };
      const transition = { duration: 0.4 };
      iconControls.start(animation, transition);
    },

    [
      states.buttonContainerWidth,
      iconWidth,
      handleSubmit,
      iconControls,
      handleVibrate,
    ]
  );

  return (
    <div
      className="w-full"
      style={{
        height,
      }}
      onClick={() => {
        if (disabled && whyDisabledInfoText) {
          window.toast(whyDisabledInfoText);
        }
      }}
      ref={parentRef}
    >
      <motion.div
        style={{
          height,
          // pointerEvents: disabled ? "none" : "all",
          opacity: disabled ? 0.7 : 1,
        }}
        className={`flex items-center w-full h-full ${
          !disabled && "before:w-[200px] mirror-Animation "
        } blue-gradient rounded-full  shadow-md cursor-pointer  relative overflow-hidden`}
      >
        <motion.div
          className={`bg-gradient absolute overflow-hidden p-1 inset-0  `}
        >
          <motion.div
            drag={
              disabled || states.slideFunStarted || disableDrag ? false : "x"
            }
            onDrag={handleSlide}
            onDragEnd={handleDragEnd}
            // dragMomentum={false}
            className={`flex items-center relative bg-white text-[#2136d4] border justify-end h-full   rounded-full z-10`}
            animate={iconControls}
            initial={{ x: 0 }}
            style={{
              width: iconWidth,
              paddingRight: height / 3,
            }}
          >
            {states.isFullSlide ? <FaCheck /> : <FaAnglesRight />}
          </motion.div>
        </motion.div>
        <span className="flex-grow text-center font-semibold text-[1.125rem]">
          {states.confirmed ? (
            onConfirmedText || "Confirmed"
          ) : states.isFullSlide ? (
            <>
              {!states.slideFunStarted
                ? onFullSlideTextAfterRelease || "Release to confirm"
                : onFullSlideTextBeforeRelease || (
                    <div
                      className="loader w-[1.25rem] "
                      style={{
                        borderTopColor: "white",
                      }}
                    ></div>
                  )}
            </>
          ) : (
            btnText
          )}
        </span>
      </motion.div>
    </div>
  );
};

export default SlideToConfirmButton;
