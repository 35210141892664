import React from "react";
import "./index.css";
import "./App.css";
import { GlobalStoreProvider } from "./context/GlobalStoreContext";
import ErrorBoundary from "./assets/ErrorBoundary";
import ErrorPage from "./assets/ErrorPage";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { OnboardingProvider } from "./context/OnboardingContext";
import { CompRenderingProvider } from "./context/CompRenderingContext";
import MainRouter from "./routes/MainRouter";

function App() {
  return (
    <Router>
      <CompRenderingProvider>
        <GlobalStoreProvider>
          <AuthProvider>
            <OnboardingProvider>
              <ErrorBoundary fallback={<ErrorPage />}>
                <MainRouter />
              </ErrorBoundary>
            </OnboardingProvider>
          </AuthProvider>
        </GlobalStoreProvider>
      </CompRenderingProvider>
    </Router>
  );
}

export default App;
