// Function to convert base64 to a File object
export function base64ToFile(base64String, filename, mimeType) {
  // Split the base64 string into the header and the actual data
  const byteString = atob(base64String.split(",")[1]); // atob decodes base64 to binary
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  // Populate the arrayBuffer with the byte data
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  // Create a File object from the arrayBuffer
  return new File([uint8Array], filename, { type: mimeType });
}
