import { AnimatePresence, motion } from "framer-motion";
import { useAuthContext } from "../context/AuthContext";
import useFetchInitialData from "../hooks/useFetchInitialData";
import { update_santa_api } from "../api/santaApi";
import { RxCross2 } from "react-icons/rx";
import { useState } from "react";

const AddEditBankDetailsModal = ({ states, setStates }) => {
  return (
    <AnimatePresence>
      {states.addBankDetailsModal && (
        <BankSelection
          handleClose={() => {
            setStates((p) => ({ ...p, addBankDetailsModal: false }));
          }}
        />
      )}
    </AnimatePresence>
  );
};

export default AddEditBankDetailsModal;

function BankSelection({ handleClose }) {
  const {
    initialState: { santaId, santa, token },
  } = useAuthContext();

  const { callMethods } = useFetchInitialData();

  const [states, setStates] = useState({
    bankDetails: {
      accountNumber: santa.bankDetails?.accountNumber || "",
      bankName: santa.bankDetails?.bankName || "",
      IFSC: santa.bankDetails?.IFSC || "",
      VPA: santa.bankDetails?.VPA || "",
    },
    isSaving: false,
  });

  const canProceed =
    (states.bankDetails.accountNumber &&
      states.bankDetails.bankName &&
      states.bankDetails.IFSC) ||
    states.bankDetails.VPA;

  const handleProceed = async () => {
    if (!canProceed) {
      return;
    }
    setStates((p) => ({ ...p, isSaving: true }));

    const updateObject = {
      "bankDetails.accountNumber": states.bankDetails.accountNumber,
      "bankDetails.bankName": states.bankDetails.bankName,
      "bankDetails.IFSC": states.bankDetails.IFSC,
      "bankDetails.VPA": states.bankDetails.VPA,
      "bankDetails.status": "inReview",
    };

    const options = {
      queryObject: { _id: santaId },
      updateObject,
      projectionString:
        "personalDetails address workArea workDetails documentsDetails profileDraftDetails",
      tags: ["crud", "update"],
      token: token,
    };

    const res = await update_santa_api({ options });

    if (res.isSuccess) {
      await callMethods({ refetchData: true });
      handleClose();
      setStates((p) => ({ ...p, isSaving: false }));
      window.toast("Bank details saved successfully");
    }
  };

  return (
    <motion.div
      initial={{
        y: "100%",
      }}
      animate={{
        y: 0,
      }}
      exit={{
        y: "100%",
      }}
      transition={{
        duration: 0.3,
        ease: "linear",
      }}
      className=" flex z-[20] flex-col px-4 bg-white fixed top-0 p-4 right-0 left-0 bottom-0 overflow-hidden items-start "
    >
      <div className="w-full flex items-end justify-end">
        <RxCross2 onClick={handleClose} className="text-2xl" />
      </div>
      <div className="text-[1.25rem] mt-4 font-[600] w-full text-center">
        Provide your bank details and UPI ID
      </div>
      <div className="text-[.875rem] font-[500] mb-[1.5rem] text-[gray] w-full text-center">
        Your earnings will be transferred to the provided bank account details
        or UPI ID every week
      </div>

      <div className="flex flex-col w-full ">
        <div className=" text-[.75rem] text-[gray] mb-1">Account number</div>
        <input
          className="input2 w-full p-2 "
          type="number"
          value={states.bankDetails.accountNumber}
          onChange={(e) => {
            setStates((p) => ({
              ...p,
              bankDetails: {
                ...p.bankDetails,
                accountNumber: e.target.value,
              },
            }));
          }}
          placeholder="Account number"
        />
        <div className=" text-[.75rem] text-[gray] mb-1 mt-2">Bank name</div>
        <input
          className="input2 w-full p-2 "
          value={states.bankDetails.bankName}
          onChange={(e) => {
            setStates((p) => ({
              ...p,
              bankDetails: {
                ...p.bankDetails,
                bankName: e.target.value,
              },
            }));
          }}
          placeholder="Bank name"
        />
        <div className=" text-[.75rem] text-[gray] mb-1 mt-2">IFSC code</div>
        <input
          className="input2 w-full p-2 "
          value={states.bankDetails.IFSC}
          onChange={(e) => {
            setStates((p) => ({
              ...p,
              bankDetails: {
                ...p.bankDetails,
                IFSC: e.target.value,
              },
            }));
          }}
          placeholder="IFSC code"
        />
        <div className=" border text-[.75rem] text-[gray] leading-4 mt-2 border-gray-200 bg-gray-50 rounded-lg p-2 ">
          IFSC code Ex: HDFCOOOO1234. You can find it on your passbook or
          chequebook
        </div>

        <div className=" text-[.75rem] text-[gray] mb-1 mt-4 ">
          UPI ID (optional)
        </div>
        <input
          className="input2 w-full p-2 "
          value={states.bankDetails.VPA}
          onChange={(e) => {
            setStates((p) => ({
              ...p,
              bankDetails: {
                ...p.bankDetails,
                VPA: e.target.value,
              },
            }));
          }}
          placeholder="Enert your UPI ID "
        />

        <div className="flex w-full mt-4 mb-2 scroll-bar-remove whitespace-nowrap overflow-scroll gap-4 text-[.75rem] flex-nowrap ">
          {allVPASuffix.map((curr) => {
            return (
              <div
                onClick={() => {
                  setStates((p) => ({
                    ...p,
                    bankDetails: {
                      ...p.bankDetails,
                      VPA: (p.bankDetails.VPA?.split("@")[0] || "") + curr,
                    },
                  }));
                }}
                className="border border-gray-200 bg-gray-50 rounded-lg px-2 py-1 cursor-pointer"
                key={curr}
              >
                {curr}
              </div>
            );
          })}
        </div>
        <div className=" border text-[.75rem] text-[gray] leading-4 mt-2 border-gray-200 bg-gray-50 rounded-lg p-2 ">
          UPI ID Ex: abcd@bank. You can find it in your UPI apps
        </div>
      </div>
      <div
        className={`fixed bottom-0 left-0 right-0 px-4  flex justify-between pb-4  bg-white 
         `}
      >
        <div
          className={`w-full bg-white ${
            !canProceed && " pointer-events-none opacity-45"
          } `}
          onClick={() => {
            !states.isSaving && handleProceed();
          }}
        >
          <Button
            text={
              states.isSaving ? (
                <div
                  className="loader w-[1rem]"
                  style={{ borderTopColor: "white" }}
                ></div>
              ) : (
                "Save"
              )
            }
            disabled={!canProceed}
          />
        </div>
      </div>
    </motion.div>
  );
}

const Button = ({ text, type, id, isLoading, disabled }) => {
  let disableStyle =
    disabled === true
      ? " opacity-70 cursor-not-allowed"
      : "mirror-Animation opacity-100 cursor-pointer";

  return (
    <button
      type={type}
      className={`${disableStyle} blue-gradient before:w-[200px] text-white w-full py-3 rounded-md active:scale-[0.99] transition-all uppercase font-medium md:font-semibold  "cursor-pointer"
         `}
      id={id ? id : ""}
      // disabled={disabled}
    >
      {/* {text} */}
      {isLoading ? (
        <div
          style={{ borderTopColor: "white" }}
          className="loader w-[1rem]"
        ></div>
      ) : (
        text
      )}
    </button>
  );
};

const allVPASuffix = [
  "@ybl", // Paytm
  "@yapl",
  "@apl",
  "@oksbi",
  "@okaxis", // Axis Bank
  "@hdfcbank", // HDFC Bank
  "@icici", // ICICI Bank
  "@upi", // UPI
  "@sbi", // State Bank of India
  "@kotak", // Kotak Mahindra Bank
  "@idfc", // IDFC Bank
  "@rbl", // RBL Bank
  "@federal", // Federal Bank
  "@yesbank", // Yes Bank
  "@idbibank", // IDBI Bank
  "@indus", // IndusInd Bank
  "@citi", // Citibank
  "@pnb", // Punjab National Bank
  "@unionbank", // Union Bank of India
  "@canarabank", // Canara Bank
  "@allahabadbank", // Allahabad Bank
  "@andb", // Andhra Bank
  "@axisbank", // Axis Bank
  "@bankofbaroda", // Bank of Baroda
  "@bhim", // BHIM UPI
  "@bob", // Bank of Baroda
  "@centralbank", // Central Bank of India
  "@dbs", // DBS Bank
  "@dena", // Dena Bank
  "@equitas", // Equitas Small Finance Bank
  "@hsbc", // HSBC Bank
  "@iob", // Indian Overseas Bank
  "@karnatakabank", // Karnataka Bank
  "@karurvysya", // Karur Vysya Bank
  "@lvb", // Lakshmi Vilas Bank
  "@saraswat", // Saraswat Bank
  "@sib", // South Indian Bank
  "@syndicatebank", // Syndicate Bank
  "@tmb", // Tamilnad Mercantile Bank
  "@uco", // UCO Bank
  "@vijayabank", // Vijaya Bank
];
