import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { BsEmojiLaughingFill } from "react-icons/bs";
import moment from "moment-timezone";
// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function OrderAcceptedPage({ order }) {
  return (
    <Link
      to={`/orders/order/${order._id}`}
      className="flex flex-col fixed top-0 right-0 left-0 bottom-0 items-center justify-center text-white z-[5] bg-green-600"
    >
      <RxCross2
        style={{
          animationDelay: "1.5s",
        }}
        className="top-4 animate__animated animate__fadeIn absolute text-white  right-4 text-3xl "
      />
      <FaCheckCircle
        style={{
          animationDelay: "0s",
        }}
        className="text-[8rem] mb-4 animate__animated animate__backInUp  text-white  delay-1000"
      />

      <div
        style={{
          animationDelay: "0.2s",
        }}
        className="animate__animated animate__backInUp text-xl text-white  font-semibold flex items-center gap-2"
      >
        You got the order! <BsEmojiLaughingFill className="text-base" />
      </div>
      <div
        style={{
          animationDelay: "1.2s",
        }}
        className="animate__animated animate__fadeIn text-sm mt-1  text-white  font-regular"
      >
        Work great to get more orders
      </div>
    </Link>
  );
}
