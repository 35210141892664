import { useEffect, useState } from "react";
import CommonHeader from "../../../../../assets/CommonHeader";
import OtpInputBoxes from "../../../../onBoarding/authComponents/subComponents/OtpInputBoxes";
import Error from "../../../../../assets/Error";
import { motion } from "framer-motion";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { resendOtpForOrderApi } from "../../../../../api/orderApi";
import SlideToConfirmButton from "../../../../../assets/buttons/SlideToConfirmButton";

export default function OrderOtp({
  setStates,
  setParentStates,
  states,
  order,
  orderId,
  orderOtp,
  handleSubmit,
}) {
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(30);
  const [otp, setOtp] = useState(Array(4).fill(""));

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer((prev) => prev - 1), 1000);
    }
    // eslint-disable-next-line
  }, [timer]);

  useEffect(() => {
    const filledDigits = otp.filter((digit) => digit !== ""); // Filter out empty digits
    // console.log(filledDigits)
    if (filledDigits.length === otp.length) {
      // All OTP digits are filled
      verifyOTP();
    }
    // eslint-disable-next-line
  }, [otp]);

  const verifyOTP = async (e) => {
    e && e.preventDefault();

    setStates((p) => ({ ...p, isLoading: true, isVerificationStarted: true }));
    const OTP = otp.join("");
    try {
      const matched = parseInt(orderOtp) === parseInt(OTP);
      // const res = await verifyOtpForOrderApi({ otp: OTP, orderId: orderId });
      if (!matched) {
        setError("wrong Code");
        setStates((p) => ({
          ...p,
          isLoading: false,
          isVerificationStarted: false,
        }));
      } else {
        handleSubmit(null, "otpVerified");
        setStates((p) => ({
          ...p,
          isLoading: false,
          isVerificationStarted: false,
          isOtpVerified: matched,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendOtpToUser = async () => {
    setOtp(Array(4).fill(""));
    window.toast("Otp sent to xxxxxx" + number.toString().slice(6, 10));
    await resendOtpForOrderApi({ orderId: orderId });
    setTimer(30);
  };

  const resendOTP = () => {
    if (timer === 0) {
      sendOtpToUser();
    }
  };

  // if (states.isOtpVerified) return <TakePhoto />;
  const number =
    order?.addressId?.receiversDetails?.mobileNumber ||
    order.userId.phoneNumber;

  return (
    <motion.div
      initial={{ opacity: 1, x: " 100%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.3 }}
      className="fixed top-0 bottom-0 bg-white left-0 right-0  z-[16]"
    >
      <CommonHeader
        navFun={() => {
          // setParentStates((p) => ({ ...p, isCurrStatusPageRender: false }));
          setStates((p) => ({ ...p, isOtpVerifyPage: false }));
        }}
        content="Verification"
      />
      <div className="mx-4">
        <div className="mt-6">
          <p className="text-[1.25rem] font-medium">Enter OTP</p>
          <p className="text-[gray] text-[.8125rem] flex items-center gap-1">
            Ask customer for the order OTP{" "}
            <AiOutlineInfoCircle
              onClick={() => {
                window.showMsg(
                  "Order OTP can be accessed from the customer's order details page, located in the 'My Bookings' section. or Send the OTP again to xxxxxx" +
                    number.toString().slice(6, 10)
                );
              }}
              className="text-[#2136d4] text-[.8125rem]"
            />
          </p>
        </div>
        <div className="flex relative text-[.825rem] mt-8 text-[#666666] gap-[1rem] font-medium"></div>
        <div className="my-6 mx-8">
          <OtpInputBoxes
            otp={otp}
            setOtp={setOtp}
            isVerificationStarted={states.isVerificationStarted}
          />
        </div>
        <div className="flex gap-1 justify-center items-center  text-xs font-medium md:font-semibold">
          <span
            className={`my-3 ${
              timer > 0
                ? "pointer-events-none text-[#979797]"
                : "cursor-pointer  text-[#2136D4] "
            }`}
            onClick={resendOTP}
          >
            {timer > 0 ? "Resend OTP in" : "Resend OTP"}
          </span>
          {timer > 0 && (
            <span className="text-[#2136d4]">
              00:{timer < 10 ? "0" + timer : timer}
            </span>
          )}
        </div>
        <Error setError={setError} error={error} />
      </div>

      <div className="fixed bottom-0 flex flex-col justify-center items-center left-0 right-0 m-2 mx-4">
        <SlideToConfirmButton
          btnText={"Verify"}
          disabled={otp.join("").length < 4}
          funOnFullSlide={verifyOTP}
          onFullSlideTextBeforeRelease={"Release to verify"}
        />

        <div
          onClick={() => {
            window.askRadio(
              "Why you want to skip OTP verification?",
              "",
              [
                "Customer is not present at the location",
                "Customer don't have the OTP",
              ],
              "Done",
              (r, i) => {
                handleSubmit(null, "otpVerified", r);
              },
              true
            );
          }}
          className="text-[grey] text-[1rem] mt-4 pb-2 font-[500] "
        >
          Skip
        </div>
      </div>
    </motion.div>
  );
}
