import { memo } from "react";

const Header = () => {
  const LoginPageHead = () => {
    return (
      <div className="flex flex-col items-center text-white ">
        <p className=" text-6xl font-bold ">
          <span
            style={{
              fontFamily: "'Josefin Sans', sans-serif",
            }}
            className=" text-6xl font-bold mr-2"
          >
            ebo
          </span>
          santa
        </p>

        <p
          // style={{ fontFamily: " 'Carter One', system-ui" }}
          className="text-2xl font-medium"
        >
          khushiyon ki zimmedari
        </p>
      </div>
    );
  };
  return (
    <div className=" flex justify-center items-center blue-gradient  h-[35vh]  sticky top-0 py-4 rounded-b-[30px] w-[100%]">
      <LoginPageHead />
    </div>
  );
};

export default memo(Header);
