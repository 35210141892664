import { BiChevronLeft } from "react-icons/bi";
import { IoHelp, IoNotifications } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";

export default function CommonHeader({
  navFun,
  content,
  isHelp,
  isNotification,
}) {
  const navigate = useNavigate();
  const {
    initialState: { santa },
  } = useContext(AuthContext);
  return (
    <div
      onClick={() => {
        navFun ? navFun() : navigate(-1);
      }}
      className="sticky top-0 border-b py-3 px-2 flex items-center justify-between bg-white z-10 "
    >
      <div className="flex items-center text-[1rem]">
        <BiChevronLeft className="text-[1.5rem]" />
        {content ? content : <p>Back</p>}
      </div>
      <div className="flex  gap-4">
        {isHelp && (
          <div
            onClick={() => {
              window.openUrl(
                `https://wa.me/9046727027?text=Hi, I'm ${santa.personalDetails?.name} an ebo santa. I'm facing an issues`
              );
            }}
            className=" h-fit w-fit text-white bg-black bg-opacity-30 rounded-full p-[6px]"
          >
            <IoHelp className="text-[1rem]" />
          </div>
        )}
        {isNotification && (
          <div
            onClick={() => {
              navigate("/orders");
            }}
            className=" h-fit w-fit text-white bg-black bg-opacity-30 rounded-full p-[6px]"
          >
            <IoNotifications className="text-[1rem]" />
          </div>
        )}
      </div>
    </div>
  );
}
