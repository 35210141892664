import React, { useContext } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { IoHelp, IoNotifications } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { SANTA_HELP_NUMBER } from "../../utils/constants";

export default function Header2({ title, navFun, order }) {
  const navigate = useNavigate();
  const {
    initialState: { santa },
  } = useContext(AuthContext);

  return (
    <div className="flex justify-between z-[15] w-full fixed items-center top-0 right-0 left-0 bg-white mb-4 p-2">
      <div
        onClick={() => {
          navFun ? navFun() : navigate(-1);
        }}
        className="flex items-center text-[1rem]"
      >
        <BiChevronLeft className="text-[1.5rem]" />
        <div className="flex flex-col ">
          <p className="font-[500] text-[1.125rem] ">
            {title ? title : "Back"}
          </p>
        </div>
      </div>
      <div className="flex gap-2 mt-1 pr-1">
        <div
          onClick={() => {
            if (order?.bookingStatus === "Out for Service") {
              // call to SANTA_HELP_NUMBER
              window.openUrl(`tel:${SANTA_HELP_NUMBER}`);
            } else {
              window.openUrl(
                `https://wa.me/9046727027?text=Hi, I'm ${santa.personalDetails?.name} an ebo santa. I'm facing an issues`
              );
            }
          }}
          className="h-fit w-fit flex blue-gradient items-center text-white px-4 pr-3 text-[.875rem] rounded-full p-[6px]"
        >
          Help
          <IoHelp className="text-[1rem]" />
        </div>
        <div
          onClick={() => {
            navigate("/orders");
          }}
          className=" h-fit w-fit text-white blue-gradient rounded-full p-[6px]"
        >
          <IoNotifications className="text-[1.25rem]" />
        </div>
      </div>
    </div>
  );
}
