import React, { useState } from "react";
import { TbCategoryPlus } from "react-icons/tb";

export default function DecorPreview({ order }) {
  const product = order.productId;
  const [decorPreviewStates, setDecorPreviewStates] = useState({
    renderingImgUrl: product.images[0],
  });
  return (
    <div className="flex flex-col w-full px-4 ">
      <div className="flex items-start w-full border border-gray-200 bg-[white] aspect-square  rounded-lg overflow-hidden ">
        <img
          onClick={() => {
            window.showMsg(decorPreviewStates.renderingImgUrl, "img", null, {
              zoom: true,
            });
          }}
          className="rounded-lg"
          alt=""
          src={decorPreviewStates.renderingImgUrl}
        />
      </div>

      <div className="font-[600] mb-2 text-[1rem] flex items-center gap-1  mt-4">
        <img
          width="16"
          height="16"
          src="https://img.icons8.com/external-flatart-icons-solid-flatarticons/64/external-decoration-christmas-flatart-icons-solid-flatarticons.png"
          alt="external-decoration-christmas-flatart-icons-solid-flatarticons"
        />
        Decoration
      </div>
      <div className="w-full overflow-scroll gap-2 flex flex-nowrap ">
        {product.images.map((currImg, idx) => {
          return (
            <img
              onClick={() => {
                setDecorPreviewStates((p) => ({
                  ...p,
                  renderingImgUrl: product.images[idx],
                }));
              }}
              className="border min-w-[4rem] max-w-[4rem] aspect-square rounded-lg bg-white"
              alt=""
              src={product.images[idx]}
            />
          );
        })}
      </div>

      {order?.addOns?.length > 0 && (
        <div className="w-full text-[#171717] flex flex-col gap-2 border-t border-dotted mt-4 pt-2">
          <div className=" font-[600] text-[1rem] flex justify-between item-center">
            <p className="flex items-center gap-1">
              <TbCategoryPlus className=" relative bottom-[1px]" />
              Add ons{" "}
              <span className="text-[.725rem]">
                (
                {order?.freebie?._id
                  ? order?.addOns?.length + 1
                  : order?.addOns?.length}
                items )
              </span>
            </p>
          </div>

          <div className=" flex justify-center bg-white rounded-lg  px-2">
            <div className="flex-1 flex flex-col gap-2 py-1 max-h-[35vh] overflow-hidden overflow-y-scroll scroll-bar-remove">
              {order?.addOns?.map((addon) => {
                return (
                  <div
                    onClick={() => {
                      setDecorPreviewStates((p) => ({
                        ...p,
                        renderingImgUrl: addon.addonId.addonImage,
                      }));
                    }}
                    key={addon.addonId._id}
                    className="  text-[.875rem] py-2  px-1 flex border-b border-gray-100  items-start justify-between "
                  >
                    <div className=" flex items-start gap-3">
                      <img
                        src={addon.addonId.addonImage}
                        className="w-[3.5rem] border border-gray-200 rounded-lg"
                        alt=""
                      />
                      <div className="flex flex-col  py-1 justify-center ">
                        <p className=" font-[600] ]">
                          {addon.addonId.addonName}
                        </p>
                        {addon.text && (
                          <p className=" text-[gray] leading-4">
                            added text:{" "}
                            <span className=" font-[500] text-[black]">
                              {addon.text}
                            </span>{" "}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="text-[.925rem]  py-1 font-[500] flex item-center gap-2 whitespace-nowrap">
                      <p className="text-[gray]"> x {addon.quantity}</p>
                    </div>
                  </div>
                );
              })}

              {order?.freebie?._id && (
                <div
                  key={order.freebie._id}
                  className=" border-t border-[#dbdbdbc2] py-2  px-1 flex justify-between items-center"
                >
                  <div className=" flex gap-4">
                    <img
                      src={order.freebie.image}
                      className="w-[5rem] rounded-xl"
                      alt=""
                    />
                    <div className="flex flex-col justify-center py-1 ">
                      <p className="text-[.825rem] font-[600] text-[black]">
                        {order.freebie.name}
                      </p>
                      <p className="text-[.725rem] ">Qty: 1</p>
                    </div>
                  </div>
                  <div className="text-[.925rem]  py-1 font-[500] flex item-center gap-2 whitespace-nowrap">
                    <p className="text-[gray]"> x 1</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
  //   return <div className="w-[95%] rounded-lg bg-white p-4 ">DecorPreview</div>;
}
