import { useContext, useEffect } from "react";
import GlobalStoreContext from "../context/GlobalStoreContext";
import { update_santa_api } from "../api/santaApi";
import AuthContext from "../context/AuthContext";

export default function useNotificationHandler() {
  const {
    storeStates: { expoToken, deviceToken },
  } = useContext(GlobalStoreContext);

  const {
    initialState: { santa },
  } = useContext(AuthContext);

  useEffect(() => {
    updateExpoToken();

    // eslint-disable-next-line
  }, [expoToken, deviceToken]);

  useEffect(() => {
    initiateToGetDeviceToken();
    initiateToGetExpoToken();
  }, [santa?._id]);

  const initiateToGetExpoToken = async () => {
    const props = {
      action: "getExpoToken",
    };
    window.sendMessageToNative(props);
  };

  const updateExpoToken = async () => {
    try {
      let updateObject = {};
      let shouldUpdate = false;
      // for expo token
      if (
        santa?._id &&
        expoToken &&
        !santa?.notification?.expoToken?.includes(expoToken)
      ) {
        const expoTokens = santa?.notification?.expoToken || [];
        expoTokens.push(expoToken);
        updateObject["notification.expoToken"] = expoTokens;
        shouldUpdate = true;
      }

      // for device token
      if (
        santa?._id &&
        deviceToken &&
        !santa?.notification?.deviceToken?.includes(deviceToken)
      ) {
        const deviceTokens = santa?.notification?.deviceToken || [];
        deviceTokens.push(deviceToken);
        updateObject["notification.deviceToken"] = deviceTokens;
        shouldUpdate = true;
      }

      // updating tokens in santa
      if (shouldUpdate) {
        await update_santa_api({
          options: {
            queryObject: { _id: santa._id },
            updateObject: updateObject,
            projectionString: "",
            tags: ["crud", "update"],
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initiateToGetDeviceToken = async () => {
    const props = {
      action: "getDeviceToken",
    };
    window.sendMessageToNative(props);
  };

  return {
    initiateToGetExpoToken,
    initiateToGetDeviceToken,
    expoToken,
    deviceToken,
    updateExpoToken,
  };
}
