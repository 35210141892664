import React from "react";

export default function MapEmbed({
  lat,
  lng,
  height = "500px",
  width = "100%",
}) {
  return (
    <iframe
      width={width}
      height={height}
      loading="lazy"
      title="google map"
      allowfullScreen
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&q=${lat},${lng}`}
    ></iframe>
  );
}
