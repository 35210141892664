import React from "react";
import { FaDirections, FaLocationArrow, FaRegClock } from "react-icons/fa";
import ServiceTags from "../subC/serviceTags/ServiceTags";
import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function WorkInfoContainer({ order }) {
  const address = order.addressId;
  const delivery = order.date.delivery;
  return (
    <div className="w-[95%] flex flex-col">
      <div className=" w-full flex flex-col relative  text-[.875rem] font-[400] p-4 bg-[white] rounded-lg border border-gray-200  ">
        <div
          onClick={() =>
            window.openUrl(
              `https://www.google.com/maps/dir/?api=1&destination=${address.locationv2.lat},${address.locationv2.lng}&travelmode=driving`
            )
          }
          className=" text-[1.125rem] absolute top-4 right-4 text-[white] gap-2 font-[500] blue-gradient flex items-center justify-center bg-[#2136d4] rounded-full size-[2rem] "
        >
          <FaDirections />
        </div>

        <p className=" text-[1.125rem] font-[500] text-[black]">
          {formatDateTime(delivery?.celebrationDate)}
        </p>
        <p className=" text-sm font-[500] ">
          <span className="text-gray-500"> Reach by: </span>
          {moment(delivery.arrivingTime, "HH").format("hh:mm A")}
        </p>

        <div className="w-full flex items-start mt-2 gap-2 relative">
          <div className=" flex flex-col w-full py-[2px]">
            <div className="text-black text-[1rem] flex items-center gap-1 ">
              <FaLocationArrow className="text-[#2136d4] text-[1rem] " />
              {address.locationv2.addressLine1}
            </div>
            <div className="text-[gray] text-[.875rem]  font-[400] leading-[19px]  ">
              {address.locationv2?.addressLine2}
            </div>
          </div>
        </div>

        <div className=" flex gap-1 items-center mt-2 ">
          <FaRegClock className="text-[#2136d4]" /> {delivery.workHour} hr of
          service
        </div>

        <ServiceTags tags={order?.productId?.tags || []} />
      </div>
    </div>
  );
}

const formatDateTime = (timeOfPresence) => {
  const now = moment();
  const presenceDate = moment(timeOfPresence);

  if (presenceDate.isSame(now, "day")) {
    return "Today, " + presenceDate.format("Do MMMM ");
  } else if (presenceDate.isSame(now.clone().add(1, "day"), "day")) {
    return "Tomorrow, " + presenceDate.format("Do MMMM ");
  } else if (presenceDate.isSame(now.clone().subtract(1, "day"), "day")) {
    return "Yesterday, " + presenceDate.format("Do MMMM ");
  } else {
    return presenceDate.format("Do MMMM ");
  }
};
