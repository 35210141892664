import { useEffect, useState } from "react";
import { CiCalendar } from "react-icons/ci";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { analyseServiceTimev2 } from "../helperFn";

import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function TopBox({ parentStates, setParentStates }) {
  const nav = useNavigate();
  // const result = analyseServiceTime(parentStates.order);
  const [states, setStates] = useState({
    isLoading: false,
    delivery: parentStates.order?.date?.delivery,
    extraTimeGrantModal: false,
    timer: null,
  });

  useEffect(() => {
    if (states.null !== null && states.timer <= 0) {
      setStates((p) => ({
        ...p,
        extraTimeGrantModal: true,
        timer: 0,
      }));
    } else {
      const timerInterval = setInterval(
        () =>
          setStates((p) => ({
            ...p,
            timer: p.timer - 1,
            extraTimeGrantModal: false,
          })),
        1000
      );
      return () => clearInterval(timerInterval);
    }
    // eslint-disable-next-line
  }, [states.timer]);

  useEffect(() => {
    const result_ = analyseServiceTimev2(parentStates.order);
    if (result_.isExtraTimeModalOpen) {
      setStates((p) => ({
        ...p,
        extraTimeGrantModal: true,
        timer: 0,
        ...result_,
      }));
    } else {
      setStates((p) => ({
        ...p,
        extraTimeGrantModal: false,
        timer: result_.workCounter,
        ...result_,
      }));
    }
    // eslint-disable-next-line
  }, [parentStates.order]);

  return (
    <>
      <div className=" items-center  z-[2] justify-center flex flex-col fixed top-0 right-0 left-0 rounded-b-xl blue-gradient p-6 py-5">
        <FaAngleLeft
          onClick={() => nav("/orders")}
          className=" fixed top-6 left-4 text-[1.5rem]  "
        />
        {parentStates.currStatus === "serviceStarted" && <TopHeading />}
        <div className="flex items-center justify-between mt-4 w-full  text-[white]">
          {parentStates.currStatus === "serviceStarted" ? (
            <TimerContainer
              states={states}
              delivery={parentStates.order?.date?.delivery}
              setStates={setStates}
            />
          ) : (
            <div className="flex flex-col font-[500] text-[1rem] items-center">
              <CiCalendar className="text-[6rem]" />
              <div className="leading-3">
                {states.isLoading ? (
                  <p className="h-[1.25rem] rounded-lg w-[5rem] ebo_skeleton "></p>
                ) : (
                  moment(states.delivery?.celebratingDate).format("ddd, D MMM")
                )}
              </div>
            </div>
          )}
          <RightBox states={states} setStates={setStates} />
        </div>
      </div>
      {/* <ExtraTimeGrantModal
        order={parentStates.order}
        setParentStates={setParentStates}
        states={states}
        setStates={setStates}
      /> */}
    </>
  );

  function TopHeading() {
    return (
      <div className="flex gap-2 rounded-full p-1 text-[.875rem] items-center bg-blue-50 ">
        <img
          alt=""
          src={parentStates.order?.productId?.images[0]}
          className="rounded-full aspect-square w-[1.5rem]"
        />

        <div className="text-[#2136d4] pr-2 font-[600]">Ongoing Service</div>
      </div>
    );
  }

  function TimerContainer({ states }) {
    const duration = moment.duration(states.timer, "seconds");

    const formattedTime2 = moment
      .utc(duration.asMilliseconds())
      .format("HH:mm:ss");

    return (
      <div className="flex flex-col font-[500] text-[1rem] items-center">
        <div className=" aspect-square p-4 border-[4px] border-white rounded-full flex flex-col items-center justify-center">
          <p className="text-[.875rem] font-[600] leading-[14px] mt-2 ">
            Ready in
          </p>
          <p className="text-[1.25rem] whitespace-nowrap font-semibold">
            {formattedTime2}
          </p>
        </div>
      </div>
    );
  }

  function RightBox({ states }) {
    return (
      <div className="flex flex-col justify-end  gap-[6px] items-center">
        <p className="text-[1rem] leading-[1] font-[500] ">
          {states.isDelayed ? "Ready time was" : "Ready time"}
        </p>
        <div className=" text-[1.25rem] font-medium leading-[1] ">
          <p className={` text-[1.75rem] font-[800] `}>
            {states.estimatedReadyTime}
          </p>
        </div>

        <div className="font-[600]  rounded-full px-3 py-[2px] text-[1rem]  bg-[#ffffff42]  text-[white] w-full text-center">
          {states.isDelayed ? "Delayed" : "On time"}
        </div>
      </div>
    );
  }
}
