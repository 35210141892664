import React from "react";
import Header2 from "../../assets/headers/Header2";
import { useParams } from "react-router-dom";
import EWPayoutDetails from "../../components/earnings/payout/EWPayoutDetails";

// each week payout details
export default function EWPayoutDetailsPage() {
  const { week, year } = useParams();

  return (
    <div className="fixed top-0 px-4 space-y-4 right-0 left-0 bottom-0 bg-blue-50 pb-[3rem] flex overflow-scroll flex-col pt-[4rem] items-center  ">
      <Header2 title={"Payout details"} />
      <EWPayoutDetails week={week} year={year} />
    </div>
  );
}
