import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import CommonHeader from "../../../../../../assets/CommonHeader";
import { s3UploadApi } from "../../../../../../api/aws/s3";
import SlideToAcceptBtn from "../../../../../../assets/SlideToAcceptBtn";
import { FaCameraRetro } from "react-icons/fa";

export default function SantaTeamSelfie({
  states,
  setStates,
  order,
  handleSubmit,
}) {
  const santaImageInputRef = useRef(null);

  useEffect(() => {
    openCamera();

    // eslint-disable-next-line
  }, []);

  const handleUpload = async (santaGroupImgFile) => {
    try {
      if (!santaGroupImgFile) {
        santaGroupImgFile = states.santaGroupImgFile;
      }
      setStates((p) => ({ ...p, isLoading: true }));
      // upload to s3 logic, if success then call handleSubmit
      const santaGroupImgToUploadObj = [
        {
          file: santaGroupImgFile,
          path: `orders/${order._id}/santaGroupImg.webp`,
        },
      ];
      await s3UploadApi(santaGroupImgToUploadObj);

      await handleSubmit(
        `https://img.ebonow.com/custom/orders/${order._id}/santaGroupImg.webp`,
        "santaSelfiTaken"
      );

      setStates((p) => ({ ...p, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };

  const onImagePick = (file) => {
    handleUpload(file);
  };

  const openCamera = () => {
    window.pickImage({
      onImagePickEnd: onImagePick,
      pickFrom: "camera",
      allowsEditing: false,
    });
  };

  return (
    <motion.div
      initial={{ opacity: 1, x: " 100%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.3 }}
      className="fixed top-0 bottom-0 left-0 right-0 bg-blue-50 z-[17]"
    >
      {states.isLoading && (
        <div className="fixed text-white top-0 gap-2 right-0 left-0 bottom-0 bg-[#000000ac] z-[9] flex items-center justify-center ">
          Uploading...{" "}
          <div
            style={{ borderTopColor: "white" }}
            className="loader w-[1rem]"
          ></div>
        </div>
      )}
      {!santaImageInputRef && (
        <div className="fixed text-white top-0 gap-2 right-0 left-0 bottom-0 bg-[#000000ac] z-[9] flex items-center justify-center ">
          Please wait...{" "}
          <div
            style={{ borderTopColor: "white" }}
            className="loader w-[1rem]"
          ></div>
        </div>
      )}

      <CommonHeader
        content="Santa team selfie"
        navFun={() => {
          // setParentStates((p) => ({ ...p, isCurrStatusPageRender: false }));
          setStates((p) => ({ ...p, santaTeamSelfie: false }));
        }}
      />
      <div className="mx-4 mt-2">
        {states.santaGroupImg ? (
          <div className="relative">
            <img
              src={states.santaGroupImg}
              alt=""
              className="min-h-[200px]  rounded-[10px] border w-full"
            />
          </div>
        ) : (
          <div className="user-photo-upload-placeholder border rounded-[12px] p-4  min-h-[500px] flex items-center justify-center bg-gray-50">
            <div className="cursor-pointer flex flex-col items-center gap-2 flex-1">
              <FaCameraRetro className="text-[4rem] text-[#2136D4]" />

              <div className="text-[gray] text-center w-full">
                Make sure all santa team members are visible
              </div>
            </div>
          </div>
        )}
      </div>
      {states.santaGroupImg ? (
        <div className="m-2 fixed flex gap-2 bottom-0 left-0 right-0 ">
          <label
            className={`py-3 px-4 text-center w-full rounded-[.5rem] font-[500] text-[1.125rem]   
        border border-gray-300 bg-gray-100   `}
            onClick={openCamera}
          >
            Retake
          </label>
          <SlideToAcceptBtn
            btnText="Confirm"
            fun={handleUpload}
            disabled={!states.santaGroupImgFile}
            isSimple={true}
          />
        </div>
      ) : (
        <div className="m-2 fixed flex bottom-0 left-0 right-0 ">
          <label
            className={`py-3 px-4 text-center w-full rounded-[.5rem] font-[500] text-[1.125rem]   
              before:w-[200px] mirror-Animation blue-gradient   `}
            onClick={openCamera}
          >
            Take picture
          </label>
        </div>
      )}
    </motion.div>
  );
}
