import axios from "axios";
import imageCompression from "browser-image-compression";
import AWS from "aws-sdk";
async function compressImage(img) {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(img, options);
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
}

async function validateImage(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const image = new Image();
      image.onload = () => resolve(true); // Valid image
      image.onerror = () => reject(new Error("Corrupted image")); // Cannot load
      image.src = event.target.result; // Set the data URL
    };
    reader.onerror = () => reject(new Error("Error reading file")); // Read error
    reader.readAsDataURL(file); // Read the file
  });
}

export async function s3UploadApi(files) {
  try {
    /* use case:
    files: Array 
    example [
       {
        file: file,
        path: [the path to which the file will be uploaded on s3], ex: p1/p2/file.webp 
        then the url will be https://img.ebonow.com/custom/p1/p2/file.webp
         santa img ex: santa/santaid/pic1.webp 
       },
    ]
    */

    for (const curr of files) {
      console.log("curr", curr);
      const compressedFile = await compressImage(curr.file);

      // Validate the compressed file
      await validateImage(compressedFile);

      const file = new File([compressedFile], {
        type: compressedFile.type,
      });

      await uploadFileToS3FromClient(file, "custom/" + curr.path);
    }
    return { isSuccess: true };
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
    };
  }
}

const uploadFileToS3FromClient = async (file, path) => {
  const S3_BUCKET = process.env.REACT_APP_IMAGES_BUCKET_NAME;
  const REGION = process.env.REACT_APP_EBO_AWS_REGION;
  if (!S3_BUCKET) return;
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_IMAGES_BUCKET_HANDLER_ACCESS_KEY,
    secretAccessKey:
      process.env.REACT_APP_IMAGES_BUCKET_HANDLER_SECRET_ACCESS_KEY,
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const params = {
    Bucket: S3_BUCKET,
    Key: path,
    Body: file,
    ContentType: "image/webp",
  };

  var upload = s3
    .putObject(params)
    .on("httpUploadProgress", (evt) => {})
    .promise();

  await upload;
};

export async function s3RemoveApi(paths) {
  try {
    /* use case:
    paths: Array 
    example [
     '[objectPath]', ... 
    ]
    */
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/s3/s3Remove`,
      { paths },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
    };
  }
}
