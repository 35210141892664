// import React, { useContext, useEffect } from "react";
// import { FiLoader } from "react-icons/fi";
// import GlobalStoreContext from "../context/GlobalStoreContext";
// /*
// use case:
//   const containerRef = useRef(null);

//  const [refereshStates, setRefereshStates] = useState({});

//   usePullToRefresh(
//     containerRef,
//     () => console.log("triggers referesh"),
//     (params) => {
//       setRefereshStates(params);
//     },
//     {
//       isGradient: true,
//       loaderColor: "white",
//     }
//   );

//  <div ref={containerRef} className=" ">  // the container on which you want the refresh effect
//         <RefreshLoader refereshStates={refereshStates} />
//         // rest components
//  </div>

// */

// function usePullToRefresh(ref, onTrigger, refreshIndicator, loaderOptions) {
//   const TRIGGER_THRESHOLD = 150;
//   const SHOW_INDICATOR_THRESHOLD = 30;

//   useEffect(() => {
//     const handleTouchStart = (startEvent) => {
//       const el = ref.current;
//       if (!el) return;

//       // get the initial Y position
//       const initialY = startEvent.touches[0].clientY;

//       el.addEventListener("touchmove", handleTouchMove);
//       el.addEventListener("touchend", handleTouchEnd);

//       function handleTouchMove(moveEvent) {
//         const el = ref.current;
//         if (!el) return;

//         if (el.scrollTop > 0) return; // Ensure container is at the top

//         // get the current Y position
//         const currentY = moveEvent.touches[0].clientY;

//         // get the difference
//         const dy = currentY - initialY;
//         const contTopPosition = ref.current.scrollTop;
//         if (contTopPosition > 10) {
//           refreshIndicator({
//             dy,
//             loaderRender: false,
//             TRIGGER_THRESHOLD,
//             SHOW_INDICATOR_THRESHOLD,
//             loaderOptions,
//           });
//           return;
//         }
//         if (dy < 0) return;
//         if (dy > TRIGGER_THRESHOLD) {
//           refreshIndicator({
//             dy,
//             loaderRender: true,
//             TRIGGER_THRESHOLD,
//             SHOW_INDICATOR_THRESHOLD,
//             loaderOptions,
//           });
//         } else if (dy > SHOW_INDICATOR_THRESHOLD) {
//           refreshIndicator({
//             dy,
//             loaderRender: true,
//             TRIGGER_THRESHOLD,
//             SHOW_INDICATOR_THRESHOLD,
//             loaderOptions,
//           });
//         } else {
//           refreshIndicator({
//             dy,
//             loaderRender: false,
//             TRIGGER_THRESHOLD,
//             SHOW_INDICATOR_THRESHOLD,
//             loaderOptions,
//           });
//         }
//       }
//       function handleTouchEnd(endEvent) {
//         const el = ref.current;
//         if (!el) return;

//         // run the callback
//         const y = endEvent.changedTouches[0].clientY;
//         const dy = y - initialY;

//         const parts = Math.floor(
//           (dy - SHOW_INDICATOR_THRESHOLD > 0
//             ? dy - SHOW_INDICATOR_THRESHOLD
//             : 0) /
//             (TRIGGER_THRESHOLD / 12)
//         );
//         const linesToShow = Math.min(parts, 12);
//         const contTopPosition = ref.current.scrollTop;

//         if (contTopPosition > 10) {
//           refreshIndicator({
//             dy,
//             loaderRender: false,
//             TRIGGER_THRESHOLD,
//             SHOW_INDICATOR_THRESHOLD,
//             loaderOptions,
//           });
//           return;
//         }
//         const containerYPosition = el.scrollTop;

//         if (linesToShow >= 12 && containerYPosition <= 10) {
//           onTrigger();
//           window.toast(
//             linesToShow +
//               "  " +
//               containerYPosition +
//               " " +
//               contTopPosition +
//               " " +
//               dy +
//               " " +
//               y
//           );
//           refreshIndicator({
//             dy,
//             loaderRender: false,
//             TRIGGER_THRESHOLD,
//             SHOW_INDICATOR_THRESHOLD,
//             loaderOptions,
//           });
//         } else if (dy >= 10) {
//           refreshIndicator({
//             dy,
//             loaderRender: false,
//             TRIGGER_THRESHOLD,
//             SHOW_INDICATOR_THRESHOLD,
//             loaderOptions,
//           });
//         }

//         // listen for transition end event
//         el.addEventListener("transitionend", onTransitionEnd);

//         // cleanup
//         el.removeEventListener("touchmove", handleTouchMove);
//         el.removeEventListener("touchend", handleTouchEnd);
//       }

//       function onTransitionEnd() {
//         const el = ref.current;
//         if (!el) return;

//         // remove transition
//         el.style.transition = "";

//         // cleanup
//         el.removeEventListener("transitionend", onTransitionEnd);
//       }
//     };

//     const el = ref.current;
//     if (el) {
//       el.addEventListener("touchstart", handleTouchStart);
//     }

//     return () => {
//       if (el) {
//         el.removeEventListener("touchstart", handleTouchStart);
//       }
//     };

//     // eslint-disable-next-line
//   }, [ref, onTrigger]);
// }
// let isVibrateFlag = true;

// export function RefreshLoader({ refereshStates }) {
//   const totalParts = 12;
//   const TRIGGER_THRESHOLD = refereshStates.TRIGGER_THRESHOLD / totalParts;

//   const { handleVibrate } = useContext(GlobalStoreContext);
//   const parts = Math.floor(
//     (refereshStates.dy - refereshStates.SHOW_INDICATOR_THRESHOLD > 0
//       ? refereshStates.dy - refereshStates.SHOW_INDICATOR_THRESHOLD
//       : 0) / TRIGGER_THRESHOLD
//   );
//   const linesToShow = Math.min(parts, 13);
//   useEffect(() => {
//     if (linesToShow === 12 && isVibrateFlag) {
//       handleVibrate();
//       isVibrateFlag = false;
//     }
//     if (linesToShow < 12 && !isVibrateFlag) {
//       isVibrateFlag = true;
//     }
//     // eslint-disable-next-line
//   }, [linesToShow]);
//   const lineStyle = {
//     margin: "24px",
//     width: "2.5px",
//     height: "9px",
//     background: refereshStates?.loaderOptions?.loaderColor || "white",
//     position: "absolute",
//     transformOrigin: "center bottom",
//   };

//   const keyframeStyle = `
//           @keyframes opacityChange {
//             0% {
//               opacity: 0.2;
//             }
//             100%{
//               opacity:1;
//             }
//           }
//         `;

//   if (!refereshStates.loaderRender) return <></>;
//   return (
//     <div
//       style={{
//         height: Math.min(parts * 10, 150),
//         minHeight: Math.min(parts * 10, 150),
//         transitionDuration: ".3",
//         transition: "all",
//         transitionBehavior: "normal",
//         transitionTimingFunction: "linear",
//       }}
//       className={`w-full flex items-center  justify-center ${
//         refereshStates?.loaderOptions?.isGradient ? " blue-gradient" : ""
//       }`}
//     >
//       <div
//         style={{
//           transform: `scale(${Math.min(parts / 10, 1)})`,

//           transitionDuration: ".3",
//           transition: "all",
//           transitionBehavior: "normal",
//           transitionTimingFunction: "linear",
//         }}
//         className="flex justify-center items-center"
//       >
//         {linesToShow >= 12 ? (
//           <FiLoader className="text-[2rem] spinner" />
//         ) : (
//           <>
//             <style>{keyframeStyle}</style>
//             {Array.from({ length: linesToShow }).map((_, index) => (
//               <div
//                 key={index}
//                 style={{
//                   ...lineStyle,
//                   transform: `rotate(calc(${index} * (360deg / 12)))  translateY(-68%) translateX(-5%)`,
//                 }}
//               ></div>
//             ))}
//           </>
//         )}
//       </div>
//     </div>
//   );
// }

// export default usePullToRefresh;

import React, { useContext, useEffect, useRef } from "react";
import { FiLoader } from "react-icons/fi";
import GlobalStoreContext from "../context/GlobalStoreContext";

function usePullToRefresh(ref, onTrigger, refreshIndicator, loaderOptions) {
  const TRIGGER_THRESHOLD = 150;
  const SHOW_INDICATOR_THRESHOLD = 30;

  const initialYRef = useRef(null);
  const isRefreshing = useRef(false);

  useEffect(() => {
    const handleTouchStart = (startEvent) => {
      const el = ref.current;
      if (!el || el.scrollTop > 0) return;

      initialYRef.current = startEvent.touches[0].clientY;
      isRefreshing.current = false;

      el.addEventListener("touchmove", handleTouchMove);
      el.addEventListener("touchend", handleTouchEnd);
    };

    const handleTouchMove = (moveEvent) => {
      const el = ref.current;
      if (!el || el.scrollTop > 0 || isRefreshing.current) return;

      const currentY = moveEvent.touches[0].clientY;
      const dy = currentY - initialYRef.current;

      if (dy < 0) return; // Ignore upward scroll

      if (dy > TRIGGER_THRESHOLD) {
        refreshIndicator({
          dy,
          loaderRender: true,
          TRIGGER_THRESHOLD,
          SHOW_INDICATOR_THRESHOLD,
          loaderOptions,
        });
      } else if (dy > SHOW_INDICATOR_THRESHOLD) {
        refreshIndicator({
          dy,
          loaderRender: true,
          TRIGGER_THRESHOLD,
          SHOW_INDICATOR_THRESHOLD,
          loaderOptions,
        });
      } else {
        refreshIndicator({
          dy,
          loaderRender: false,
          TRIGGER_THRESHOLD,
          SHOW_INDICATOR_THRESHOLD,
          loaderOptions,
        });
      }
    };

    const handleTouchEnd = (endEvent) => {
      const el = ref.current;
      if (!el) return;

      const y = endEvent.changedTouches[0].clientY;
      const dy = y - initialYRef.current;

      if (dy > TRIGGER_THRESHOLD && el.scrollTop <= 10) {
        onTrigger();
        isRefreshing.current = true;
      }

      refreshIndicator({
        dy,
        loaderRender: false,
        TRIGGER_THRESHOLD,
        SHOW_INDICATOR_THRESHOLD,
        loaderOptions,
      });

      el.removeEventListener("touchmove", handleTouchMove);
      el.removeEventListener("touchend", handleTouchEnd);
    };

    const el = ref.current;
    if (el) {
      el.addEventListener("touchstart", handleTouchStart);
    }

    return () => {
      if (el) {
        el.removeEventListener("touchstart", handleTouchStart);
      }
    };
  }, [ref, onTrigger, refreshIndicator, loaderOptions]);

  // eslint-disable-next-line
}

let isVibrateFlag = true;

export function RefreshLoader({ refereshStates }) {
  const totalParts = 12;
  const TRIGGER_THRESHOLD = refereshStates.TRIGGER_THRESHOLD / totalParts;

  const { handleVibrate } = useContext(GlobalStoreContext);
  const parts = Math.floor(
    (refereshStates.dy - refereshStates.SHOW_INDICATOR_THRESHOLD > 0
      ? refereshStates.dy - refereshStates.SHOW_INDICATOR_THRESHOLD
      : 0) / TRIGGER_THRESHOLD
  );
  const linesToShow = Math.min(parts, 13);

  useEffect(() => {
    if (linesToShow === 12 && isVibrateFlag) {
      handleVibrate();
      isVibrateFlag = false;
    }
    if (linesToShow < 12 && !isVibrateFlag) {
      isVibrateFlag = true;
    }
    // eslint-disable-next-line
  }, [linesToShow]);

  const lineStyle = {
    margin: "24px",
    width: "2.5px",
    height: "9px",
    background: refereshStates?.loaderOptions?.loaderColor || "white",
    position: "absolute",
    transformOrigin: "center bottom",
  };

  const keyframeStyle = `
    @keyframes opacityChange {
      0% {
        opacity: 0.2;
      }    
      100%{
        opacity:1;
      }
    }
  `;

  if (!refereshStates.loaderRender) return <></>;
  return (
    <div
      style={{
        height: Math.min(parts * 10, 150),
        minHeight: Math.min(parts * 10, 150),
        transitionDuration: ".3s",
        transition: "all",
        transitionTimingFunction: "linear",
      }}
      className={`w-full flex items-center justify-center ${
        refereshStates?.loaderOptions?.isGradient ? "blue-gradient" : ""
      }`}
    >
      <div
        style={{
          transform: `scale(${Math.min(parts / 10, 1)})`,
          transitionDuration: ".3s",
          transition: "all",
          transitionTimingFunction: "linear",
        }}
        className="flex justify-center items-center"
      >
        {linesToShow >= 12 ? (
          <FiLoader className="text-[2rem] spinner" />
        ) : (
          <>
            <style>{keyframeStyle}</style>
            {Array.from({ length: linesToShow }).map((_, index) => (
              <div
                key={index}
                style={{
                  ...lineStyle,
                  transform: `rotate(calc(${index} * (360deg / 12))) translateY(-68%) translateX(-5%)`,
                }}
              ></div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default usePullToRefresh;
