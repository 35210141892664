import React from "react";

export default function SantaTeam({ santaGroups }) {
  return (
    <div className="flex items-start mt-1 gap-4  overflow-x-scroll flex-shrink-0  scroll-bar-remove  ">
      {santaGroups?.santas?.length > 0 &&
        santaGroups.santas.map((curr, idx) => {
          // don't show those santas who are optout
          if (curr.tags?.includes("optout")) return null;

          return (
            <div key={idx} className="w-[5rem] flex flex-col  items-center  ">
              <img
                src={
                  curr.santa?.personalDetails?.image ||
                  "/images/santaPicPlaceholder.jpg"
                }
                alt=""
                className="size-[3rem] bg-[white] rounded-full  border-blue-200 border "
              />

              <p className=" whitespace-nowrap text-ellipsis text-start text-[.85rem] ">
                {curr.santa.personalDetails.name.substring(0, 12)}
              </p>
              {santaGroups?.santas?.length > 1 &&
                curr.tags.includes("leader") && (
                  <div className="text-[gray] text-[.75rem] flex flex-wrap space-x-2 capitalize items-center justify-center">
                    Admin
                  </div>
                )}
            </div>
          );
        })}
    </div>
  );
}
