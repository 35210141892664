import { GrDocumentTime } from "react-icons/gr";

export default function ReviewOnboardingPage() {
  return (
    <div className="px-2 pb-[110px]">
      <div className="flex flex-col justify-center items-center mt-4 bg-white w-full p-6 rounded-[.5rem] shadow-lg">
        <GrDocumentTime className="text-blue-500 text-[4rem] mb-4" />
        <p className="mb-2 text-[1.5rem] font-bold text-gray-800">
          Registration in Review
        </p>
        <p className="  text-gray-600">
          Your account is currently under review.
        </p>
      </div>

      <div className="mt-8 bg-gray-50 border w-full p-4 rounded-[.5rem] shadow">
        <p className="text-md font-semibold mb-2 text-gray-800">
          Important Information:
        </p>
        <div className="space-y-2 text-gray-600 mb-4">
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>Your documents are being verified.</p>
          </div>
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>The review process typically takes 1-3 business days.</p>
          </div>
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>You will receive a notification once the review is complete.</p>
          </div>
        </div>
        <p className="text-md font-semibold mb-2 text-gray-800">Next Steps:</p>
        <div className="space-y-2 text-gray-600">
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>Check your Whatsapp/email for any updates from our team.</p>
          </div>
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>Contact support if you have any questions.</p>
            {/* <p>Contact support for any urgent queries.</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
