
import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export const analyseServiceTimev2 = (order) => {
  try {
    const delivery = order.date.delivery;

    let workCounter = 0; // in minutes
    let estimatedReadyTime = moment(
      parseInt(delivery.arrivingTime) + parseInt(delivery.workHour),
      "HH"
    )
      .add(delivery.extraWorkMinutesGranted, "minutes")
      .format("hh:mm A");
    let isDelayed = false;
    let isExtraTimeModalOpen = false;
    const startTime = moment(delivery.celebrationDate)
      .startOf("day")
      .hours(delivery.arrivingTime);

    const diffSeconds = moment().diff(startTime, "seconds");
    const workHourInSec =
      delivery.workHour * 60 * 60 + delivery.extraWorkMinutesGranted * 60;
    if (diffSeconds <= 0) {
      // arrived before the service day
      workCounter =
        Math.abs(diffSeconds) +
        workHourInSec +
        delivery.extraWorkMinutesGranted * 60;
    } else {
      if (diffSeconds <= workHourInSec) {
        workCounter = workHourInSec - diffSeconds;
      } else {
        isDelayed = true;
        isExtraTimeModalOpen = true;
      }
    }

    return { workCounter, estimatedReadyTime, isDelayed, isExtraTimeModalOpen };
  } catch (error) {
    console.error(error);
  }
};

// date: {
//     orderDate: { type: Date },
//     delivery: {
//       extraWorkMinutesGranted: {type: Number, default: 0},  // the extra work time given to santa for work to be done. ( delay time )
//       workHour: { type: Number, default: 2 }, // estimated time for completion of decor in hours
//       readyTime: { type: String, default: "16" }, // 24 hr string
//       arrivingTime: { type: String, default: "16" }, // 24 hr string
//       celebrationDate: { type: Date, default: "" },
//       minDistanceOfSantaFromLocation: { type: Number, default: 500 }, // (in meter) if the distance of santa and the service location in with in this value then only the santa can make the service status to santaArrived. NOTE: if it is more than 2km (i.e 2000) then the santa arrived or not does not matter
//     },
//   },
