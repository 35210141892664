import React, { useState } from "react";
import { FaCheckCircle, FaRegStar, FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BsEmojiLaughingFill } from "react-icons/bs";
import { motion } from "framer-motion";
import moment from "moment-timezone";
import { BsFillPhoneFill } from "react-icons/bs";
import { findServiceStatus } from "../../../subC/helperFunctions";
import useFetchInitialData from "../../../../../../hooks/useFetchInitialData";
// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function OrderCompletionPage({ order, updateOrder }) {
  const completionTime =
    findServiceStatus(order.orderServiceStatus, "completed")?.child?.date ||
    moment(order.date.delivery.celebrationDate)
      .hour(parseInt(order.date.delivery.readyTime))
      .toISOString();

  return (
    <div className="fix-screen flex-col space-y-4 bg-blue-50 ">
      <AnimationTopContainer completionTime={completionTime} />
      <FeedBackContainer order={order} updateOrder={updateOrder} />
    </div>
  );
}

const FeedBackContainer = ({ order, updateOrder }) => {
  const nav = useNavigate();
  const { callMethods } = useFetchInitialData();

  const [states, setStates] = useState({
    stars: 5,
    review: "",
    submiting: false,
  });

  const onSubmit = async () => {
    // validation check for stars that stars should be greater than 0
    if (states.stars < 1) {
      window.toast("Please rate first!");
      return;
    }
    setStates({ ...states, submiting: true });

    try {
      await updateOrder({
        "feedbacks.santa.stars": states.stars,
        "feedbacks.santa.review": states.review,
      });

      await callMethods({ refetchData: true });

      nav(`/orders/completed/${order._id}`);
    } catch (error) {
      console.error(error);
    }

    setStates({ ...states, submiting: false });
  };

  return (
    <div className="w-full px-4 h-full  space-y-4 relative">
      <div className="w-full p-4 border border-gray-200 rounded-lg bg-white flex-col">
        <div className=" font-medium flex items-center text-base gap-2  ">
          <BsFillPhoneFill className="text-sm text-[#2136d4]" />
          Rate your work experience?
        </div>
        <div className="ml-5">
          <RatingStars states={states} setStates={setStates} />
        </div>
      </div>

      <div className="absolute bottom-0 right-0 px-4 py-3 left-0 bg-white">
        <div
          onClick={onSubmit}
          className="w-full rounded-lg blue-gradient text-center py-3 text-base font-medium "
        >
          {states.submiting ? (
            <>
              <div
                className="loader w-[1rem] mr-2"
                style={{
                  borderTopColor: "white",
                }}
              ></div>
              Submitting...
            </>
          ) : (
            "Submit"
          )}
        </div>
      </div>
    </div>
  );
};

const RatingStars = ({
  states,
  setStates,
  ratingType = "stars",
  reviewType = "review",
}) => {
  const starsCountArray = [1, 2, 3, 4, 5];

  return (
    <div className="flex-col w-full">
      <div className="flex items-center gap-1.5 mt-2 ">
        {starsCountArray.map((star) => {
          if (states[ratingType] >= star) {
            let starColor = "text-[#2136d4]";
            if (states[ratingType] === 3) {
              starColor = "text-orange-600";
            }

            if (states[ratingType] < 3) {
              starColor = "text-red-600";
            }

            return (
              <FaStar
                key={star}
                onClick={() => setStates({ ...states, [ratingType]: star })}
                className={`${starColor} animate__animated animate__tada   text-2xl cursor-pointer`}
              />
            );
          }
          return (
            <FaRegStar
              key={star}
              onClick={() => setStates({ ...states, [ratingType]: star })}
              className="text-gray-400 text-2xl cursor-pointer"
            />
          );
        })}
      </div>
      {/* only present for rating less than 4 */}
      {states[ratingType] < 4 && states[ratingType] > 0 && (
        <textarea
          className="w-full p-2 border border-gray-200 text-sm rounded-lg mt-3"
          placeholder="Please tell us what you feel bad..."
          value={states[reviewType]}
          onChange={(e) =>
            setStates({ ...states, [reviewType]: e.target.value })
          }
        />
      )}
    </div>
  );
};

const AnimationTopContainer = ({ completionTime }) => {
  return (
    <motion.div
      initial={{
        height: "100dvh",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
      animate={{
        height: "28dvh",
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
      }}
      transition={{
        delay: 1.2,
        ease: "easeOut",
        duration: 0.75,
      }}
      className="flex flex-col w-full flex-shrink-0 items-center pt-10 py-5 justify-center text-white z-[5] bg-green-600"
    >
      <FaCheckCircle
        style={{
          animationDelay: "0s",
        }}
        className="text-[8rem] mb-4 animate__animated animate__backInUp  text-white  delay-1000"
      />

      <div
        style={{
          animationDelay: "0.2s",
        }}
        className="animate__animated animate__backInUp text-xl text-white  font-semibold flex items-center gap-2"
      >
        Order Completed! <BsEmojiLaughingFill className="text-base" />
      </div>
      <div
        style={{
          animationDelay: "1.2s",
        }}
        className="animate__animated animate__fadeIn text-sm mt-1  text-white  font-regular"
      >
        {moment(completionTime).format("D, MMM - h:mm A")}
      </div>
    </motion.div>
  );
};
