import moment from "moment";
import React, { useCallback } from "react";
import { FaLocationArrow } from "react-icons/fa6";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdCall, MdDirections } from "react-icons/md";
import { useAuthContext } from "../../../../../../context/AuthContext";
import { useCompRendering } from "../../../../../../context/CompRenderingContext";
import CallToUserModal from "../../../../../../assets/CallToUserModal";

export default function AddressIdx({ order, updateOrder, isContainer = true }) {
  const {
    initialState: { santa },
  } = useAuthContext();

  const { states: compStates, setStates: setCompStates } = useCompRendering();

  const address = order.addressId;
  const addressLine2 =
    address?.address1 +
    ", " +
    (address?.address2 && address?.address2 + ", ") +
    address?.locationv2?.addressLine1 +
    ", " +
    address?.locationv2?.addressLine2;

  const isAddressOwnerNumberDisabled =
    order?.tags.includes("No2. failed") && !order?.tags.includes("No1. failed");

  const isUsersNumberDisabled =
    order?.tags.includes("No1. failed") && !order?.tags.includes("No2. failed");

  const onUserCallError = useCallback(
    async (callTo) => {
      let shouldDisableCallMasking = false;

      let updateObj = {
        $push: {},
      };

      if (
        parseInt(order?.addressId?.receiversDetails.mobileNumber) ===
        parseInt(order?.userId?.phoneNumber)
      ) {
        shouldDisableCallMasking = true;
        updateObj["$push"]["tags"] = "No1. failed";
      } else {
        if (callTo === "addressOwner") {
          if (order.tags?.includes("No1. failed")) {
            shouldDisableCallMasking = true;
          }
          updateObj["$push"]["tags"] = "No2. failed";
        }

        if (callTo === "customer") {
          if (order.tags?.includes("No2. failed")) {
            shouldDisableCallMasking = true;
          }
          updateObj["$push"]["tags"] = "No1. failed";
        }
      }

      if (shouldDisableCallMasking) {
        updateObj["disableCallMasking"] = true;
        updateObj["$push"]["events"] = {
          eventName: "Call masking disabled",
          time: moment().toISOString(),
          eventCreator: {
            creatorId: santa?._id,
            creatorType: "santa", // user, santa, admin
            creatorName: santa?.personalDetails?.name,
            creatorNumber: santa?.personalDetails?.phoneNumber,
          },
          remark:
            " Call masking disabled by santa: " + santa?.personalDetails?.name,
          tags: [],
        };

        window.openUrl(`tel:${order?.userId?.phoneNumber}`);
      }

      await updateOrder(updateObj);

      if (!shouldDisableCallMasking) {
        window.toast("Unable to call customer, please try another number");
      }
    },
    [santa, updateOrder, order]
  );

  const handleCallToUser = useCallback(
    (callTo = "addressOwner") => {
      // caller is either "addressOwner" or "customer"

      let number = order?.addressId?.receiversDetails.mobileNumber;
      let name = order?.addressId?.receiversDetails.name;

      if (callTo === "customer") {
        number = order?.userId?.phoneNumber;
        name = order?.userId?.name;
      }

      const user = {
        name: name,
        phoneNumber: number,
      };

      if (order?.disableCallMasking) {
        window.openUrl(`tel:${number}`);
      } else {
        setCompStates((p) => ({
          ...p,
          CallToUserModal: {
            isRender: true,
            user: user,
            onErrorCallBack: () => onUserCallError(callTo),
          },
        }));
      }
    },
    [onUserCallError, order, setCompStates]
  );

  const isSameNumber =
    parseInt(order?.userId?.phoneNumber) ===
    parseInt(order?.addressId?.receiversDetails?.mobileNumber);

  return (
    <>
      <CallToUserModal
        parentStates={compStates}
        setParentStates={setCompStates}
      />

      <div
        className={`flex flex-col ${
          isContainer
            ? " w-[95%] bg-[white]  rounded-lg border border-gray-200 mt-2 py-4"
            : "w-full"
        }`}
      >
        <div className="w-full flex mb-4 relative items-start px-4 gap-2">
          <div className=" p-2 mt-1 bg-blue-50 text-[#2136d4] rounded-full  text-lg size-[2rem] ">
            <FaLocationArrow />
          </div>
          <div className=" flex flex-col w-full  pt-0.5">
            <div className="text-[gray] text-xs ">Customer address</div>
            <div className="text-black text-[1rem] max-w-[12rem] mt-1  leading-[18px]  font-[500] ">
              {address.locationv2.addressLine1}
            </div>
            <div className="text-[gray] text-sm max-h-[3.2rem] overflow-scroll  leading-[18px] mt-1  ">
              {addressLine2}
            </div>
          </div>
          <div
            onClick={() => {
              window.openUrl(
                `https://www.google.com/maps/dir/?api=1&destination=${address.locationv2.lat},${address.locationv2.lng}&travelmode=driving`
              );
            }}
            className="flex items-center text-sm  absolute top-[0rem] blue-gradient rounded-full gap-1 font-[500] right-2 px-3 py-1 "
          >
            <MdDirections /> Direction
          </div>
        </div>

        {/* address owner number */}
        <div className="w-full flex mb-4  items-center px-4 gap-2">
          <div className="flex mt-1 items-center justify-center flex-shrink-0 size-[2rem] aspect-square bg-blue-50 text-[#2136d4] rounded-full font-[600] text-base ">
            {address.receiversDetails.name[0]}
          </div>
          <div className=" flex flex-col w-full py-[2px]">
            <div className="text-[gray] text-xs">Customer</div>
            <div className="text-black text-sm font-[500]">
              {address.receiversDetails.name}
            </div>
          </div>

          <div
            onClick={() => {
              if (isAddressOwnerNumberDisabled) {
                window.toast(
                  "Unable to call customer, Please try alternate number"
                );
              } else {
                handleCallToUser("addressOwner");
              }
            }}
            className={`p-1.5 rounded-full text-base ${
              isAddressOwnerNumberDisabled
                ? "bg-gray-100 opacity-70 text-gray-500"
                : "blue-gradient"
            } `}
          >
            <MdCall />
          </div>
        </div>

        {/* if the customer number is different from the address owner number */}
        {!isSameNumber && (
          <div className="w-full flex items-center px-4 gap-2">
            <div className="flex mt-1 items-center justify-center flex-shrink-0 size-[2rem] aspect-square bg-blue-50 text-[#2136d4] rounded-full font-[600] text-base ">
              <IoPhonePortraitOutline />
            </div>
            <div className=" flex flex-col w-full py-[2px]">
              <div className="text-[gray] text-xs ">Alternate number</div>
              <div className="text-black text-sm font-[500] ">
                {order?.userId?.name}
              </div>
            </div>
            <div
              onClick={() => {
                if (isUsersNumberDisabled) {
                  window.toast(
                    "Unable to call on alternate number, Please try customer number"
                  );
                } else {
                  handleCallToUser("customer");
                }
              }}
              className={`p-1.5 rounded-full text-base ${
                isUsersNumberDisabled
                  ? "bg-gray-100 opacity-70 text-gray-500"
                  : "blue-gradient"
              } `}
            >
              <MdCall />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
