import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross1 } from "react-icons/rx";

export default function AskForRadio({ parentStates, setParentStates }) {
  const handleClose = async () => {
    setParentStates((p) => ({
      ...p,
      askForRadio: {
        isRender: false,
        labels: [],
        heading: "",
        subHeading: "",
        onSubmitCallback: null,
        isOtherOptionAvailable: false,
      },
    }));
  };

  return (
    <AnimatePresence>
      {parentStates.askForRadio.isRender && (
        <Modal
          handleClose={handleClose}
          parentStates={parentStates.askForRadio}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose, parentStates }) => {
  const [states, setStates] = useState({
    selectedIdx: null,
    otherInputText: "",
  });

  return (
    <div className="fixed top-0 z-[98] flex items-end justify-end md:justify-start right-0 left-0 bottom-0  ">
      <div
        onClick={handleClose}
        className="fixed top-0 z-[-1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
      ></div>
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: "0%", opacity: 1 }}
        exit={{ y: "100%", opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="flex items-center justify-center w-full md:w-[30rem]  flex-col bg-[white] rounded-lg   "
      >
        <div className=" w-full flex items-center justify-between p-4 border-b border-gray-200 pb-2 mb-2 ">
          <div className="flex flex-col ">
            <p className="text-[1rem] font-[500] ">{parentStates.heading}</p>
            <p className="text-[.75rem] text-[grey] leading-[13px] ">
              {parentStates.subHeading}
            </p>
          </div>
          <div onClick={handleClose} className="cursor-pointer ">
            <RxCross1 />
          </div>
        </div>
        <div className=" w-full flex flex-col gap-2 px-4 pt-4 max-h-[80vh] overflow-scroll text-[.875rem] ">
          {parentStates?.labels?.map((curr, idx) => {
            return (
              <div
                key={idx}
                onClick={() => {
                  setStates((p) => ({
                    ...p,
                    selectedIdx: idx,
                    otherInputText: "",
                  }));
                }}
                className="flex gap-1 items-center cursor-pointer  w-full"
              >
                {states.selectedIdx === idx ? (
                  <div className=" w-[1rem] rounded-full h-[1rem]  blue-gradient  "></div>
                ) : (
                  <div className=" w-[1rem] rounded-full h-[1rem] border border-gray-400 bg-gray-50  "></div>
                )}
                <div className=" text-[.875rem] ">{curr}</div>
              </div>
            );
          })}
          {parentStates.isOtherOptionAvailable && (
            <>
              <div
                onClick={() => {
                  setStates((p) => ({ ...p, selectedIdx: -1 }));
                }}
                className="flex gap-1 items-center cursor-pointer  w-full"
              >
                {states.selectedIdx === -1 ? (
                  <div className=" w-[1rem] rounded-full h-[1rem]  blue-gradient  "></div>
                ) : (
                  <div className=" w-[1rem] rounded-full h-[1rem] border border-gray-400 bg-gray-50  "></div>
                )}
                <div className=" text-[.875rem] ">Other</div>
              </div>
              {states.selectedIdx === -1 && (
                <input
                  className="input2 p-2 mb-2"
                  onChange={(e) =>
                    setStates((p) => ({ ...p, otherInputText: e.target.value }))
                  }
                  placeholder="Type here..."
                  value={states.otherInputText}
                />
              )}
            </>
          )}
        </div>

        <div className="flex p-4  w-full items-end justify-end gap-2">
          <button
            onClick={async () => {
              if (states.selectedIdx === null) {
                window.toast("Please select an option");
                return;
              }
              if (states.selectedIdx === -1 && states.otherInputText === "") {
                window.toast("Please enter a value for other option");
                return;
              }

              if (states.selectedIdx === -1) {
                await parentStates.onSubmitCallback(states.otherInputText, -1);
              } else {
                await parentStates.onSubmitCallback(
                  parentStates?.labels[states.selectedIdx],
                  states.selectedIdx
                );
              }
              handleClose();
            }}
            className="text-[1rem] flex-1 cursor-pointer font-[500] blue-gradient   p-2 px-6 rounded-lg"
          >
            {parentStates.saveBtnTxt}
          </button>
        </div>
      </motion.div>
    </div>
  );
};
