import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import "animate.css";

const container = document.getElementById("root");
const root = createRoot(container);
// document.body.style.overflow = "hidden";
root.render(<App />);

reportWebVitals();
