import React from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { BsEmojiFrownFill } from "react-icons/bs";
import moment from "moment-timezone";
import { MdCancel } from "react-icons/md";
// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function OrderPassedPage() {
  return (
    <Link
      to={"/orders"}
      className="flex flex-col fixed top-0 right-0 left-0 bottom-0 items-center justify-center text-white z-[5] bg-red-600"
    >
      <RxCross2
        style={{
          animationDelay: "1.5s",
        }}
        className="top-4 animate__animated animate__fadeIn absolute text-white  right-4 text-3xl "
      />
      <MdCancel
        style={{
          animationDelay: "0s",
        }}
        className="text-[8rem] mb-4 animate__animated animate__backInUp  text-white  delay-1000"
      />

      <div
        style={{
          animationDelay: "0.2s",
        }}
        className="animate__animated animate__backInUp text-xl text-white  font-semibold flex items-center gap-2"
      >
        You passed the order <BsEmojiFrownFill className="text-base" />
      </div>
      {/* <div
        style={{
          animationDelay: "1.2s",
        }}
        className="animate__animated animate__fadeIn text-sm mt-1  text-white  font-regular"
      >
        Work great to get more orders
      </div> */}
    </Link>
  );
}
