import React, { useMemo } from "react";
import { formatCurrency } from "../../../../utils/santaAppUtils";
import { FaArrowTrendUp } from "react-icons/fa6";
import { PiCoinsFill } from "react-icons/pi";
import { GiReceiveMoney } from "react-icons/gi";

export default function ServiceEarningQuickView({ order }) {
  const santaServiceCosting = order?.santaGroups?.santaServiceCosting || 0;
  const santaHandlingFee = order?.santaGroups?.santaHandlingFee || 0;
  const santaTip = order?.priceDetail?.santaTip || 0;

  // Calculate surge amount using useMemo
  const surgeAmount = useMemo(() => {
    return (
      order?.orderWallet?.find((curr) => curr.inOutName === "surge bonus")
        ?.amount || 0
    );
  }, [order?.orderWallet]);

  // Calculate extra amount using useMemo
  const extraAmount = useMemo(() => {
    return (
      order?.orderWallet?.find((curr) => curr.inOutName === "extra amount")
        ?.amount || 0
    );
  }, [order?.orderWallet]);

  // Memoized earning calculation
  const earning = useMemo(() => {
    let amt =
      santaServiceCosting + santaHandlingFee + order?.priceDetail?.santaTip;

    for (const adj of order?.orderWallet) {
      if (adj.inOutType === "in") {
        amt += adj.amount;
      } else {
        amt -= adj.amount;
      }
    }

    return amt;
  }, [order, santaServiceCosting, santaHandlingFee]);

  // Calculate amount without surge charge
  const amountWithOutSurgeAndTip = useMemo(() => {
    return earning - surgeAmount - order?.priceDetail?.santaTip - extraAmount;
  }, [earning, surgeAmount, order, extraAmount]);

  return (
    <div className="w-full px-4 ">
      <div className=" w-full flex flex-col relative  text-[.875rem] font-[400] p-4 bg-[white] rounded-lg border border-gray-200  ">
        <p className=" text-sm font-[500] text-gray-500">Total Earning</p>
        {amountWithOutSurgeAndTip === earning ? (
          <p className=" text-2xl  font-semibold gap-2 flex items-center">
            <span className="font-extrabold">{formatCurrency(earning)}</span>
          </p>
        ) : (
          <p className=" text-2xl  font-semibold gap-2 flex items-center">
            <span className="text-gray-400 line-through">
              {formatCurrency(amountWithOutSurgeAndTip)}
            </span>
            <span className="font-extrabold">{formatCurrency(earning)}</span>{" "}
            <FaArrowTrendUp className="text-[#2136d4]" />
          </p>
        )}
        {surgeAmount > 0 && (
          <div className="flex items-center gap-1 font-semibold mt-3 ">
            <FaArrowTrendUp className="text-green-600 text-lg mr-0.5" />
            {formatCurrency(surgeAmount)}{" "}
            <span className="text-gray-500 font-medium">
              Surge amount included
            </span>
          </div>
        )}
        {extraAmount > 0 && (
          <div className="flex items-center gap-1 font-semibold mt-3 ">
            <GiReceiveMoney className="text-green-600 relative bottom-[2px] text-xl" />
            {formatCurrency(extraAmount)}{" "}
            <span className="text-gray-500 font-medium">
              Extra amount demanded
            </span>
          </div>
        )}

        {santaTip > 0 && (
          <div className="flex items-center gap-1 font-semibold mt-2 ">
            <PiCoinsFill className="text-xl text-[#2136d4]" />
            {formatCurrency(santaTip)}
            <span className="text-gray-500 font-medium">Tip from customer</span>
          </div>
        )}
      </div>
    </div>
  );
}
