import { useContext, useEffect, useState, memo, useRef } from "react";
import AuthContext from "../../../context/AuthContext";
import Error from "../../../assets/Error";
import Button from "./subComponents/Button";
import OTPDigitsInput from "../../../assets/askModals/OTPDigitsInput";

const OTPform = () => {
  const { setAuthPage, number, setInitialState, resendOTPFunc, checkOTP } =
    useContext(AuthContext);
  const otpInputField = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isVerificationStarted, setIsVerificationStarted] = useState(false);

  const [timer, setTimer] = useState(30);
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [inputedOTP, setInputedOTP] = useState("");

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer((prev) => prev - 1), 1000);
    }
  }, [timer]);

  useEffect(() => {
    // console.log(filledDigits)
    if (inputedOTP.length === 6) {
      // All OTP digits are filled
      setIsVerificationStarted(true);
      verifyOTP();
    }

    // eslint-disable-next-line
  }, [inputedOTP]);

  const verifyOTP = async (e) => {
    e && e.preventDefault();
    setIsLoading(true);

    if (inputedOTP.length < 6) {
      setError("OTP should have minimul length of six");
      setIsLoading(false);
      return;
    }

    try {
      const res = await checkOTP(inputedOTP);
      if (!res.matched) {
        setError("wrong OTP");
      }
      setIsVerificationStarted(false);
      setIsLoading(false);
    } catch (error) {
      setInitialState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const onEdit = () => {
    setAuthPage((prev) => ({ ...prev, loginPage: true, otpPage: false }));
  };

  const resendOTP = () => {
    if (timer === 0) {
      setInputedOTP("");
      resendOTPFunc();
      setTimer(30);
    }
  };

  return (
    <form onSubmit={verifyOTP} className="">
      <div className="flex relative text-[.825rem] mt-8 text-[#666666] gap-[1rem] font-medium">
        <p className="flex gap-1">
          Enter OTP sent to <span>+91 {number}</span>
        </p>
        <span
          onClick={onEdit}
          className="font-[500] cursor-pointer text-[#2136d4]"
        >
          Edit
        </span>
      </div>
      <hr className="mt-1 border-t-2 border-solid border-[#2136D4] w-[70px] " />

      <div className="relative mb-2 mt-4">
        <OTPDigitsInput
          {...{
            inputedOTP,
            setInputedOTP,
            isInputFocus,
            setIsInputFocus,
            otpInputField,
          }}
        />
        <div className="flex gap-1 justify-center items-center  text-xs font-medium md:font-semibold">
          <span
            className={`my-3 ${
              timer > 0
                ? "pointer-events-none text-[#979797]"
                : "cursor-pointer  text-[#2136D4] "
            }`}
            onClick={resendOTP}
          >
            {timer > 0 ? "Resend OTP in" : "Resend OTP"}
          </span>
          {timer > 0 && (
            <span className="text-[#2136d4]">
              00:{timer < 10 ? "0" + timer : timer}
            </span>
          )}
        </div>
      </div>
      <Error setError={setError} error={error} />
      <Button
        isLoading={isLoading}
        type={"submit"}
        text={"Verify"}
        disabled={!isVerificationStarted}
      />
    </form>
  );
};

export default memo(OTPform);
