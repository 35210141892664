import React from "react";

export default function SantaInstructions({ order }) {
  const santaInstructions = order?.santaInstructions
    ?.split(";")
    .filter((curr) => curr);

  const imagesInstructionForSanta = order?.imagesInstructionForSanta;

  if (santaInstructions?.length > 0 || imagesInstructionForSanta?.length > 0) {
    return (
      <div className="w-full px-4 mt-4">
        <div className=" w-full flex flex-col  text-[.875rem] font-[400] p-4 bg-[white] rounded-lg border border-gray-200  ">
          <div className="text-[gray] text-[.8125rem] mb-2 ">Instructions</div>
          <div className="flex gap-2 w-full flex-col">
            {santaInstructions?.map((curr, idx) => {
              return (
                <div key={idx} className="flex items-start gap-2 ">
                  <div className=" w-[.5rem] relative top-[6px] rounded-full aspect-square h-[.5rem]  blue-gradient  "></div>
                  <div className="  first-letter:capitalize ">{curr}</div>
                </div>
              );
            })}
            {imagesInstructionForSanta?.map((curr, idx) => {
              return (
                <div key={idx} className="flex items-center  w-full    ">
                  <div className="flex items-start gap-2 w-[80%] pr-2">
                    <div className=" w-[.5rem]  relative top-[6px] rounded-full aspect-square h-[.5rem]  blue-gradient  "></div>
                    <div className="  first-letter:capitalize ">
                      {curr.instruction}
                    </div>
                  </div>
                  <img
                    className="w-[20%] rounded-lg"
                    onClick={() => {
                      window.showMsg(curr.img, "img");
                    }}
                    alt=""
                    src={curr.img}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
