import { commonServiceTags } from "../../../../../utils/constants";

export default function ServiceTags({ tags, comp }) {
  let matchingTags = [];
  tags.forEach((tag) => {
    const matchingCommonTag = commonServiceTags.find((commonTag) =>
      new RegExp(`\\b${tag.replace(/\d+$/, "").trim()}\\b`, "i").test(
        commonTag.tagName
      )
    );
    if (matchingCommonTag) {
      matchingTags.push(matchingCommonTag);
    }
  });
  if (matchingTags.length < 1) return <></>;

  if (comp === "product")
    return (
      <div className="flex flex-wrap gap-2 bg-white p- rounded-[.5rem] ">
        {matchingTags.length > 0 &&
          matchingTags.map((tag, idx) => {
            return (
              <div
                key={idx}
                className="flex items-center gap-1 ` font-[500] text-[.875rem]  text-[#2136D4] bg-blue-100  w-fit px-2 py-[2px] rounded-[.625rem]"
              >
                <div className="">{tag.icon}</div>
                <p className="capitalize"> {tag.tagName}</p>
              </div>
            );
          })}
      </div>
    );

  return (
    <div className="w-full mt-4 ">
      <div className="flex flex-wrap gap-2 rounded-[.5rem]">
        {matchingTags.length > 0 &&
          matchingTags.map((tag, idx) => {
            return (
              <div
                key={idx}
                className="flex items-center gap-1 font-[400] text-[.8125rem]  text-[#2136D4] border border-blue-200 bg-blue-50  w-fit px-2 py-[2px] rounded"
              >
                <div className="">{tag.icon}</div>
                <p className="capitalize"> {tag.tagName}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
}
