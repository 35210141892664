import React, { memo, useRef } from "react";
// import "./SideBar.css";

const PhoneInput = ({ number, setNumber, disabled }) => {
  const inputRef = useRef(null);

  const handleBlur = () => {
    if (inputRef.current && inputRef.current.value.length === 10) {
      inputRef.current.blur();
    }
  };

  return (
    <div
      className={`flex gap-2 py-3 px-4 my-3 w-[100%] border rounded-[10px] ${
        disabled && "opacity-[70%]"
      } `}
    >
      <span className=" border-[#d9d9d9] pr-1 font-medium">+91</span>
      <input
        className="  border-[#d9d9d9] px-2 outline-none bg-inherit focus:border-[rgba(0,0,0,0.3)] w-[100%]"
        onChange={(e) => {
          handleBlur();
          setNumber(e.target.value);
        }}
        inputMode="numeric"
        value={number}
        disabled={disabled ? disabled : false}
        minLength="10"
        maxLength="10"
        autoComplete="on"
        placeholder="Enter mobile number"
        ref={inputRef}
      />
    </div>
  );
};

export default memo(PhoneInput);
