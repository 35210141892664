import React, { useState, useRef, useEffect } from "react";
import BottomBtn from "./subC/BottomBtn";
import QRmodal from "./subC/qrModal/QRmodal";
import CashCollectModal from "./subC/cashCollectModal/CashCollectModal";
import TopBox from "./subC/TopBox/TopBox";
import SantaTeam from "../subC/santaTeam/SantaTeam";
import PaymentDetail from "./subC/paymentDetail/PaymentDetail";
import OrderSummaryContainer from "./subC/orderSummary/OrderSummaryContainer";
import OrderSummaryIdx from "./subC/orderSummary/OrderSummaryIdx";
import MaterialAddModal from "./subC/orderSummary/orderSummarySubC/MaterialAddModal";
import DecorationPics from "./subC/decorationPics/DecorationPics";
import AddressIdx from "./subC/address/AddressIdx";
import usePullToRefresh, {
  RefreshLoader,
} from "../../../../hooks/usePullToRefresh";
import { rzpAPI } from "../../../../api/pay/pay.api";
import { getOrderForSantaApi } from "../../../../api/orderApi";
import { MdCall } from "react-icons/md";
import { SANTA_HELP_NUMBER } from "../../../../utils/constants";
import { handleUpdate } from "../subC/helperFunctions";
import { s3UploadApi } from "../../../../api/aws/s3";
import { v4 as uuidv4 } from "uuid";

let timeoutIdForPaymentStatusCheck;
let timeToRecheckPaytmentStatus = 3000;
export default function LiveOrderIdx({
  parentStates,
  setParentStates,
  order,
  onReferesh,
  updateOrder,
  santaId,
  leaderSanta,
}) {
  const containerRef = useRef(null);
  const [refereshStates, setRefereshStates] = useState({});

  usePullToRefresh(
    containerRef,
    onReferesh,
    (params) => {
      setRefereshStates(params);
    },
    {
      isGradient: false,
      loaderColor: "#2136d4",
    }
  );

  const [states, setStates] = useState({
    showQrModal: false,
    cashCollectModal: false,
    orderSummaryPage: false,
    materialAddModal: false,
    isPaymentDone: false,
    QRScanner: null,
    QRID: null,
    paymentLinkSentToCustomer: false,
    paymentLinkSentToCustomerTime: null,
    isUploadingDecorImg: false,
  });

  useEffect(() => {
    if (states.showQrModal && states.QRID && !states.isPaymentDone) {
      handlePaymentStatusCheck();
    } else {
      clearTimeout(timeoutIdForPaymentStatusCheck);
    }

    // eslint-disable-next-line
  }, [states.QRID, states.showQrModal]);

  const [workImageStates, setWorkImageStates] = useState({
    isUploadingImg: false,
    img: "",
    imgFile: "",
  });

  const handleWorkImageUpload = async ({
    imgFile,
    idx: imgIdx,
    decorImagesLength,
  }) => {
    setWorkImageStates((p) => ({ ...p, isUploadingImg: true }));
    setStates((p) => ({ ...p, isUploadingDecorImg: true }));

    try {
      if (!imgFile) {
        window.toast("Click a picture first!");
        return;
      }

      const idx = uuidv4();
      const positionIdx = imgIdx;

      const imgToUploadObj = [
        {
          file: imgFile,
          path: `orders/${order._id}/decoration/${idx}.webp`,
        },
      ];

      await s3UploadApi(imgToUploadObj);

      const imgUrl = `https://img.ebonow.com/custom/orders/${order._id}/decoration/${idx}.webp`;

      if (imgIdx === decorImagesLength) {
        await handleUpdate({
          orderId: order._id,
          updateObject: {
            $push: {
              decorWorkPictures: {
                imgUrl: imgUrl,
                isRemoved: false,
              },
            },
          },
          arrayFilters: [],
          setStates: setParentStates,
        });
      } else {
        const allDecorImages = order.decorWorkPictures;
        allDecorImages[positionIdx] = {
          imgUrl: imgUrl,
          isRemoved: false,
        };
        await handleUpdate({
          orderId: order._id,
          updateObject: {
            decorWorkPictures: allDecorImages,
          },
          arrayFilters: [],
          setStates: setParentStates,
        });
      }

      setWorkImageStates((p) => ({
        ...p,
        img: "",
        imgFile: "",
      }));
    } catch (error) {
      console.log(error);
    }
    setWorkImageStates((p) => ({ ...p, isUploadingImg: false }));
    setStates((p) => ({ ...p, isUploadingDecorImg: false }));
  };

  const openCameraForWorkImageClick = (idx, decorImagesLength) => {
    window.pickImage({
      onImagePickEnd: (file) => {
        window.pickImage({
          isRender: false,
        });
        handleWorkImageUpload({ imgFile: file, idx, decorImagesLength });
      },
      pickFrom: "camera",
      cameraType: "back",
      allowsEditing: false,
    });
  };

  const handlePaymentStatusCheck = async () => {
    try {
      const res = await rzpAPI({
        options: {
          operation: "fetchQRCode",
          id: states.QRID,
        },
      });

      if (res.isSuccess && res.paymentStatus === "captured") {
        const orderRes = await getOrderForSantaApi({
          orderId: order._id,
          projectionString: "priceDetail",
        });
        if (orderRes.order.priceDetail.paymentStatus.toLowerCase() === "paid") {
          setStates((p) => ({
            ...p,
            isPaymentDone: true,
          }));

          clearTimeout(timeoutIdForPaymentStatusCheck);
        } else {
          timeoutIdForPaymentStatusCheck = setTimeout(() => {
            handlePaymentStatusCheck();
          }, timeToRecheckPaytmentStatus);
        }
      } else {
        timeoutIdForPaymentStatusCheck = setTimeout(() => {
          handlePaymentStatusCheck();
        }, timeToRecheckPaytmentStatus);
      }

      return res;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className=" fixed top-0 flex flex-col pt-[14rem]  bg-blue-50 items-center right-0 left-0 bottom-0 ">
        <TopBox setParentStates={setParentStates} parentStates={parentStates} />
        <div
          ref={containerRef}
          className=" flex flex-col items-center w-full overflow-scroll h-full pb-[10rem] scroll-bar-remove"
        >
          <RefreshLoader refereshStates={refereshStates} />

          <PaymentDetail order={order} />
          <OrderSummaryContainer order={order} setStates={setStates} />
          <DecorationPics
            {...{
              workImageStates,
              setWorkImageStates,
              order,
              setStates,
              parentStates,
              setParentStates,
              openCameraForWorkImageClick,
            }}
          />
          <AddressIdx order={order} updateOrder={updateOrder} />

          <div className="w-full flex mt-4 mb-0 py-4 items-center justify-between px-4 bg-white gap-2">
            <div
              onClick={() => {
                window.openUrl(`tel:${SANTA_HELP_NUMBER}`);
              }}
              className="flex items-center text-[#2136d4] border border-[#2136d4] bg-blue-50 rounded-lg  w-full  text-[1.125rem] font-[600] py-4 justify-center gap-2"
            >
              <MdCall className="text-[1.5rem] mb-[3px] " />
              Contact ebo support
            </div>
          </div>
          <div className="px-4 mt-6 w-full">
            <p className=" text-[1.125rem] w-full text-center font-[600] ">
              <span className="text-[gray]">Santa </span>
              <span className="   text-[black]">Team</span>
            </p>
            <SantaTeam santaGroups={order?.santaGroups} />
          </div>
          <BottomBtn
            {...{
              states,
              setParentStates,
              parentStates,
              order,
              setStates,
              leaderSanta,
              santaId,
              openCameraForWorkImageClick,
            }}
          />
        </div>
      </div>
      {/* different modals */}
      <OrderSummaryIdx order={order} states={states} setStates={setStates} />
      <QRmodal
        setParentStates={setParentStates}
        order={order}
        states={states}
        setStates={setStates}
        onReferesh={onReferesh}
      />
      <MaterialAddModal
        setParentStates={setParentStates}
        order={order}
        states={states}
        setStates={setStates}
      />
      <CashCollectModal
        setParentStates={setParentStates}
        order={order}
        states={states}
        setStates={setStates}
      />
    </>
  );
}
