import React from "react";

export default function ReturningItemsRow({ states }) {
  return (
    <div className=" flex flex-col bg-white rounded-lg p-4 w-full border border-gray-200 ">
      <div className=" font-medium mb-2 ">Returnable items</div>
      <div className=" flex flex-nowrap gap-2 w-full overflow-scroll scroll-bar-remove ">
        {states.returnableItems?.map((item, index) => {
          let itemName = item?.item?.itemName || item.customItem.itemName;

          let itemImage =
            item?.item?.images?.length > 0
              ? item.item.images[0]
              : item.customItem.image;

          return (
            <div
              key={index}
              className=" flex flex-col relative w-[7rem] flex-shrink-0 "
            >
              <img
                src={itemImage}
                alt=""
                onClick={() => {
                  window.showMsg(itemImage, "img");
                }}
                className="w-full aspect-square rounded-lg  border border-gray-200 "
              />
              <div
                className="text-[.875rem] absolute bg-gradient-to-r from-blue-200 font-medium bottom-2 left-2 via-blue-100 to-[#ffffffca] text-[#2136d4]
               rounded-full whitespace-nowrap max-w-[90%] overflow-hidden text-ellipsis py-0.5 px-2 text-xs"
              >
                {itemName}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
