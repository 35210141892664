import { Suspense, lazy, useContext } from "react";
import CompRenderingContext from "../context/CompRenderingContext";
import WarningModalv2 from "../assets/WarningModalv2";
import Toast1 from "../assets/toastModals/Toast1";
import { AnimatePresence } from "framer-motion";
import AskForInputs1 from "../assets/askModals/AskForInputs1";
import AskForRadio from "../assets/askModals/AskForRadio";
import LoadingScreen1 from "../assets/loadings/LoadingScreen1";
import AddEditBankDetailsModal from "../assets/AddEditBankDetailsModal";
import CallToUserModal from "../assets/CallToUserModal";
import AskImagePickFrom from "../assets/askModals/AskImagePickFrom";
import FloatingCustomModal from "../assets/FloatingCustomModal";
const OnboardingSuccessModal = lazy(() =>
  import("../components/onBoarding/OnboardingSuccessModal")
);
const SuccessModal = lazy(() => import("../assets/SuccessModal"));
const ToastMsgModal = lazy(() => import("../assets/ToastMsgModal"));

export default function PopUpComponents() {
  const { onboardingSuccessModal, successMsg, states, setStates, toastMsg } =
    useContext(CompRenderingContext);
  return (
    <div className="pop-up-components">
      {onboardingSuccessModal.isRender && <OnboardingSuccessModal />}
      {toastMsg.isRender && (
        <Suspense fallback={<Spinner />}>
          <ToastMsgModal />
        </Suspense>
      )}
      <AskForRadio parentStates={states} setParentStates={setStates} />
      {successMsg.isRender && (
        <Suspense fallback={<Spinner />}>
          <SuccessModal />
        </Suspense>
      )}

      <CallToUserModal parentStates={states} setParentStates={setStates} />
      <FloatingCustomModal parentStates={states} setParentStates={setStates} />
      <AddEditBankDetailsModal {...{ states, setStates }} />
      <WarningModalv2 parentStates={states} setParentStates={setStates} />
      <AskImagePickFrom parentStates={states} setParentStates={setStates} />
      <AskForInputs1 parentStates={states} setParentStates={setStates} />
      <LoadingScreen1 states={states} setStates={setStates} />
      <AnimatePresence>
        {states.toast1.isRender && (
          <Toast1 modalsRenderInfo={states} setModalsRenderInfo={setStates} />
        )}
      </AnimatePresence>
    </div>
  );
}

const Spinner = () => {
  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center">
      <div className="loader w-[2rem] "></div>
    </div>
  );
};
