import React from "react";
import SantaTeam from "../../orderDetails/subC/santaTeam/SantaTeam";

export default function TeamRow({ order }) {
  return (
    <div className="px-4 mt-6 w-full">
      <p className=" text-[1.125rem] w-full text-center font-[600] ">
        <span className="text-[gray]">Santa </span>
        <span className="text-[black]">Team</span>
      </p>
      <SantaTeam santaGroups={order?.santaGroups} />
    </div>
  );
}
