import { BiCheck } from "react-icons/bi";
import { TbRepeat } from "react-icons/tb";

export default function MaterialsList({ rawMaterials }) {
  const isItemPickedUp = (idx) => {
    return (
      rawMaterials.length > 0 &&
      rawMaterials[idx].pickUpStatus ===
        ("picked_by_santa" || "delivering_to_santa")
    );
  };

  //  console.log(rawMaterials)
  const materialsLength = rawMaterials.length;
  return (
    <div className="bg-white text-[gray] text-[.875rem] rounded-lg border-gray-200 border overflow-hidden">
      <div className=" capitalize  ">
        {rawMaterials?.length > 0 &&
          rawMaterials.map((curr, idx) => {
            const item = curr.item ? curr.item : curr.customItem;

            return (
              <div
                key={idx}
                style={{
                  borderBottom:
                    materialsLength - 1 === idx ? "none" : "1px solid #d9d9d9",
                }}
                className={`flex justify-between items-center border-b py-2 px-3 
                `}
              >
                <div className="flex gap-4 w-[80%] items-center">
                  <div className="w-[90%] flex items-center gap-3">
                    <img
                      src={item?.images ? item?.images[0] : item?.image}
                      alt="material"
                      className=" rounded-full overflow-hidden w-[2rem] h-[2rem] aspect-square bg-blue-50 "
                    />
                    <p className="text-black max-w-[70%] text-ellipsis overflow-hidden whitespace-nowrap font-medium">
                      {item?.itemName}
                    </p>

                    {isItemPickedUp(idx) && (
                      <BiCheck className="text-[1rem] text-[green] " />
                    )}
                  </div>
                  {curr.item?.tags?.includes("rent") && (
                    <p>
                      <TbRepeat className="text-[1rem]" />
                    </p>
                  )}
                </div>
                <div className="flex gap-2 items-center ">
                  <p className="flex items-center">
                    <span className="text-[.625rem] mr-1">x</span>
                    {curr.quantity + curr.extraQuantity}
                    {curr.packetType === "meter"
                      ? "M"
                      : curr.packetType === "liter"
                      ? "L"
                      : ""}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
